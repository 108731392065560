import React, {useEffect, useState} from 'react';
import {H2, H6} from "../shared/fonts/fonts";
import {ButtonText} from "../shared/buttons/buttons";
import Form from "../shared/form/form";
import {pageProps} from "../../pages/appointment";
import {paymentData} from "../../utils/types";
import Slot from "../../entities/Slot";

declare global {
    interface Window { ArsenalpayWidget: any; }
}

const Payment = (props: pageProps) => {
    const [widget, setWidget] = useState<any>(null);
    const [slot, setSlot] = useState<Slot|null>(null);

    const prev = () => slot === null
        ? props.setPage('login', {})
        : props.setPage('confirmation', {slot});

    useEffect(() => {
        const script = document.createElement("script");
        script.src = 'https://arsenalpay.ru/widget/script.js';
        script.async = true;
        document.body.appendChild(script);

        props.next && props.next((payment: paymentData, slot: Slot) => {
            setSlot(slot);
            setWidget(new window['ArsenalpayWidget']({
                element: 'pay-widget',
                ...payment
            }));
        });
    }, []);

    useEffect(() => {
        widget && widget.render();
    }, [widget]);

    return (
        <Form>
            <H2>Запись на онлайн-информирование</H2>
            <div id='pay-widget' />
            <ButtonText onClick={prev}>
                <H6>Вернуться</H6>
            </ButtonText>
        </Form>
    );
};

export default Payment;