import React from 'react';
import styled from "styled-components";
import {Images} from "../../../utils/images";

export const H1 = styled.h1`
  line-height: 64px;
  font-size: 48px;
  font-weight: 800;
  margin: 0;
  
  .mobile & {
    font-size: 30px;
    line-height: 46px;
  }
`;

export const H2 = styled.h2`
  line-height: 54px;
  font-size: 40px;
  font-weight: 800;
  margin: 0;

  .mobile & {
    font-size: 30px;
    line-height: 46px;
  }
`;

export const H3 = styled.h3`
  line-height: 32px;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
`;

export const H4 = styled.h4`
  line-height: 28px;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
`;

export const H5 = styled.h5`
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  display: inline-block
`;

export const H6 = styled.h6`
  line-height: 20px;
  font-size: 13px;
  font-weight: 500;
  margin: 0;
`;

export const H7 = styled.h6`
  line-height: 32px;
  font-size: 24px;
  font-weight: 500;
  margin: 0;
`;

export const H8 = styled.h6`
  line-height: 28px;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`;

export const H9 = styled.h6`
  line-height: 20px;
  font-size: 13px;
  font-weight: 600;
  margin: 0;
`;

export const P1 = styled.p`
  line-height: 26px;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
`;

export const P2 = styled.p`
  line-height: 22px;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
`;



const P1Point = styled.img`
  
`;

const P1PointedStyle = styled.div`
  display: grid;
  grid-gap: 40px;
  align-items: center;
  grid-auto-flow: column;
`;

export const P1Pointed = (props: {children: any}) => {
    return (<P1PointedStyle>
        <P1Point src={Images.clouds.about.point} />
        <P1>{props.children}</P1>
    </P1PointedStyle>)
}
