import React from 'react';
import styled from "styled-components";

const Link = styled.a`
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

export default Link;