import React, {useEffect, useState} from 'react';
import {P2} from "../shared/fonts/fonts";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {storeType} from "../../redux/store";
import User, {EMPTY_USER, UserData} from "../../entities/User";

const Container = styled.div`
  display: grid;
  grid-gap: 6px;
  
  & h6 {
    color: ${props => props.theme.color.dpu1};
  }

  & h5 {
    line-height: 22px;
  }

  & p {
    font-weight: 400;
  }
  
  & > div {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 4px;
  }

  .mobile & > div {
    grid-auto-flow: row;
  }
`;

const Input = styled.input`
  line-height: 28px;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Open Sans';
  color: ${props => props.theme.color.dpu1};
  width: 100%;
  border: none;
  box-sizing: border-box;
  background: ${props => props.theme.color.dpu5};
  border-radius: 5px;
`;

const InputDate = styled.input`
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Open Sans';
  background: ${props => props.theme.color.dpu5};
  border-radius: 5px;
  border: none;
  box-sizing: border-box;
`;

const EditInfo = (props: {setData: (value: any) => void}) => {

    const meData = useSelector<storeType, UserData | null>((state) => state.me);
    const me = new User(meData || EMPTY_USER);

    const [lastname, setLastname_] = useState(me.lastname);
    const [firstname, setFirstname_] = useState(me.firstname);
    const [fatherName, setFatherName_] = useState(me.fatherName || '');
    const [birthdate, setBirthdate_] = useState(me.birthdate);

    const setLastname = (e: any) => setLastname_(e.target.value);
    const setFirstname = (e: any) => setFirstname_(e.target.value);
    const setFatherName = (e: any) => setFatherName_(e.target.value);
    const setBirthdate = (e: any) => {
        const date_ = e.target.value ? new Date(e.target.value) : null;
        date_ && setBirthdate_(date_);
    }

    useEffect(() => props.setData({lastname, firstname, fatherName, birthdate}),
        [lastname, firstname, fatherName, birthdate])

    const bd = birthdate ? birthdate.toISOString().slice(0, 10) : '';

    return (
        <Container>
            <div>
                <Input type={'text'} value={lastname} placeholder={'Фамилия'} onInput={setLastname} />
                <Input type={'text'} value={firstname} placeholder={'Имя'} onInput={setFirstname} />
                <Input type={'text'} value={fatherName} placeholder={'Отчество'} onInput={setFatherName} />
            </div>
            <P2>Дата рождения <InputDate type={'date'} value={bd} onInput={setBirthdate} /></P2>
        </Container>
    );
};

export default EditInfo;
