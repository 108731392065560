import React, {useState} from 'react';
import {H3} from "../../shared/fonts/fonts";
import styled from "styled-components";
import Articles from "../tabs/Articles";
import {Select} from "../../shared/form/input";
import Reviews from "../tabs/Reviews";
import Links from "../tabs/Links";
import Videos from "../tabs/Videos";

const InfoStyle = styled.div`
  display: grid;
  grid-gap: 10px;
`;

type tabs_values = 'articles'|'links'|'videos'|'reviews';
const bodies = {
    articles: Articles, links: Links, videos: Videos, reviews: Reviews
};

const TabBodyStyle = styled.div``;
const Info = () => {
    const [currentTab, setCurrentTab] = useState<tabs_values>('reviews');
    const TabBody = bodies[currentTab];

    return (
        <InfoStyle>
            <H3>Данные на странице "Полезная информация"</H3>
            <Select input={{value: currentTab, onChange: (e: any) => setCurrentTab(e.target.value)}}>
                {/*<option value={'articles'}>Статьи</option>*/}
                <option value={'links'}>Ссылки</option>
                <option value={'videos'}>Видео</option>
                <option value={'reviews'}>Отзывы</option>
            </Select>
            <TabBodyStyle>
                {
                    <TabBody />
                }
            </TabBodyStyle>
        </InfoStyle>
    );
};

export default Info;