import React from 'react';
import {H1, H5, P1, H4} from "../shared/fonts/fonts";
import {ButtonBlue} from "../shared/buttons/buttons";
import styled from "styled-components";
import {useViewport} from "../../utils/ViewportContext";
import MainPhoto from "../../images/svgs/main/MainPhoto";
import MainRightTop from "../../images/svgs/main/MainRightTop";
import Consultation from "../../entities/Consultation";

const TopContainer = styled.div`
  display: grid;
  padding-bottom: 95px;
  
  .desktop & {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
  }
  
  .mobile & {
    grid-template-columns: 100%;
    overflow-x: hidden;
  }
`;

const TopLeftContainer = styled.div`
  display: grid;
  grid-gap: 48px;
  margin: 0 24px;
  align-content: center;
  justify-items: start;
  width: min-content;
  z-index: 1;
  
  .mobile & {
    width: auto;
    & h1 {
    white-space: normal;
  }
`;

const TopRightContainer = styled.div`
  position: relative;
  justify-self: end;
  
  .mobile & {
    margin-right: -70px;
  }
`;

const Cloud1 = styled.div`
  background: ${props => props.theme.color.pu2};
  width: 53px;
  height: 53px;
  border-radius: 50%;
  position: absolute;
  top: 328px;
  right: 573px;
`;

const InfoContainer = styled.div`
  margin: -70px 106px 0 0;
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  align-items: center;
  grid-gap: 22px;
  
  .mobile & {
    margin: -30px 0 40px 0;
    justify-content: center;
  }
`;

const Info = styled.div`
  text-align: end;
  
  & > h5 {
    margin: 0
  }
`;

const Cloud2 = styled.div`
  background: ${props => props.theme.color.pu2};
  width: 105px;
  aspect-ratio: 1;
  border-radius: 50%;
  
  .mobile & {
    width: 50px;
  }
`;

const Quote = styled(P1)`
  text-align: end;
  font-style: italic;
`;

const Top = () => {
    const viewport = useViewport();

    return (
        <TopContainer>
            {viewport.device === 'desktop' &&
                <TopLeftContainer>
                    <H1>Очные консультации<br/>Онлайн-информирование</H1>
                    <P1>Доказательная медицина<br/>Ведение взрослых</P1>

                    <Quote>"Единственная красота, которую я знаю, — это здоровье. ”<br/>Генрих Гейне</Quote>
                    <ButtonBlue href={Consultation.linkToView()}><H5>Обратиться онлайн</H5></ButtonBlue>
                </TopLeftContainer>
            }
            <TopRightContainer>
                <MainRightTop />
                <MainPhoto />
                <Cloud1 />
                <InfoContainer>
                    <Info>
                        <H4>Сиюхова Заира Аслановна</H4>
                        <div />
                        <H4>Врач-эндокринолог</H4>
                    </Info>
                    <Cloud2 />
                </InfoContainer>
            </TopRightContainer>
            {viewport.device !== 'desktop' &&
                <TopLeftContainer>
                    <H1>Очные консультации<br/>Онлайн-информирование</H1>
                    <P1>Доказательная медицина<br/>Ведение взрослых</P1>
                    <Quote>"Единственная красота, которую я знаю, — это здоровье. ”<br/>Генрих Гейне</Quote>
                    <ButtonBlue href={Consultation.linkToView()}><H5>Обратиться онлайн</H5></ButtonBlue>
                </TopLeftContainer>
            }
        </TopContainer>
    );
};

export default Top;
