import React from 'react';
import {Images} from "../../utils/images";

const SvgDefs = () => {
    return (
        <svg style={{display: 'none'}}>
            <defs>
                <image id="image_gradient1" width="2560" height="2560" xlinkHref={Images.backgrounds.gradient1}/>
                <image id="image_gradient2" width="2560" height="2560" xlinkHref={Images.backgrounds.gradient2}/>
                <image id="image_gradient3" width="2560" height="2560" xlinkHref={Images.backgrounds.gradient3}/>
                <image id="image_photo" width="3639" height="4096" xlinkHref={Images.backgrounds.photo}/>
            </defs>
        </svg>
    );
};

export default SvgDefs;