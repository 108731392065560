import React from 'react';
import styled from "styled-components";

const MainRightTopStyle = styled.svg`
  position: absolute;
  right: 0;
  top: 0;
`;

const MainRightTop = () => {
    return (
        <MainRightTopStyle width="679" height="421" viewBox="0 0 679 421" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <ellipse cx="398.5" cy="89" rx="398.5" ry="332" fill="#B994E9"/>
            <ellipse cx="398.5" cy="89" rx="398.5" ry="332" fill="url(#pattern_main_right_top)" fillOpacity="0.5"/>
            <defs>
                <pattern id="pattern_main_right_top" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref="#image_gradient1" transform="matrix(0.000390625 0 0 0.000468868 0 -0.100151)"/>
                </pattern>
            </defs>
        </MainRightTopStyle>
    );
};

export default MainRightTop;