import React, {useEffect, useState} from 'react';
import {H3, H4} from "../shared/fonts/fonts";
import styled from "styled-components";
import {ButtonText} from "../shared/buttons/buttons";
import {Images} from "../../utils/images";
import {useViewport} from "../../utils/ViewportContext";
import {video} from "../../utils/types";
import post from "../../api/post";

const VideoStyle = styled.div`
  & h4 {
    padding: 30px 0;
    text-align: center;
  }
  
  .mobile & img {
    width: 100%;
  }
`;

const Video = (props: {video: video}) => {
    const regexp = props.video.href.match(/v=([A-z0-9]+)/);
    let link_ = '';
    if (regexp && regexp.length === 2) {
        link_ = regexp[1];
    }

    return (
        <VideoStyle>
            {/*<img src={Images.icons.videoWindow} />*/}
            <iframe width={450} height={342} src={'https://www.youtube.com/embed/' + link_} frameBorder={0}
                    allowFullScreen></iframe>
            <H4>{props.video.title}</H4>
        </VideoStyle>)
}

const VideosStyle = styled.div`
  padding: 30px 24px 62px 24px;
  display: grid;
  justify-items: center;
  
  .mobile & {
    padding: 30px 10px 62px 10px;
  }
`;

const VideoContainer = styled.div<{rows: number}>`
    margin: 90px 0 40px 0;
    display: grid;
    //grid-gap: 30px;
    height: ${props => props.rows * 434}px;
    overflow-y: hidden;
    align-content: start;
    
    transition: height 0.2s ease-in-out;
  
  .mobile & {
    width: 100%;
    grid-template-columns: 100%;
  }
`;

const VideoRow = styled.div`
  display: flex;
  column-gap: 16px;
`;

const ButtonStyle = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  align-items: center;
  
  &.more img {
    transform: rotate(180deg);
  }
`;

const Videos = () => {
    const [more, setMore] = useState(false);

    const viewport = useViewport();
    const countInRow = Math.floor((viewport.viewport.width - 32) / 470);
    let rows:any[] = [];

    const [videos, setVideos] = useState<video[]>([]);

    for (let i = 0; i < videos.length; i++) {
        if (rows.at(-1) && rows.at(-1).length < countInRow) {
            rows.at(-1).push(<Video key={i} video={videos[i]} />)
        }
        else {
            rows.push([<Video key={i} video={videos[i]} />])
        }
    }

    useEffect(() => {
        post('getVideos', {})
            .then(result => setVideos(result.data.videos))
            .catch();
    }, []);

    if (videos.length === 0) {
        return <div></div>
    }

    return (
        <VideosStyle>
            <H3>Видео</H3>
            <VideoContainer rows={more ? rows.length : 1}>
                {
                    rows.map((videos, i) => <VideoRow key={i}>{videos}</VideoRow>)
                }
            </VideoContainer>
            {
                rows.length > 1 && <ButtonText onClick={() => setMore(!more)}>
                    <H4>
                        <ButtonStyle className={more ? 'more' : ''}><img src={Images.icons.arrowDown}/>
                            {more ? 'Свернуть' : 'Развернуть'}
                        </ButtonStyle>
                    </H4>
                </ButtonText>
            }
        </VideosStyle>
    );
};

export default Videos;
