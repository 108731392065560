import store from "../../../../redux/store";
import {setSlots, setSlotsLoading} from "../../../../redux/actions";
import get from "../../../get";

export const getSlots = (start: number) => {
    store.dispatch(setSlotsLoading());
    get('slots/all', {from: start})
        .then(result => {
            store.dispatch(setSlots(result.data.slots));
        })
        .catch(error => console.log(error))
}