import React, {useState} from 'react';
import Template from "../components/template/template";
import Main from "../components/appointment/Main";
import Confirmation from "../components/appointment/Confirmation";
import Success from "../components/appointment/Success";
import Error from "../components/appointment/Error";
import Payment from "../components/appointment/Payment";
import Slot from "../entities/Slot";

const pages:any = {
    login: Main,
    confirmation: Confirmation,
    success: Success,
    error: Error,
    payment: Payment
}
export type pageNames = 'login'|'confirmation'|'payment'|'success'|'error';

interface props_ {
    text?: string;
    next?: (value?: any) => void;
    prev?: () => void;
    slot?: Slot
}

export interface pageProps extends props_ {
    setPage: (page: pageNames, props: props_) => void;
}

const Appointment = () => {
    const [page, setPage_] = useState<string>('login');
    const [pageData, setPageData] = useState<props_>({});
    const CPage = pages[page];

    const setPage = (page: pageNames, pageData_: props_) => {
        setPageData({...pageData_});
        setPage_(page);
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    return (
        <Template>
            {
                <CPage setPage={setPage} {...pageData} />
                //<Success CPage={setPage} text={'Запись успешно создана!'} />
            }
        </Template>
    );
};

export default Appointment;
