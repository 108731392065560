import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {H3, H5, H7} from "../fonts/fonts";
import {Images} from "../../../utils/images";
import {URLs, weekDays} from "../../../utils/constants";
import {ButtonText, ButtonWhite} from "../buttons/buttons";
import {useDispatch, useSelector} from "react-redux";
import {ChooseSlot} from "../../../redux/actions";
import {storeType} from "../../../redux/store";
import {useViewport} from "../../../utils/ViewportContext";
import {toLocaleStringByMoscow} from "../../../utils/functions";
import {ByMoscowTime} from "../../appointment/Main";
import Slot, {SlotData} from "../../../entities/Slot";
import User, {UserData} from "../../../entities/User";
import {disAppoint} from "../../../api/methods/slots/update/disAppoint";

const CardStyle = styled.div`
  position: fixed;
  //top: calc(50% - 143px);
  bottom: 20px;
  right: 20px;
  background: white;
  max-width: 622px;
  
  display: grid;

  border-radius: 16px;
  box-shadow: 48px 48px 48px 48px rgba(24, 37, 56, 0.12);
  padding: 20px;
  //grid-gap: 50px;
  z-index: 2;

  height: 286px;
  box-sizing: border-box;
  grid-template-rows: 1fr 1fr 1fr;
  
  & > :nth-child(1) {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    
    align-self: start;
  }

  & > :nth-child(3) {
    display: grid;
    grid-auto-flow: column;
    align-items: end;

    grid-template-columns: auto min-content;
    align-self: end;
    justify-content: space-between;
    
    .mobile & {
      grid-template-columns: none;
      grid-auto-flow: row;
      grid-gap: 10px;
      justify-content: center;
      justify-items: center;
      margin-top: 20px;
    }
  }
  
  .mobile & {
    bottom: auto;
    top: 20px;
    width: calc(100% - 40px);
    height: auto;
    max-height: 70%;
  }
`;

const Header = styled.div`
  &, & a {
    text-decoration: none;
    color: ${props => props.theme.color.bl1};
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    text-align: start;
  }
`;

const CloseContainer = styled.div`
  display: grid;
  justify-items: end;
`;

const Close = styled.img`
  margin: -20px;
  padding: 20px;
  cursor: pointer;
`;

export const Info = styled.div`
  display: grid;
  justify-items: start;
  grid-gap: 10px;
  padding: 0 6px;
  
  & h6 {
    font-weight: 400;

    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    
    .mobile &, .mobile :nth-child(2) {
      white-space: normal;
    }
  }
  
  & > div {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 12px;
    align-items: center;
  }
`;

export const Cancel = styled(ButtonText)`
  & h5 {
    color: ${props => props.theme.color.pi2};
  }
`;

export const Message = styled(ButtonWhite)<{count?: number}>`
  color: ${props => props.theme.color.b1};
  border-color: ${props => props.theme.color.b1};
  position: relative;

  &::after {
    display: ${props => props.count ? 'grid' : 'none'};
    content: "${props => props.count && '+' + props.count}";
    position: absolute;

    right: -4px;
    top: -10px;
    font-size: 10px;
    background: ${props => props.theme.color.pi2};
    color: white;
    padding: 0 4px;
    border-radius: 4px;
  }
`;

const DateString = styled.div`
    display: flex;
    grid-gap: 10px;
    flex-wrap: wrap; 
    
    & > :nth-child(2) {
        opacity: 0.5;
    }
`;

export const dateToString = (ts: number) => {
    const d_ = new Date(ts * 1000);
    const s_ = toLocaleStringByMoscow(d_);
    return (
        <DateString>
            <span>{s_.slice(0, 5)}</span>
            <span>{weekDays[(d_.getDay() + 6) % 7]}</span>
            <span>{s_.slice(12, 17)}</span>
            <ByMoscowTime>по <span>московскому</span> времени</ByMoscowTime>
        </DateString>);
}

export const Color = styled.div<{color: string}>`
  background: #${props => props.color};
  width: 24px;
  aspect-ratio: 1;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
`;

const Sure = styled.div`
  position: absolute;
  border-radius: 16px;
  padding: 20px;
  background: white;
  display: grid;
  grid-gap: 10px;
  box-sizing: border-box;
  
  right: calc(100% + 20px);
  bottom: 0;

  box-shadow: 0 0 28px 28px rgba(24, 37, 56, 0.12);
  
  .mobile & {
    height: fit-content;
    right: auto;
    top: calc(100% + 20px);
    width: 100%;
  }
`;

const Card = (props: {update: () => void}) => {
    const viewport = useViewport();
    const [sure, setSure] = useState(false);

    const dispatch = useDispatch();
    const meData = useSelector<storeType, UserData | null>((state) => state.me);
    const me = meData ? new User(meData) : null;
    const slotData = useSelector<storeType, SlotData|null>((state) => state.slot);
    const slot = slotData ? new Slot(slotData) : null;

    useEffect(() => {
        setSure(false);
    }, [slotData]);

    const close = () => {
        dispatch(ChooseSlot(null));
    }
    const disappoint = () =>
        slot && disAppoint(slot)
            .then(() => {
                props.update();
                close();
            })
            .catch(error => console.log(error));

    if (slot) {
        return (
            <CardStyle>
                <div>
                    <Header>
                        {slot.user
                            ? <a href={slot.user.linkToView()}>
                                {[slot.user.lastname, slot.user.firstname, slot.user.fatherName].join(' ')}
                            </a>
                            : 'Ваша запись'}
                    </Header>
                    <CloseContainer>
                        <Close src={Images.icons.close} onClick={close}/>
                    </CloseContainer>
                </div>
                <Info>
                    <div>
                        <H7>{slot.consultation.title}</H7>
                        <Color color={slot.consultation.color} />
                    </div>
                    <H3>{dateToString(slot.timestamp)}</H3>
                </Info>
                <div>
                    {
                        viewport.device === 'mobile' ?
                            <>
                                <ButtonWhite href={slot.linkToView()}><H5>Посмотреть запись</H5></ButtonWhite>
                                <Message href={URLs.chat + ((me && me.role.isAdmin() && slot.user) ? '?id=' + slot.user.id : '')}>
                                    <img src={Images.icons.message_blue}/>Диалог
                                </Message>
                                {
                                    sure ? <ButtonText onClick={() => setSure(false)}><H5>Закрыть</H5></ButtonText>
                                        : <Cancel onClick={() => setSure(true)}><H5>Отменить запись</H5></Cancel>
                                }
                            </>
                            :
                            <>
                                {
                                    sure ? <ButtonText onClick={() => setSure(false)}><H5>Закрыть</H5></ButtonText>
                                        : <Cancel onClick={() => setSure(true)}><H5>Отменить запись</H5></Cancel>
                                }
                                <ButtonWhite href={slot.linkToView()}><H5>Посмотреть запись</H5></ButtonWhite>
                                {
                                    (slot.timestamp < (new Date()).getTime() / 1000 && me && me.role.isUser())
                                        ?
                                        <Message href={URLs.writeReview + '?slot=' + slot.id}>
                                            Отзыв
                                        </Message>
                                        :
                                        <Message href={URLs.chat + ((me && me.role.isAdmin() && slot.user) ? ('?id=' + slot.user.id) : '')}
                                                 count={slot.user?.unread}>
                                            <img src={Images.icons.message_blue}/>Диалог
                                        </Message>
                                }
                            </>
                    }
                </div>
                {
                    sure && <Sure>
                        <H5>Вы уверены?</H5>
                        <Cancel onClick={disappoint}><H5>Отменить запись</H5></Cancel>
                    </Sure>
                }
            </CardStyle>
        );
    }
    return (<></>);
};

export default Card;
