import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {H3,H5} from "../shared/fonts/fonts";
import Days from "../shared/calendar/Days";
import {Images} from "../../utils/images";
import {useViewport} from "../../utils/ViewportContext";
import {getUserAppointments} from "../../api/methods/slots/get/getUserAppointments";
import {useLocation} from "react-router-dom";
import Card from "../shared/calendar/Card";
import {useSelector} from "react-redux";
import {storeType} from "../../redux/store";

const AppointmentsStyle = styled.div`
  padding: 0px 125px 162px 125px;
  position: relative;
  overflow-x: hidden;
  
  .mobile & {
    padding: 0 0 162px 0;
      margin: 10px;
  }
`;

const AppointmentsContainer = styled.div`
  padding: 0px 0 134px 0;
  border-radius: 16px;
  background: white;
  box-shadow: 48px 24px 48px 0 rgba(24, 37, 56, 0.12);
  display: grid;
  grid-gap: 10px;
  justify-items: center;
  
  .mobile & {
    padding-bottom: 85px;
  }
`;

const HintContainer = styled.div`
  text-align: center;
  padding: 0 0 40px 0;
  display:inline;
  width: 550px;
  .mobile & {
    width: 350px;
  }
`;

const DaysContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content auto min-content;
  width: 100%;
  
  .mobile & {
    grid-template-columns: auto;
    grid-auto-flow: row;
    
    & > :last-child {
      display: grid;
      grid-auto-flow: column;
    }
  }
`;

const ButtonLeft = styled.div`
  padding: 0 50px;
  display: grid;
  align-content: center;
  cursor: pointer;
  
  .mobile & {
    padding: 20px 0;
    width: 100%;
    justify-content: center;
  }
`;

const ButtonRight = styled(ButtonLeft)`
  & img {
    transform: rotate(180deg);
  }
`;

const CloudAppointments = styled.img`
  position: absolute;
  top: 55px;
  left: 0;
  z-index: -1;
`;

const Appointments = () => {
    const viewport = useViewport();
    const consultationsLoading = useSelector<storeType, boolean>((state) => state.consultationsLoading);
    const now = new Date();
    const [currentDate, setCurrentDate] =
        useState(new Date(now.getFullYear(), now.getMonth(), now.getDate()));
    const currentWeekDay = (6 + currentDate.getDay()) % 7;
    const weekStart = new Date(currentDate);
    weekStart.setDate(weekStart.getDate() - currentWeekDay);

    const location = useLocation();

    const updateAppointments = () => {
        if (!consultationsLoading) {
            const user_id_str = (new URLSearchParams(location.search).get('id')) || '';
            const user_id = /^[0-9]+$/.test(user_id_str) ? parseInt(user_id_str) : undefined;
            getUserAppointments(weekStart.getTime() / 1000, user_id);
        }
    }

    useEffect(updateAppointments, [location.search, currentDate, consultationsLoading]);

    const leftButton = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(newDate.getDate() - 7);
        setCurrentDate(newDate)
    }

    const rightButton = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(newDate.getDate() + 7);
        setCurrentDate(newDate)
    }

    const dateData = {currentDate, setCurrentDate, currentWeekDay, weekStart};

    return (
        <AppointmentsStyle>
            <CloudAppointments src={Images.clouds.lk.appointments} />
            <Card update={updateAppointments} />
            <AppointmentsContainer>
                <H3>Приемы</H3>
                <HintContainer>
                  <H5>Здесь Вы видите Ваши </H5> <H5>уже существующие записи.</H5><H5>Для того, чтобы записаться,</H5> <H5>нужно пройти во вкладку "обратиться онлайн"</H5>
                </HintContainer>
                <DaysContainer>
                    {
                        viewport.device === 'mobile' ?
                            <>
                                <Days {...dateData} isDynamic={false} />
                                <div>
                                    <ButtonLeft onClick={leftButton}><img src={Images.icons.leftB} /></ButtonLeft>
                                    <ButtonRight onClick={rightButton}><img src={Images.icons.leftB} /></ButtonRight>
                                </div>
                            </>
                            :
                            <>
                                <ButtonLeft onClick={leftButton}><img src={Images.icons.leftB} /></ButtonLeft>
                                <Days {...dateData} isDynamic={false} />
                                <ButtonRight onClick={rightButton}><img src={Images.icons.leftB} /></ButtonRight>
                            </>
                    }
                </DaysContainer>
            </AppointmentsContainer>
        </AppointmentsStyle>
    );
};

export default Appointments;
