import React from 'react';
import styled from "styled-components";
import {Images} from "../../../utils/images";
import {H5} from "../../shared/fonts/fonts";
import {Links, Strings, URLs} from "../../../utils/constants";
import {ButtonPU} from "../../shared/buttons/buttons";
import ButtonAuth from "../../shared/buttons/ButtonAuth";
import {useViewport} from "../../../utils/ViewportContext";
import FooterLeft from "../../../images/svgs/template/FooterLeft";
import FooterRight from "../../../images/svgs/template/FooterRight";
import FooterMobileCenter from "../../../images/svgs/template/FooterMobileCenter";
import Consultation from "../../../entities/Consultation";

const FooterStyle = styled.div`
  height: 287px;
  background: ${props => props.theme.color.white};
  display: grid;
  grid-auto-flow: column;
  position: relative;
  align-self: end;
`;

const ImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const RightCont = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;

const RightCloudImg = styled.img`
  position: absolute;
  right: 490px;
  bottom: 30px;
`;

const ContentContainer = styled.div`
  display: grid;
  z-index: 1;
  grid-auto-flow: column;
  margin: 0 24px;
  grid-template-columns: min-content minmax(30px, 1fr) auto minmax(30px, 0.5fr) auto minmax(30px, 2fr) auto;
  justify-content: space-between;
  align-items: center;
  
  .tablet & {
    grid-template-columns: min-content minmax(30px, 1fr) auto minmax(30px, 2fr) auto;
    
    & > :nth-child(3) {
      display: grid;
      grid-gap: 20px;
    }
  }
`;

const LeftContainer = styled.div`
  display: grid;
  font-size: 11px;
  line-height: 20px;
  font-weight: 500;
  position: relative;
  
  & div {
    position: absolute;
    top: calc(100% + 34px);
    white-space: nowrap;
  }
`;

const InfoItem = styled(H5)`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-gap: 12px;
  color: ${props => props.theme.color.dpu1};
`;

const RightContainer = styled.div`
  display: grid;
  grid-gap: 21px;
  justify-items: end;
`;

const Icons = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-auto-flow: column;
  align-items: center;
`;

const List = styled.div`
  display: grid;
  grid-gap: 18px;
  
  & a {
    text-decoration: none;
  }
  
  & a:hover {
    text-decoration: underline;
  }
`;

const FooterMobile = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  position: relative;
  overflow: hidden;
  
  padding: 98px 24px 0 24px;
  grid-template-rows: auto 156px auto auto auto;
  
  & > :nth-child(1) {
    position: absolute;
    top: 0;
    z-index: -1;
    padding-left: 56px;
  }

  & > :nth-child(2) {
    justify-items: center;
    
    & a, & H5 {
      color: white;
    }
  }
  
  & > :nth-child(4) {
    opacity: 0.7;
  }
  
  & > :nth-child(5) {
    display: grid;
    justify-items: center;
    margin: 90px;

    font-size: 11px;
    line-height: 20px;
    font-weight: 500;
    grid-gap: 16px;
  }
  
  & > :last-child {
    display: grid;
    grid-template-rows: auto 20px auto 62px auto;
    justify-items: center;
    padding-bottom: 82px;
  }
`;

const Footer = () => {
    const viewport = useViewport();

    if (viewport.device === "mobile") {
        return (
            <FooterMobile>
                <div>
                    <FooterMobileCenter />
                </div>
                <List>
                    <a href={URLs.main}><H5>Главная</H5></a>
                    <a href={URLs.about}><H5>Обо мне</H5></a>
                    <a href={URLs.info}><H5>Полезная информация</H5></a>
                </List>
                <div />
                <List>
                    <InfoItem><img src={Images.icons.mail}/>{Strings.email}</InfoItem>
                    <InfoItem><img src={Images.icons.phone}/>{Strings.phone}</InfoItem>
                    <InfoItem><img src={Images.icons.geolocation}/>{Strings.address}</InfoItem>
                </List>
                <div>
                    <img src={Images.logoSquareDP}/>
                    <div>© 2023 Сиюхова З. А. Все права защищены</div>
                </div>
                <div>
                    <ButtonPU href={Consultation.linkToView()}><H5>Обратиться онлайн</H5></ButtonPU>
                    <div />
                    <ButtonAuth/>
                    <div />
                    <Icons>
                        <a href="https://prodoctorov.ru/krasnodar/vrach/458594-siuhova/"  target="_blank">
                            <img src="https://prodoctorov.ru/rating/d458594/" title="Рейтинг - Сиюхова З. А." />
                        </a>
                        <a href={Links.telegram}><img src={Images.icons.telegram}/></a>
                        {/*<a href={Links.instagram}><img src={Images.icons.instagram}/></a>*/}
                    </Icons>
                </div>
            </FooterMobile>
        )
    }
    else {
        return (
            <FooterStyle>
                <ImageContainer>
                    <FooterLeft />
                    <RightCont>
                        <FooterRight />
                        <RightCloudImg src={Images.clouds.template.center}/>
                    </RightCont>
                </ImageContainer>
                <ContentContainer>
                    <LeftContainer>
                        <img src={Images.logoSquare}/>
                        <div>© 2023 Сиюхова З. А. Все права защищены</div>
                    </LeftContainer>
                    <div/>
                    {
                        viewport.device === 'tablet' ?
                            <div>
                                <List>
                                    <InfoItem><img src={Images.icons.mail}/>{Strings.email}</InfoItem>
                                    <InfoItem><img src={Images.icons.phone}/>{Strings.phone}</InfoItem>
                                    <InfoItem><img src={Images.icons.geolocation}/>{Strings.address}</InfoItem>
                                </List>
                                <List>
                                    <a href={URLs.main}><H5>Главная</H5></a>
                                    <a href={URLs.about}><H5>Обо мне</H5></a>
                                    <a href={URLs.info}><H5>Полезная информация</H5></a>
                                </List>
                            </div>
                            :
                            <>
                                <List>
                                    <InfoItem><img src={Images.icons.mail}/>{Strings.email}</InfoItem>
                                    <InfoItem><img src={Images.icons.phone}/>{Strings.phone}</InfoItem>
                                    <InfoItem><img src={Images.icons.geolocation}/>{Strings.address}</InfoItem>
                                </List>
                                <div/>
                                <List>
                                    <a href={URLs.main}><H5>Главная</H5></a>
                                    <a href={URLs.about}><H5>Обо мне</H5></a>
                                    <a href={URLs.info}><H5>Полезная информация</H5></a>
                                </List>
                            </>
                    }
                    <div/>
                    <RightContainer>
                        <Icons>
                            <a href="https://prodoctorov.ru/krasnodar/vrach/458594-siuhova/"  target="_blank">
                                <img src="https://prodoctorov.ru/rating/d458594/" title="Рейтинг - Сиюхова З. А." />
                            </a>
                            <a href={Links.telegram}><img src={Images.icons.telegram}/></a>
                            {/*<a href={Links.instagram}><img src={Images.icons.instagram}/></a>*/}
                        </Icons>
                        <ButtonAuth/>
                        <ButtonPU href={Consultation.linkToView()}><H5>Обратиться онлайн</H5></ButtonPU>
                    </RightContainer>
                </ContentContainer>
            </FooterStyle>
        );
    }
};

export default Footer;
