import logo from '../images/logo.svg';
import logoSquare from '../images/logoSquare.svg';
import logoSquareDP from '../images/logoSquareDP.svg';

import gradient1 from '../images/backgrounds/gradient1.svg';
import gradient2 from '../images/backgrounds/gradient2.svg';
import gradient3 from '../images/backgrounds/gradient3.svg';
import photo from '../images/backgrounds/photo_1000.png';

import menu from '../images/icons/menu.svg';
import menu_pink from '../images/icons/menu_pink.svg';
import twitter_pink from '../images/icons/twitter_pink.png';
import instagram_pink from '../images/icons/instagram_pink.png';
import center from '../images/clouds/template/center.svg';
import telegram_pink from '../images/icons/telegram_pink.svg';
import inn from '../images/icons/inn.svg';
import document from '../images/icons/document.svg';

import point from '../images/clouds/about/point.svg';
import aboutTopCloud from '../images/clouds/about/topCloud.svg';
import workPlacesCloud from '../images/clouds/about/workPlacesCloud.svg';

import rightTopInfo from '../images/clouds/info/rightTop.png';

import appointments from '../images/clouds/lk/appointments.png';

import twitter from '../images/icons/twitter.svg';
import telegram from '../images/icons/telegram.svg';

import instagram from '../images/icons/instagram.svg';
import instagramB from '../images/icons/instagramB.svg';

import mail from '../images/icons/mail.svg';
import mailB from '../images/icons/mailB.svg';

import phone from '../images/icons/phone.svg';
import phoneB from '../images/icons/phoneB.svg';

import geolocation from '../images/icons/geolocation.svg';
import geolocationB from '../images/icons/geolocationB.svg';
import geolocationBS from '../images/icons/geolocationBS.svg';

import articles from '../images/icons/articles.svg';
import links from '../images/icons/links.svg';
import video from '../images/icons/video.svg';

import arrowDown from '../images/icons/arrowDown.svg';

import linkL from '../images/icons/linkL.svg';
import videoWindow from '../images/icons/video.png';

import send from '../images/icons/send.svg';
import down from '../images/icons/down.png';
import left from '../images/icons/left.svg';

import lock from '../images/icons/lock.svg';

import seen from '../images/icons/seen.svg';
import unSeen from '../images/icons/unSeen.svg';

import message from '../images/icons/message.svg';
import message_blue from '../images/icons/message_blue.svg';

import leftB from '../images/icons/leftB.png';

import success from '../images/icons/success.svg';
import avatar from '../images/icons/avatar.png';
import close from '../images/icons/close.svg';

import info from '../images/photos/info.png';

import avatarAdmin from '../images/photos/avatar.png';

import comm_skype from '../images/icons/comm_skype.svg';
import comm_whatsapp from '../images/icons/comm_whatsapp.svg';
import comm_telegram from '../images/icons/comm_telegram.svg';

import required from '../images/icons/required.svg';
import check from '../images/icons/check.svg';

import preview_DOVO from '../images/photos/previews/Диплом о высшем образовании.png';
import preview_DOOO from '../images/photos/previews/Диплом об окончании ординатуры.png';
import preview_SSNE from '../images/photos/previews/Сертификат специалиста НМИЦ Эндокринологии.png';
import preview_SSENC from '../images/photos/previews/Сертификат специалиста Эндокринологический научный центр.png';

export const Images = {
    logo,
    logoSquare,
    logoSquareDP,
    backgrounds: {
        gradient1,
        gradient2,
        gradient3,
        photo
    },
    previews: {
        DOVO: preview_DOVO,
        DOOO: preview_DOOO,
        SSNE: preview_SSNE,
        SSENC: preview_SSENC
    },
    clouds: {
        template: {
            center
            //footer
        },
        about: {
            point,
            topCloud: aboutTopCloud,
            workPlacesCloud
        },
        info: {
            rightTop: rightTopInfo
        },
        lk: {
            appointments
        }
    },
    icons: {
        check,

        comm_skype,
        comm_telegram,
        comm_whatsapp,
        required,

        seen,
        unSeen,
        inn,
        document,

        menu,
        menu_pink,
        twitter_pink,
        instagram_pink,
        telegram_pink,

        twitter,
        telegram,

        instagram,
        instagramB,

        mail,
        mailB,

        phone,
        phoneB,

        geolocation,
        geolocationB,
        geolocationBS,

        arrowDown,

        articles,
        links,
        video,

        linkL,
        videoWindow,
        send,
        down,
        left,

        message,
        message_blue,

        leftB,

        success,
        avatar,
        close,

        lock
    },
    photos: {
        info,
        avatarAdmin
    }
}
