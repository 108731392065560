import React, {useEffect, useState} from 'react';
import {H3, H5} from "../../shared/fonts/fonts";
import {getSlots} from "../../../api/methods/slots/get/getSlots";
import DayTimetable, {Cell, getCell} from "../timetable/DayTimetable";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {storeType} from "../../../redux/store";
import {ButtonPU, ButtonText} from "../../shared/buttons/buttons";
import SelectConsultation from "../../shared/timetable/selectConsultation";
import Week from "../shared/Week";
import Slot, {SlotData} from "../../../entities/Slot";
import Consultation, {ConsultationData} from "../../../entities/Consultation";
import {copySlots} from "../../../redux/actions";
import {save} from "../../../api/methods/slots/update/save";

const Days = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  grid-auto-columns: 1fr;
`;

const Cons = styled.div<{color?: string}>`
  display: grid;
  grid-auto-flow: column;
  align-items: end;
  justify-content: space-between;
  
  /*& select {
    border-bottom-color: #${props => props.color};
    border-bottom-width: 4px;
  }*/
`;

const TimetableStyle = styled.div`
  display: grid;
  grid-gap: 10px;
`;

const Buttons = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
`;

const ButtonCancel = styled(ButtonText)`
  & h5 {
    color: ${props => props.theme.color.pi2};
  }
`;

const Timetable = () => {
    const date = new Date();
    const currentWeekDay = (6 + date.getDay()) % 7;
    date.setDate(date.getDate() - currentWeekDay);
    date.setHours(0, 0, 0, 0);
    const [firstDayOfWeek, setFirstDayOfWeek] = useState(date);

    const [addedSlots, setAddedSlots] = useState<Slot[]>([]);
    const [deletedSlots, setDeletedSlots] = useState<Slot[]>([]);

    const [cons, setCons_] = useState<Consultation|undefined>(undefined);
    const setCons = (e:any) => setCons_(consultations.find(c => c.id === parseInt(e.target.value)));

    const consultationsData = useSelector<storeType, ConsultationData[]>((state) => state.consultations);
    const consultations = consultationsData.map(c => new Consultation(c));
    const slotsData = useSelector<storeType, SlotData[]>((state) => state.slots);
    const [slots, setSlots] = useState<Slot[]>([]);
    const slotsLoading = useSelector<storeType, boolean>((state) => state.slotsLoading);
    const copiedSlotsData = useSelector<storeType, SlotData[]>((state) => state.copiedSlots);
    const copiedSlots = copiedSlotsData.map(s => new Slot(s));
    const [slotsToPaste, setSlotsToPaste] = useState<Slot[]>([]);
    const dispatch = useDispatch();

    const addSlot = (ts: number) =>
        cons && setAddedSlots([...addedSlots, new Slot({id: 0, timestamp: ts, consultation: cons})]);

    const deleteAddedSlot = (ts: number) =>
        cons && setAddedSlots([...addedSlots.filter(s => s.timestamp !== ts)]);

    const deleteSlot = (slot: Slot) =>
        cons && setDeletedSlots([...deletedSlots, slot]);

    useEffect(() => {
        dispatch(copySlots([]));
    }, [dispatch]);

    useEffect(() => {
        if (consultations.length) {
            setCons_(consultations[0]);
        }
    }, [consultationsData]);

    useEffect(() => {
        setSlots(slotsData.filter(s => !deletedSlots.map(s_ => s_.id).includes(s.id)).map(s => new Slot(s)));
    }, [slotsData, deletedSlots]);

    useEffect(() => {
        setSlotsToPaste(copiedSlots.filter(s => {
            const cell = getCell(null, addedSlots, slots, s.consultation,
                s.timestamp + firstDayOfWeek.getTime() / 1000);
            return cell.is_actual && cell.type === Cell.empty;
        }));
    }, [addedSlots, slots, copiedSlotsData]);

    const days = [];
    if (cons) {
        for (let i = 0; i < 7; i++) {
            days.push(<DayTimetable key={i} addedSlots={addedSlots}
                                    date={new Date(firstDayOfWeek.getTime() + 24 * 60 * 60 * 1000 * i)}
                                    cons={cons} slots={slots}
                                    functions={{addSlot: addSlot, deleteAddedSlot: deleteAddedSlot, deleteSlot: deleteSlot}}
            />);
        }
    }

    const save_ = () =>
        save(addedSlots, deletedSlots)
            .then(() => cancel())
            .catch(error => console.log(error));

    const cancel = () => {
        setAddedSlots([]);
        setDeletedSlots([]);
        getSlots(firstDayOfWeek.getTime() / 1000);
    }

    const copy = () => dispatch(copySlots(slots.map(s => {
        const s_ = s.getValue();
        s_.id = 0;
        s_.paid = 0;
        s_.timestamp -= firstDayOfWeek.getTime() / 1000;
        return s_;
    })));

    const paste = () =>
        setAddedSlots([...addedSlots, ...slotsToPaste.map(s => {
            s.timestamp += firstDayOfWeek.getTime() / 1000;
            return s;
        })]);

    useEffect(() => getSlots(firstDayOfWeek.getTime() / 1000), [firstDayOfWeek]);

    return (
        <TimetableStyle>
            <H3>Расписание</H3>
            <Week firstDayOfWeek={firstDayOfWeek} setFirstDayOfWeek={setFirstDayOfWeek} />
            <Cons color={cons?.color}>
                <SelectConsultation cons={cons} setCons={setCons} />
                <Buttons>
                    <ButtonPU onClick={copy} className={(slotsLoading || slotsData.length === 0) ? 'disabled' : undefined}>
                        <H5>Скопировать</H5>
                    </ButtonPU>
                    <ButtonPU onClick={paste} className={(slotsLoading || slotsToPaste.length === 0) ? 'disabled' : undefined}>
                        <H5>Вставить</H5>
                    </ButtonPU>
                    <ButtonCancel onClick={cancel}><H5>Сбросить</H5></ButtonCancel>
                    <ButtonPU onClick={save_} className={(addedSlots.length === 0 && deletedSlots.length === 0) ? 'disabled' : undefined}>
                        <H5>Сохранить</H5>
                    </ButtonPU>
                </Buttons>
            </Cons>
            <Days>
                {
                    days
                }
            </Days>
        </TimetableStyle>
    );
};

export default Timetable;
