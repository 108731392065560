import React from 'react';
import Reviews from "../components/main/Reviews";
import Contacts from "../components/about/Contacts";
import WorkPlaces from "../components/about/WorkPlaces";
import Template from "../components/template/template";
import Top from "../components/about/Top";

const About = () => {
    return (
        <Template>
            <Top />
            <WorkPlaces />
            <Contacts />
            <Reviews />
        </Template>
    );
};

export default About;