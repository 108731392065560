import React, {useEffect, useState} from 'react';
import post from "../../../api/post";
import {H5} from "../../shared/fonts/fonts";
import {useSelector} from "react-redux";
import {storeType} from "../../../redux/store";
import {video} from "../../../utils/types";
import {BlocksStyle, BlockStyle, Button, ButtonVisible, ColumnButtons, Header} from "./shared";
import styled from "styled-components";
import {InputText} from "../../shared/form/input";

const Text = styled.div`
  display: grid;
  grid-gap: 10px;
`;

const Video = (props: {data: video, updateVideosList: () => void, order_i: number, videos: video[]}) => {
    const token = useSelector<storeType, string|null>((state) => state.token);
    const [title, setTitle_] = useState(props.data.title);
    const [href, setHref_] = useState(props.data.href);
    const [deleting, setDeleting] = useState(false);

    const setTitle = (e: any) => setTitle_(e.target.value);
    const setHref = (e: any) => setHref_(e.target.value);

    const setVisibility = () => {
        post('setVideoVisible', {token, video_id: props.data.id, visibility: 1 - props.data.visible})
            .then(props.updateVideosList)
            .catch();
    }

    const changed = title !== props.data.title || href !== props.data.href;

    const save = () =>
        post('editVideo', {token, video_id: props.data.id, title, href})
            .then(() => {
                props.updateVideosList();
            })
            .catch();

    const cancel = () => {
        setTitle_(props.data.title);
        setHref_(props.data.href);
    }

    const deleteVideo = () => setDeleting(true);
    const cancelDeleteVideo = () => setDeleting(false);
    const sureDeleteVideo = () =>
        post('deleteVideo', {token, video_id: props.data.id})
            .then(() => {
                props.updateVideosList();
            })
            .catch();

    const setOrder = (vector: number) => {
        const changeWith = props.videos.at(props.order_i + vector);
        changeWith && post('setVideoOrder', {token, video_id: props.data.id, order_id: changeWith.order_id})
            .then(() =>
                post('setVideoOrder', {token, video_id: changeWith.id, order_id: props.data.order_id})
                    .then(() => {
                        props.updateVideosList();
                    })
                    .catch())
            .catch();
    }

    return (
        <BlockStyle>
            <Header className={props.data.visible === 1 ? 'visible' : ''}>
                <ButtonVisible className={(props.data.visible === 0 && (!props.data.title || !props.data.href)) ? 'disabled' : ''} onClick={setVisibility}>
                    <H5>{props.data.visible === 0 ? 'Сделать видимым' : 'Сделать невидимым'}</H5>
                </ButtonVisible>
                <Button onClick={() => setOrder(-1)} className={props.order_i === 0 ? 'disabled' : ''}>
                    <H5>▲</H5>
                </Button>
                <Button onClick={() => setOrder(1)} className={props.order_i === props.videos.length - 1 ? 'disabled' : ''}>
                    <H5>▼</H5>
                </Button>
                <Button onClick={save} className={changed ? '' : 'disabled'}>
                    <H5>Сохранить изменения</H5>
                </Button>
                <Button onClick={cancel} className={changed ? '' : 'disabled'}>
                    <H5>Отменить изменения</H5>
                </Button>
                {
                    deleting ?
                        <ColumnButtons>
                            <Button onClick={sureDeleteVideo}>
                                <H5>Удалить</H5>
                            </Button>
                            <Button onClick={cancelDeleteVideo}>
                                <H5>Отмена</H5>
                            </Button>
                        </ColumnButtons>
                        :
                        <Button onClick={deleteVideo}>
                            <H5>Удалить</H5>
                        </Button>
                }
            </Header>
            <Text>
                <InputText input={{value: title, onInput: setTitle, style: {borderWidth: '2px', borderStyle: 'dashed'},
                    placeholder: 'Введите название видео'}} />
                <InputText input={{value: href, onInput: setHref, style: {borderWidth: '2px', borderStyle: 'dashed'},
                    placeholder: 'Введите ссылку на YouTube-видео'}} />
            </Text>
        </BlockStyle>
    )
}

const Videos = () => {
    const token = useSelector<storeType, string|null>((state) => state.token);
    const [videos, setVideos] = useState<video[]>([]);

    const updateVideosList = () => {
        setVideos([]);
        post('getVideosList', {token})
            .then(result => setVideos(result.data.videos))
            .catch();
    }

    useEffect(() => {
        updateVideosList();
    }, []);

    const create = () =>
        post('createVideo', {token})
            .then(updateVideosList)
            .catch();

    return (
        <BlocksStyle>
            <Button onClick={create}><H5>Создать видео</H5></Button>
            {videos.map((v, i) =>
                <Video order_i={i} videos={videos} updateVideosList={updateVideosList} key={i} data={v} />)}
        </BlocksStyle>
    );
};

export default Videos;