import React from 'react';
import Dialogs from "../components/chat/Dialogs";
import Messages from "../components/chat/Messages";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {storeType} from "../redux/store";
import Template from "../components/template/template";
import User, {UserData} from "../entities/User";
import Loading from "../components/shared/form/loading";

const ChatContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: minmax(33%, 477px) 66%;
  height: calc(100vh - 72px);
  grid-template-rows: 100%;
  position: relative;
  
  &.user {
    grid-template-columns: 0 100%;
  }
  
  .mobile &.admin {
    grid-template-columns: 100% 0;
    overflow-x: hidden;
  }
  .mobile &.admin.messages {
    grid-template-columns: 0 100%;
  }
`;

const Chat = () => {
    const meData = useSelector<storeType, UserData | null>((state) => state.me);
    const currentChatData = useSelector<storeType, UserData|null>((state) => state.currentChat);

    const me = meData ? new User(meData) : null;
    const currentChat = currentChatData ? new User(currentChatData) : null;

    return (
        <Template noFooter={true}>
            {
                me
                    ?
                    <ChatContainer className={me.role.isAdmin() ? 'admin ' + ((currentChat === null) ? 'chats' : 'messages') : 'user'}>
                        <Dialogs />
                        <Messages />
                    </ChatContainer>
                    : <Loading />
            }
        </Template>
    );
};

export default Chat;
