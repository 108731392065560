import store from "../../../../redux/store";
import {setSlots} from "../../../../redux/actions";
import get from "../../../get";

export const getAppointments = (start: number) => {
    get('appointments/all', {from: start})
        .then(result => {
            store.dispatch(setSlots(result.data.appointments));
        })
        .catch(error => console.log(error))
}
