import React, {useEffect, useState} from 'react';
import Form from "../shared/form/form";
import {Images} from "../../utils/images";
import {H2, H5, P1} from "../shared/fonts/fonts";
import {ButtonPU, ButtonWhite} from "../shared/buttons/buttons";
import styled from "styled-components";
import {useLocation} from "react-router-dom";
import Slot from "../../entities/Slot";
import User from "../../entities/User";

export const ResultFormStyle = styled(Form)`
  max-width: calc(100% - 700px);
  text-align: center;
  width: auto;
  
  & h2 {
    white-space: normal;
  }
  
  .mobile & {
    max-width: none;
  }
`;

const Buttons = styled.div`
  display: grid;
  grid-gap: 10px;
  justify-items: center;
`;

const Success = () => {
    const loc = useLocation();
    const id_string = new URLSearchParams(loc.search).get("id");
    const [id, setId] = useState<number|null>(null);

    useEffect(() => {
        if (id_string && /^[0-9]+$/.test(id_string)) {
            setId(parseInt(id_string));
        }
    }, [id_string]);

    return (
        <ResultFormStyle>
            <img src={Images.icons.success}/>
            <H2>Запись прошла успешно</H2>
            <P1>Информация появится в личном кабинете.</P1>
            <Buttons>
                <ButtonWhite href={Slot.linkToView(id || undefined)}><H5>Посмотреть запись</H5></ButtonWhite>
                <ButtonPU href={User.linkToView()}><H5>Личный кабинет</H5></ButtonPU>
            </Buttons>
        </ResultFormStyle>
    );
};

export default Success;
