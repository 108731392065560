import store from "../../../../redux/store";
import {AddChats, UpdateChat} from "../../../../redux/actions";
import get from "../../../get";
import {getUser} from "../../getUser";
import User from "../../../../entities/User";

export const updateChat = (user: User) => {
    getUser(user.id).then(user => store.dispatch(UpdateChat(user)));
}

export const getNextChats = () =>
    new Promise((resolve: (value?: any) => void, reject: (error: any) => void) =>
        get('chats', {limit: 50, offset: store.getState().chats.length})
            .then(result => {
                store.dispatch(AddChats(result.data.chats));
                resolve();
            })
            .catch(error => {
                console.log(error);
                reject(error);
            }));