import store from "../../../../redux/store";
import {PushMessages, SetLastSeenMessageId} from "../../../../redux/actions";
import User from "../../../../entities/User";
import get from "../../../get";
import {updateChat} from "./getChats";

export const signAtNewMessages = (user: User, controller: AbortController) => {
    const lastMessage = store.getState().messages.at(-1);
    get('messages/new',
        {from_id: lastMessage ? lastMessage.id : 0,
            user_id: user.id,
            last_seen_id: store.getState().lastSeenMessageId},
        {signal: controller.signal, timeout: 30000})
        .then(result => {
            store.dispatch(PushMessages(result.data.messages.reverse()));
            store.dispatch(SetLastSeenMessageId(result.data.last_seen_message_id));
            const meData = store.getState().me;
            const currentChatData = store.getState().currentChat
            if (meData && currentChatData) {
                const me = new User(meData);
                const currentChat = new User(currentChatData);
                me && me.role.isAdmin() && updateChat(currentChat);
            }
            signAtNewMessages(user, controller);
        })
        .catch(() => {
            //console.log('aborted');
            //setTimeout(() => signAtNewMessages(stamp, user_id), 1000)
        });
}
