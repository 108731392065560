import styled from "styled-components";

export const ElementStyle = styled.div`
  border: 2px solid black;
  padding: 20px;
  border-radius: 20px;
  display: grid;
  grid-gap: 16px;
  
  & input[type=color] {
    height: 60px;
    width: 60px;
    cursor: pointer;
  }
`;

export const ElementAddStyle = styled(ElementStyle)`
  cursor: pointer;
  border-style: dashed;
  opacity: 0.5;
  
  &:hover {
    opacity: 1;
  }
  
  & h5 {
    font-style: italic;
  }
`;

export const ElementsContainer = styled.div`
  //margin-top: 20px;
  display: grid;
  grid-gap: 10px;
`;

export const ElementHeaderStyle = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  
  & > h5 {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ElementHeaderActions = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
`;

export const ElementHeaderDeleteContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 8px;
`;

export const ElementHeaderButtonsOrder = styled.div`
  display: grid;
  margin: -20px 0;
  align-content: center;
  justify-items: center;
  color: ${props => props.theme.color.b1};
  
  & div {
    opacity: 0.5;
    cursor: no-drop;
    user-select: none;
  }
  
  & div.active {
    cursor: pointer;
  }

  & div.active:hover {
    opacity: 1;
  }
`;

export const ElementEditButtonsCenter = styled.div`
  display: grid;
  grid-gap: 10px;
  justify-items: center;
  grid-auto-flow: column;
  justify-content: center;
`;

export const ElementHeaderEmpty = styled.span`
  opacity: 0.5;
`;