const Theme = {
    color : {
        white: '#FFFFFF',

        bl1: '#18191F',
        bl2: '#474A57',
        bl3: '#969BAB',
        bl4: '#D9DBE1',
        bl5: '#EEEFF4',

        gray100: '#F4F5F7',

        pu1: '#7A4DDA',
        pu2: '#B994E9',
        pu3: '#E7C9FF',
        pu4: '#F8E5FB',

        dpu1: '#512DA8',
        dpu2: '#896ED5',
        dpu3: '#ACA1EC',
        dpu4: '#DED9F7',
        dpu5: '#F3F1FC',

        bb2: '#F6FDFF',

        y1: '#FFF389',
        g1: '#9BD5AF',
        bb1: '#ABD5FC',
        pi2: '#E96796',
        b1: '#6675FF',
        b2: '#96ADFF',
        pi1: '#FCA5C4',
    },
    img: {
        g1: '#B994E9',
        g2: '',
    },
    effect: {

    }
}

export default Theme;