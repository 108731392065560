import React, {useEffect, useState} from 'react';
import {H2, H5} from "../shared/fonts/fonts";
import SelectConsultation from "../shared/timetable/selectConsultation";
import Days from "../shared/calendar/Days";
import Calendar from "./Calendar";
import styled from "styled-components";
import {ButtonPU} from "../shared/buttons/buttons";
import {useDispatch, useSelector} from "react-redux";
import {storeType} from "../../redux/store";
import {ChooseSlot} from "../../redux/actions";
import {useParams} from "react-router-dom";
import {getWeek} from "../../api/methods/slots/get/getWeek";
import {pageProps} from "../../pages/appointment";
import Error from "../auth/Error";
import {useViewport} from "../../utils/ViewportContext";
import Consultation, {ConsultationData} from "../../entities/Consultation";
import Slot, {SlotData} from "../../entities/Slot";
import {book} from "../../api/methods/slots/update/book";

const AppointmentStyle = styled.div`
  display: grid;
  padding: 68px 136px;
  justify-items: start;
  grid-template-rows: auto 52px auto 50px auto 52px auto;
  
  & h2, & button {
    justify-self: center;
  }
  
  .mobile & h2 {
    white-space: normal;
  }
  
  .mobile & {
    padding: 68px 10px;
  }
`;

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 50px;
  width: 100%;
  grid-template-columns: auto min-content;
  
  .mobile & {
    grid-template-columns: auto;
    grid-auto-flow: row;
  }
`;

const ButtonPUStyle = styled(ButtonPU)`
  justify-self: center;
`;

export const ByMoscowTime = styled.span`
    color: ${props => props.theme.color.bl2};
    font-size: 14px;
    
    & span {
        color: ${props => props.theme.color.bl1};
        font-weight: 700;
    }
`;

const Main = (props: pageProps) => {
    const now = new Date();
    const [currentDate, setCurrentDate] =
        useState(new Date(now.getFullYear(), now.getMonth(), now.getDate()));
    const currentWeekDay = (6 + currentDate.getDay()) % 7;
    const weekStart = new Date(currentDate);
    weekStart.setDate(weekStart.getDate() - currentWeekDay);

    const consultationsData = useSelector<storeType, ConsultationData[]>((state) => state.consultations);
    const consultations = consultationsData.map(c => new Consultation(c));
    const slotData = useSelector<storeType, SlotData|null>((state) => state.slot);
    const slot = slotData ? new Slot(slotData) : null;

    const consultationsLoading = useSelector<storeType, boolean>((state) => state.consultationsLoading);
    const [cons, setCons_] = useState<Consultation|undefined>(undefined);
    const [error, setError] = useState<string|null>(null);
    const viewport = useViewport();

    const dispatch = useDispatch();

    const setCons = (e:any) => {
        const id_ = parseInt(e.target.value);
        setCons_(consultations.find(c => c.id === id_));
        dispatch(ChooseSlot(null));
    }

    const updateSlots = () => {
        getWeek(weekStart.getTime() / 1000, cons?.id);
    }

    const params = useParams();
    const pre_consultation_id = params.id ? parseInt(params.id) : null;

    useEffect(() => {
        if (!consultationsLoading) {
            if (pre_consultation_id) {
                const c_ = consultations.find(c => c.id === pre_consultation_id);
                if (c_) {
                    return setCons_(c_);
                }
            }
        }
    }, [consultationsData, consultationsLoading]);

    useEffect(updateSlots, [cons, currentDate]);

    const next = () =>
        slot && book(slot)
            .then(() => {
                props.setPage('confirmation', {slot: slot});
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.text) {
                    setError(error.response.data.text);
                }
                else {
                    setError('Неизвестная ошибка. Попробуйте позже.');
                }
            });

    const dateData = {currentDate, setCurrentDate, currentWeekDay, weekStart};

    return (
        <AppointmentStyle>
            <H2>Запись на онлайн-информирование</H2>
            <div/>
            <SelectConsultation cons={cons} setCons={setCons} allowAny={true} />
            <div/>
            {/*<H5>Время приема · <ByMoscowTime>по <span>московскому</span> времени</ByMoscowTime></H5>
            <div/>*/}
            <Container>
                {viewport.device === 'mobile'
                    ? <><Calendar {...dateData} />
                        <Days {...dateData} isDynamic={true} /></>
                    : <><Days {...dateData} isDynamic={true} />
                        <Calendar {...dateData} /></>}
            </Container>
            <div/>
            {
                error && <Error>{error}</Error>
            }
            <ButtonPUStyle className={slot ? '' : 'disabled'} onClick={next}><H5>Продолжить</H5></ButtonPUStyle>
        </AppointmentStyle>
    );
};

export default Main;
