import Role, {ROLES, ROLES_VALUES} from "./Role";
import env from "../env";
import patch from "../api/patch";
import {URLs} from "../utils/constants";

export interface UserData {
    id: number,
    firstname: string,
    lastname: string,
    fatherName: string|null,
    phone: string,
    email: string,
    role: ROLES_VALUES,
    hasAvatar: boolean,
    unread: number,
    birthdate: number,
    first_message_id?: number
}

class User {
    id: number;
    firstname: string;
    lastname: string;
    fatherName: string|null;
    phone: string;
    email: string;
    role: Role;
    hasAvatar: boolean;
    unread: number = 0;
    birthdate: Date;
    first_message_id: number = 0;

    constructor(data: UserData) {
        this.id = data.id;
        this.firstname = data.firstname;
        this.lastname = data.lastname;
        this.fatherName = data.fatherName;
        this.phone = data.phone;
        this.email = data.email;
        this.role = new Role(data.role);
        this.hasAvatar = data.hasAvatar;
        this.unread = data.unread;
        this.birthdate = new Date(data.birthdate * 1000);
        this.first_message_id = data.first_message_id || 0;
    }

    age = () => {
        const now = new Date();
        const age = Math.floor((now.getTime() - this.birthdate.getTime()) /
            (1000 * 60 * 60 * 24 * 365));

        if (age > 4 && age < 21) {
            return age + ' лет'
        }
        if (age % 10 === 1) {
            return age + ' год'
        }
        if (age % 10 > 1 && age % 10 < 5) {
            return age + ' года'
        }
        return age + ' лет'
    }

    static avatar = () => '';

    avatar = (random: boolean = false) =>
        (this.hasAvatar) ? (env.API_HOST + '/assets/avatars/' + this.id + (random ? ('?' + (new Date()).getTime()) : '')) : '';

    getValue:() => UserData = () => {
        return {
            id: this.id,
            firstname: this.firstname,
            lastname: this.lastname,
            fatherName: this.fatherName,
            phone: this.phone,
            email: this.email,
            role: this.role.getValue(),
            hasAvatar: this.hasAvatar,
            unread: this.unread,
            birthdate: this.birthdate.getTime() / 1000,
            first_message_id: this.first_message_id
        }
    }

    static edit = (data: {lastname: string, firstname: string, fatherName: string, birthdate: Date}) =>
        new Promise((resolve: (value?: any) => void, reject: (error: string | null) => void) => {
            patch('user/me', {...data, birthdate: data.birthdate.getTime() / 1000})
                .then(resolve)
                .catch(error =>
                    reject(error.response?.data?.text || null));
        });

    static linkToView = (user_id?: number) => URLs.lk.replace(':id?', user_id ? String(user_id) : 'me');
    linkToView = () => User.linkToView(this.id);
}

export const EMPTY_USER:UserData = {
    id: 0,
    firstname: '',
    lastname: '',
    fatherName: null,
    phone: '',
    email: '',
    role: ROLES.VISITOR.getValue(),
    hasAvatar: false,
    unread: 0,
    birthdate: 0
};

export default User;