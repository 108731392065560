import React from 'react';
import styled from "styled-components";

const PointStyle = styled.div`
  animation: loading 0.6s infinite ease-in-out;
  width: 10px;
  height: 10px;
  background: ${props => props.theme.color.b1};
  border-radius: 2px;
  
  @keyframes loading {
    0% {opacity: 1}
    100% {opacity: 0}
  }
`;

const PointStyle2 = styled(PointStyle)`
  animation-delay: 0.2s;
`;

const PointStyle3 = styled(PointStyle)`
  animation-delay: 0.4s;
`;

const LoadingStyle = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  justify-content: center;
`;
const Loading = () => {
    return (
        <LoadingStyle>
            <PointStyle />
            <PointStyle2 />
            <PointStyle3 />
        </LoadingStyle>
    );
};

export default Loading;