import React, {useEffect, useState} from 'react';
import {H3, H5} from "../../shared/fonts/fonts";
import Week from "../shared/Week";
import {CellAppointmentStyle, CellBookedStyle, CellStyle, DayTimetableStyle} from "../shared/table";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {storeType} from "../../../redux/store";
import {weekDays} from "../../../utils/constants";
import Card from "../../shared/calendar/Card";
import {getAppointments} from "../../../api/methods/slots/get/getAppointments";
import {timeToString, toLocaleDateStringByMoscow} from "../../../utils/functions";
import Slot, {SlotData} from "../../../entities/Slot";
import {ChooseSlot} from "../../../redux/actions";

const AppointmentsStyle = styled.div`
  display: grid;
  grid-gap: 10px;
`;

const Days = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  grid-auto-columns: 1fr;
`;

const NoSlots = styled(CellStyle)`
  font-style: italic;
  
  & > td {
    border: none;
  }
`;

const Appointments = () => {
    const date = new Date();
    const currentWeekDay = (6 + date.getDay()) % 7;
    date.setDate(date.getDate() - currentWeekDay);
    date.setHours(0, 0, 0, 0);
    const [firstDayOfWeek, setFirstDayOfWeek] = useState(date);
    const dispatch = useDispatch();

    const slotsData = useSelector<storeType, SlotData[]>((state) => state.slots);
    const slots = slotsData.map(s => new Slot(s));

    const days = [];
    for (let i = 0; i < 7; i++) {
        const date_ = new Date(firstDayOfWeek.getTime() + 24 * 60 * 60 * 1000 * i);
        days.push({date: date_,
            slots: slots.filter(slot => {
                const ts = date_.getTime() / 1000;
                return (ts <= slot.timestamp && slot.timestamp < ts + 24 * 60 * 60)
            })});
    }

    const chooseSlot = (slot: Slot) => dispatch(ChooseSlot(slot.getValue()));

    const update = () => getAppointments(firstDayOfWeek.getTime() / 1000);

    useEffect(() => {
        update();
    }, [firstDayOfWeek]);

    return (
        <AppointmentsStyle>
            <H3>Записи</H3>
            <Week firstDayOfWeek={firstDayOfWeek} setFirstDayOfWeek={setFirstDayOfWeek} />
            <Card update={update} />
            <Days>
                {
                    days.map((day, i) =>
                        <DayTimetableStyle key={i}>
                            <H5>{toLocaleDateStringByMoscow(day.date).slice(0, 5)}, {weekDays[(day.date.getDay() + 6) % 7]}</H5>
                            <table>
                                <tbody>
                                {
                                    day.slots.length ?
                                            day.slots.map((slot, i) =>
                                                (slot.isPaid() ?
                                                    <CellAppointmentStyle key={i} color={slot.consultation.color}
                                                                   onClick={() => chooseSlot(slot)}>
                                                        <td>{timeToString(slot.timestamp)}</td>
                                                    </CellAppointmentStyle>
                                                    :
                                                    <CellBookedStyle key={i} color={slot.consultation.color}
                                                                     onClick={() => chooseSlot(slot)}>
                                                        <td>{timeToString(slot.timestamp)}</td>
                                                    </CellBookedStyle>))
                                        :
                                        <NoSlots><td>Нет записей</td></NoSlots>
                                }
                                </tbody>
                            </table>
                        </DayTimetableStyle>
                    )
                }
            </Days>
        </AppointmentsStyle>
    );
};

export default Appointments;
