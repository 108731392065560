import post from "../post";
import store from "../../redux/store";
import {work_place} from "../../utils/types";

export const getWorkPlaces = () =>
    new Promise((resolve: (value: work_place[]) => void, reject: (error: any) => void) =>
        post('getWorkPlaces', {token: store.getState().token})
            .then(result => resolve(result.data.workPlaces))
            .catch(error => reject(error))
    );
