import React from 'react';
import Theme from "../../../utils/theme";
import styled from "styled-components";

interface CheckboxProps {
    value: boolean;
    onChange?: (value: boolean) => void
}

const CheckboxStyle = styled.div`
  cursor: pointer;
  display: grid;
  align-content: center;
`;

export const Checkbox = (props: CheckboxProps) => {
    return (
        <CheckboxStyle onClick={() => props.onChange && props.onChange(!props.value)}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 3.5C0.5 2.11929 1.61929 1 3 1H12C13.3807 1 14.5 2.11929 14.5 3.5V12.5C14.5 13.8807 13.3807 15 12 15H3C1.61929 15 0.5 13.8807 0.5 12.5V3.5Z" fill={props.value ? Theme.color.b1 : 'white'} stroke={Theme.color.bl1}/>
                <path d="M10.601 4.72317C10.7565 4.57845 10.9635 4.49844 11.1782 4.50002C11.393 4.50161 11.5987 4.58467 11.7519 4.73169C11.9052 4.8787 11.994 5.07816 11.9997 5.28798C12.0054 5.4978 11.9274 5.70157 11.7823 5.8563L7.37721 11.2413C7.30146 11.3211 7.21004 11.3851 7.10841 11.4295C7.00678 11.4739 6.89703 11.4978 6.78572 11.4999C6.67441 11.5019 6.56383 11.4819 6.46059 11.4412C6.35734 11.4005 6.26356 11.3399 6.18484 11.2629L3.26354 8.40744C3.18219 8.33334 3.11694 8.24398 3.07168 8.1447C3.02643 8.04542 3.00209 7.93824 3.00013 7.82957C2.99817 7.72089 3.01862 7.61295 3.06027 7.51217C3.10191 7.41138 3.1639 7.31984 3.24253 7.24298C3.32115 7.16612 3.41481 7.10553 3.51792 7.06483C3.62102 7.02412 3.73146 7.00413 3.84264 7.00604C3.95382 7.00796 4.06347 7.03175 4.16504 7.07599C4.26661 7.12022 4.35802 7.184 4.43383 7.26352L6.7457 9.52221L10.58 4.74692C10.5869 4.73861 10.5932 4.73068 10.601 4.72317Z" fill="white"/>
            </svg>
        </CheckboxStyle>
    );
};

const CheckboxBlockStyle = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 12px;
  justify-content: start;
`;

const Children = styled.div`
  color: ${props => props.theme.color.bl1};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  
  & a {
    color: ${props => props.theme.color.b1};
    font-weight: 600;
  }
`;

export const CheckboxBlock = (props: {checkbox: CheckboxProps; children?: any}) => {
    return (
        <CheckboxBlockStyle>
            {
                props.checkbox.onChange && <Checkbox {...props.checkbox} />
            }
            <Children>{props.children}</Children>
        </CheckboxBlockStyle>
    )
}
