import React, {useEffect, useState} from 'react';
import {adminReview} from "../../../utils/types";
import {H5, P1} from "../../shared/fonts/fonts";
import {URLs} from "../../../utils/constants";
import post from "../../../api/post";
import {useSelector} from "react-redux";
import {storeType} from "../../../redux/store";
import styled from "styled-components";
import {InputTextArea} from "../../shared/form/input";
import {BlocksStyle, BlockStyle, Button, ButtonVisible, Header} from "./shared";

const Text = styled.div`
  display: grid;
  grid-gap: 10px;
`;

const NewReview = styled.div`
  animation: new 1s infinite;
  animation-timing-function: step-start;

  background-color: ${props => props.theme.color.dpu2};
  color: white;
  
  @keyframes new {
    0% {
      background-color: ${props => props.theme.color.dpu2};
      & h5 {
        color: white;
      }
    }
    50% {
      background-color: white;
      & h5 {
        color: ${props => props.theme.color.dpu2};
      }
    }
  }
`;

const AnswerStyle = styled(BlockStyle)`
  margin-top: -16px;
`;
const Review = (props: {data: adminReview, updateReviewsList: () => void}) => {
    const [showed, setShow] = useState(false);
    const [answering, setAnswering] = useState(false);
    const token = useSelector<storeType, string|null>((state) => state.token);
    const [answerText, setAnswerText_] = useState(props.data.answer);
    const setAnswerText = (e: any) => setAnswerText_(e.target.value);

    const setVisibility = () =>
        post('setReviewVisible', {token, review_id: props.data.id, visibility: 1 - props.data.visible})
            .then(props.updateReviewsList)
            .catch();

    const saveAnswer = () =>
        post('writeAnswer', {token, review_id: props.data.id, text: answerText})
            .then(() => {
                setAnswering(false);
                props.updateReviewsList();
            })
            .catch();

    const cancel = () => {
        setAnswerText_(props.data.answer);
        setAnswering(false);
    }

    return (
        <>
            <BlockStyle>
                <Header className={props.data.visible === 1 ? 'visible' : ''}>
                    {
                        props.data.seen === 0 && <NewReview><H5>Новый отзыв!</H5></NewReview>
                    }
                    <ButtonVisible onClick={setVisibility}>
                        <H5>{props.data.visible === 0 ? 'Сделать видимым' : 'Сделать невидимым'}</H5>
                    </ButtonVisible>
                    <Button onClick={() => setAnswering(true)} className={answering ? 'disabled' : ''}>
                        <H5>{props.data.answer ? 'Редактировать ответ' : 'Ответить'}</H5>
                    </Button>
                    {
                        showed ?
                            <Button onClick={() => setShow(false)}><H5>Свернуть</H5></Button>
                            :
                            <Button onClick={() => setShow(true)}><H5>Развернуть</H5></Button>
                    }
                </Header>
                <div>
                    <H5>Автор: </H5>
                    {
                        props.data.user ?
                            <a href={props.data.user.linkToView()} target={'_blank'}>
                                {props.data.user.firstname + (props.data.user.fatherName ? ' ' + props.data.user.fatherName : '') + ' ' + props.data.user.lastname + '.'}
                            </a>
                            :
                            <H5>Пользователь сайта <a href={'https://prodoctorov.ru/'}>prodoctorov.ru</a></H5>
                    }
                </div>
                {
                    showed &&
                        <Text>
                            {
                                props.data.text_like.length > 0 && <P1><b>Понравилось</b><br/>{props.data.text_like}</P1>
                            }
                            {
                                props.data.text_unlike.length > 0 && <P1><b>Не понравилось</b><br/>{props.data.text_unlike}</P1>
                            }
                            {
                                props.data.text_comment.length > 0 && <P1><b>Комментарий</b><br/>{props.data.text_comment}</P1>
                            }
                        </Text>
                }
            </BlockStyle>
            {answering &&
                <AnswerStyle>
                    <Header>
                        <Button onClick={cancel}>
                            <H5>Отмена</H5>
                        </Button>
                        <Button onClick={saveAnswer} className={props.data.answer === answerText ? 'disabled' : ''}>
                            <H5>Сохранить</H5>
                        </Button>
                    </Header>
                    <InputTextArea input={{value: answerText, onInput: setAnswerText, style: {borderWidth: '2px', borderStyle: 'dashed'},
                                       placeholder: 'Введите ответ'}} />
                </AnswerStyle>}
        </>
    )
}

const Reviews = () => {
    const token = useSelector<storeType, string|null>((state) => state.token);
    const [reviews, setReviews] = useState<adminReview[]>([]);

    const updateReviewsList = () =>
        post('getReviewsList', {token})
            .then(result => setReviews(result.data.reviews))
            .catch();

    useEffect(() => {
        updateReviewsList();
        post('markReviewsAsSeen', {token}).then().catch();
    }, []);

    return (
        <BlocksStyle>
            {reviews.map((r, i) => <Review updateReviewsList={updateReviewsList} key={i} data={r} />)}
        </BlocksStyle>
    );
};

export default Reviews;