import styled from "styled-components";
import {Images} from "../../../utils/images";

export const DayTimetableStyle = styled.div`
  background: ${props => props.theme.color.dpu5};
  //border: 1px solid ${props => props.theme.color.bl1};
  //border-radius: 10px;
  display: grid;
  grid-gap: 8px;
  align-content: start;
  
  & select {
    width: auto;
  }
  
  & > h5  {
    color: white;
    background: ${props => props.theme.color.b1};
    text-align: center;
  }
`;

export const CellStyle = styled.tr<{color?: string}>`
  background: ${props => props.theme.color.bl4};
  text-align: center;
  height: 28px;
  
  &, & td {
    border: 2px dashed ${props => props.theme.color.bl1};
  }
    
    &.inActual {
        cursor: not-allowed;
        opacity: 0.5;
    }
`;

export const CellEmptyStyle = styled(CellStyle)`
  cursor: pointer;
  opacity: 0.8;
  
  &:hover {
    //background: ${props => props.theme.color.bl3};
    opacity: 1;
  }
`;

export const CellSlotBusyStyle = styled(CellStyle)`
  cursor: not-allowed;
  /*&, & td {
    border-color: ${props => props.theme.color.bl3};
  }*/
  opacity: 0.5;
`;

export const CellSlotAddedStyle = styled(CellEmptyStyle)`
  background: ${props => props.color ? '#' + props.color : props.theme.color.g1};
`;

export const CellSlotStyle = styled(CellSlotAddedStyle)`
  & td {
    border-style: solid;
  }
`;

export const CellAppointmentStyle = styled(CellSlotStyle)`
  position: relative;
  
  & td:after {
    /*content: "";
    height: 16px;
    width: 16px;
    background-image: url("${Images.icons.lock}");
    background-size: cover;
    position: absolute;
    margin: 5px;*/

    content: "оплачено";
    font-size: 9px;
    //position: absolute;
    margin-left: 4px;
  }
`;

export const CellBookedStyle = styled(CellAppointmentStyle)`
  @keyframes booked {
    0% {opacity: 1}
    100% {opacity: 0}
  }
  
  & td:after {
    //animation: booked 0.5s infinite ease-in-out;
    content: "забронировано";
  }
`;
