import React from 'react';
import {H3, H5} from "../shared/fonts/fonts";
import {Images} from "../../utils/images";
import {Strings} from "../../utils/constants";
import styled from "styled-components";

const ContactsStyle = styled.div`
  margin: 56px 140px 120px 140px;

  .mobile & {
    margin: 56px 10px 120px 10px;
  }
`;

const ContactsContainer = styled.div`
  margin-top: 90px;
  display: grid;
  grid-gap: 38px;
  padding: 56px 68px;
  width: min-content;

  border-radius: 16px;
  background: white;
  box-shadow: 48px 24px 48px 0 rgba(24, 37, 56, 0.12);
  
  .mobile & {
    padding: 16px;
    width: calc(100% - 32px);
  }
`;

const Contact = styled(H5)`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-gap: 12px;
  color: ${props => props.theme.color.b1};
`;

const Contacts = () => {
    return (
        <ContactsStyle>
            <H3>Контакты</H3>
            <ContactsContainer>
                <Contact><img src={Images.icons.mailB} />{Strings.email}</Contact>
                <Contact><img src={Images.icons.phoneB} />{Strings.phone}</Contact>
                <Contact><img src={Images.icons.geolocationBS} />{Strings.address}</Contact>
                {/*<Contact><img src={Images.icons.instagramB} />{Strings.instagram}</Contact>*/}
                <Contact><img src={Images.icons.inn} /><span style={{opacity: 0.7}}>ИНН:</span>{Strings.inn}</Contact>
            </ContactsContainer>
        </ContactsStyle>
    );
};

export default Contacts;