import React from 'react';
import {URLs} from "../../../utils/constants";
import {H5} from "../fonts/fonts";
import {ButtonGray} from "./buttons";
import {useSelector} from "react-redux";
import {storeType} from "../../../redux/store";
import User from "../../../entities/User";

const ButtonAuth = () => {
    const token = useSelector<storeType, string|null>((state) => state.token);

    if (token) {
        return (<ButtonGray href={User.linkToView()}><H5>Личный кабинет</H5></ButtonGray>)
    }
    else {
        return (<ButtonGray href={URLs.auth}><H5>Войти</H5></ButtonGray>);
    }
};

export default ButtonAuth;