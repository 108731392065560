import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans';
    font-style: normal;
    overflow-x: hidden;
  }
  
  h1,h2,h3,h4,h5,h6 {
    white-space: nowrap;
    font-family: 'Open Sans';
    color: ${props => props.theme.color.bl1};
  }
  
  p {
    font-family: 'Open Sans';
    color: ${props => props.theme.color.bl1};
  }
  
  .App > div {
    display: grid;
    min-height: 100vh;
    align-content: start;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 100%;
  }

  .ck-powered-by {
    display: none;
  }
`;

export default GlobalStyle;
