import React from 'react';
import Template from "../components/template/template";
import Success from "../components/appointment/Success";

const AppointmentResult = () => {
    return (
        <Template>
            <Success />
        </Template>
    );
};

export default AppointmentResult;