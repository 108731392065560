import React, {useEffect, useState} from 'react';
import {H3, H5} from "../../shared/fonts/fonts";
import {ButtonPU, ButtonText, ButtonTextAngry} from "../../shared/buttons/buttons";
import {communications} from "../../../utils/constants";
import {InputText, InputTextArea} from "../../shared/form/input";
import styled from "styled-components";
import {CheckboxBlock} from "../../shared/form/checkbox";
import post from "../../../api/post";
import store, {storeType} from "../../../redux/store";
import {useSelector} from "react-redux";
import {getConsultations} from "../../../api/methods/getConsultations";
import {
    ElementAddStyle, ElementEditButtonsCenter,
    ElementHeaderActions, ElementHeaderButtonsOrder, ElementHeaderDeleteContainer,
    ElementHeaderStyle,
    ElementsContainer,
    ElementStyle
} from "../shared/list";
import ConsultationClass, {ConsultationData} from "../../../entities/Consultation";

const CheckboxList = styled.div`
  display: grid;
  justify-items: start;
  
  & input {
    width: min-content;
  }
  
  & > div {
    cursor: pointer;
  }
`;

const EditConsultation = (props: { data?: ConsultationClass; order_max: number; close: () => void; choose: (id: number) => void }) => {
    const [title, setTitle_] = useState<string>(props.data ? props.data.title : '');
    const [description, setDescription_] = useState<string>(props.data ? props.data.description : '');
    const [duration_number, setDuration_] = useState<number|null>(props.data ? props.data.duration : null);
    const duration = duration_number || '';
    const [price_number, setPrice_] = useState<number|null>(props.data ? props.data.price : null);
    const price = (price_number === null || price_number === undefined) ? '' : price_number;
    const [color, setColor_] = useState<string>(props.data ? props.data.color.toString() : '');
    const [comms, setCommunications_] = useState<{title: string, link: string, value: boolean}[]>(communications.map(comm => ({title: comm.title, link: comm.link, value: props.data ? props.data.communications.includes(comm.link) : false})));
    const [needSave, setNeedSave] = useState(!props.data);
    const [empty, setEmpty] = useState(!props.data);

    const setTitle = (e: any) => setTitle_(e.target.value);
    const setDescription = (e: any) => setDescription_(e.target.value);
    const setDuration = (e: any) => setDuration_(parseInt(e.target.value.replace(/[^0-9]/, '')));
    const setPrice = (e: any) => setPrice_(parseInt(e.target.value.replace(/[^0-9]/, '')));
    const setColor = (e: any) => setColor_(e.target.value.replace(/[^0-9A-Fa-f]/, ''));
    const setCommunications = (link: string) => setCommunications_(comms.map(comm => (comm.link === link ? {...comm, value: !comm.value} : comm)));

    useEffect(() => {
        if (props.data) {
            if (title === props.data.title &&
                description === props.data.description &&
                duration_number === props.data.duration &&
                price_number === props.data.price &&
                color === props.data.color.toString() &&
                comms.every(comm => (comm.value === props.data?.communications.includes(comm.link)))
            ) {
                setNeedSave(false);
            }
            else {
                setNeedSave(true);
            }
        }
        else {
            setNeedSave(true);
        }

        if (title === '' ||
            duration === '' ||
            price === '') {
            setEmpty(true);
        }
        else {
            setEmpty(false);
        }
    }, [title, description, duration_number, price_number, comms, color, props.data]);

    const save = () => {
        const comms_:string[] = [];
        comms.map(comm => comm.value && comms_.push(comm.link));
        post('editConsultation', {
            token: store.getState().token,
            id: props.data ? props.data.id : null,
            title: title, description: description, duration: duration_number, price: price_number, color: color,
            communications: comms_
        })
            .then(() => {
                getConsultations();
                if (!props.data) {
                    props.close();
                }
            })
            .catch(error => console.log(error));
    }

    return (
        <ElementStyle>
            <Header {...props} editMode={true}>{title}</Header>
            <InputText header={'Название:'} input={{value: title, onInput: setTitle}} />
            <InputTextArea header={'Описание:'} input={{value: description, onInput: setDescription}} />
            <InputText header={'Длительность:'} textAfter={'мин.'} input={{value: duration, onInput: setDuration}} />
            <InputText header={'Стоимость:'} textAfter={'руб.'} input={{value: price, onInput: setPrice}} />
            <InputText header={'Цвет:'} input={{type: 'color', value: '#' + color, onChange: setColor}} />
            <H5>Средства связи</H5>
            <CheckboxList>
                {
                    comms.map((comm, i) =>
                        <CheckboxBlock key={i} checkbox={{value: comm.value,
                            onChange: () => setCommunications(comm.link)}}>
                            {comm.title}
                        </CheckboxBlock>)
                }
            </CheckboxList>
            <ElementEditButtonsCenter>
                <ButtonPU className={(needSave && !empty) ? '' : 'disabled'} onClick={save}><H5>Сохранить</H5></ButtonPU>
            </ElementEditButtonsCenter>
        </ElementStyle>
    )
}

const Header = (props: {children: any, editMode: boolean;
    data?: ConsultationClass;
    close: () => void;
    choose: (id: number) => void;
    order_max: number}) => {
    const [deleteApply, setDeleteApply] = useState(false);

    const setOrder = (vector: number) => {
        if (props.data) {
            const order_id = props.data.order_id + vector;
            if (order_id >= 1 || order_id <= props.order_max) {
                post('setConsultationOrder', {
                    id: props.data.id,
                    token: store.getState().token,
                    order_id: order_id
                })
                    .then(() => getConsultations())
                    .catch(error => console.log(error));
            }
        }
    }

    const deleteConsultation = () => {
        if (props.data) {
            post('deleteConsultation', {
                id: props.data.id,
                token: store.getState().token
            })
                .then(() => {
                    getConsultations();
                    props.close();
                    setDeleteApply(false);
                })
                .catch(error => console.log(error));
        }
    }

    return (
        <ElementHeaderStyle>
            <H5>{props.children}</H5>
            <ElementHeaderActions>
                {
                    (!props.editMode && props.data) &&
                    <ElementHeaderButtonsOrder>
                        {
                            props.data.order_id > 1
                                ? <div onClick={() => setOrder(-1)} className={'active'}>▲</div>
                                : <div>△</div>
                        }
                        {
                            props.data.order_id < props.order_max
                                ? <div onClick={() => setOrder(1)} className={'active'}>▼</div>
                                : <div>▽</div>
                        }
                    </ElementHeaderButtonsOrder>
                }
                {
                    props.editMode && <ButtonText onClick={() => props.close()}><H5>Отмена</H5></ButtonText>
                }
                {
                    (!props.editMode && props.data) && <ButtonText onClick={() => props.choose(props.data ? props.data.id : -1)}><H5>Изменить</H5></ButtonText>
                }
                {
                    props.data && (deleteApply ?
                        <ElementHeaderDeleteContainer><H5>Вы уверены?</H5><ButtonTextAngry onClick={deleteConsultation}><H5>Удалить</H5></ButtonTextAngry><ButtonText onClick={() => setDeleteApply(false)}><H5>Отмена</H5></ButtonText></ElementHeaderDeleteContainer>
                        :
                        <ButtonTextAngry onClick={() => setDeleteApply(true)}><H5>Удалить</H5></ButtonTextAngry>)
                }
            </ElementHeaderActions>
        </ElementHeaderStyle>
    );
}

const Consultation = (props: {data: ConsultationClass;
    close: () => void;
    choose: (id: number) => void;
    order_max: number}) => {
    return (
        <ElementStyle>
            <Header {...props} editMode={false}>{props.data.title}</Header>
        </ElementStyle>
    )
}

const ConsultationAdd = (props: {add: () => void}) => {
    return (
        <ElementAddStyle onClick={props.add}>
            <H5>Добавить новый вид обращения</H5>
        </ElementAddStyle>
    )
}

const ConsultationsStyle = styled.div`
  display: grid;
  grid-gap: 10px;
`;

const Consultations = () => {
    const consultationsData = useSelector<storeType, ConsultationData[]>((state) => state.consultations);
    const consultations = consultationsData.map(c => new ConsultationClass(c));
    const [editCon, setEditCon] = useState<number>(-1);

    const add = () => {
        setEditCon(0);
    }

    const choose = (id: number) => {
        setEditCon(id);
    }

    const close = () => {
        setEditCon(-1);
    }

    useEffect(getConsultations, []);

    return (
        <ConsultationsStyle>
            <H3>Виды обращений</H3>
            <ElementsContainer>
                {
                    editCon === 0 ? <EditConsultation order_max={consultations.length}
                                                     close={close} choose={choose} /> : <ConsultationAdd add={add} />
                }
                {
                    consultations.map((con, i) =>
                        con.id === editCon ?
                            <EditConsultation order_max={consultations.length} key={i}
                                              data={con} close={close} choose={choose} />
                            :
                            <Consultation order_max={consultations.length} key={i}
                                          data={con} close={close} choose={choose} />
                    )
                }
            </ElementsContainer>
        </ConsultationsStyle>
    );
};

export default Consultations;
