import React from 'react';
import styled from "styled-components";

const AboutLeftTopStyle = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  
  .mobile & {
    top: -120px;
  }
`;

const AboutLeftTop = () => {
    return (
        <AboutLeftTopStyle width="588" height="493" viewBox="0 0 588 493" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <path fillRule="evenodd" clipRule="evenodd" d="M150.642 -250C364.693 -250 588 -133.247 588 81.6057C588 316.559 384.718 493 150.642 493C-49.256 493 -156 282.254 -156 81.6057C-156 -95.0815 -25.3849 -250 150.642 -250Z" fill="#ACA1EC"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M150.642 -250C364.693 -250 588 -133.247 588 81.6057C588 316.559 384.718 493 150.642 493C-49.256 493 -156 282.254 -156 81.6057C-156 -95.0815 -25.3849 -250 150.642 -250Z" fill="url(#pattern_about_left_top)" fillOpacity="0.5"/>
            <defs>
                <pattern id="pattern_about_left_top" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref={"#image_gradient2"} transform="translate(0 -0.000672948) scale(0.000390625)"/>
                </pattern>
            </defs>
        </AboutLeftTopStyle>
    );
};

export default AboutLeftTop;
