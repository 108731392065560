import store from "../redux/store";
import {URLs} from "../utils/constants";

export interface SlotConsultationData {
    id: number
}
export interface ConsultationData extends SlotConsultationData {
    title: string,
    description: string,
    duration: number,
    price: number,
    color: string,
    communications: string[],
    order_id: number
}

class Consultation {
    id: number;
    title: string;
    description: string;
    duration: number;
    price: number;
    color: string;
    communications: string[] = [];
    order_id: number;

    constructor(data: ConsultationData) {
        this.id = data.id;
        this.title = data.title;
        this.description = data.description;
        this.duration = data.duration;
        this.price = data.price;
        this.color = data.color;
        this.communications = data.communications;
        this.order_id = data.order_id;
    }

    static get = (id?: number) =>
        new Consultation(store.getState().consultations.find(c => c.id === id) || EMPTY_CONSULTATION);

    static linkToView = (consultation_id?: number) =>
        URLs.appointment.replace(':id?', consultation_id ? String(consultation_id) : '');
    linkToView = () => Consultation.linkToView(this.id);
}

export default Consultation;

export const EMPTY_CONSULTATION:ConsultationData = {
    id: 0,
    title: 'Нет данных',
    description: '',
    price: 0,
    color: 'fff0',
    duration: 0,
    communications: [],
    order_id: 0
};