import React from 'react';
import styled from "styled-components";
import Day from "./Day";
import {day, day_type} from "../../../utils/types";
import {storeType} from "../../../redux/store";
import {useSelector} from "react-redux";
import {H5} from "../fonts/fonts";
import {ByMoscowTime} from "../../appointment/Main";
import Week from "../../admin/shared/Week";
import Slot, {SlotData} from "../../../entities/Slot";

const DaysWithHeaderStyle = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-gap: 8px;
`;

const DaysStyle = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(64px, 1fr);
  overflow-x: auto;
`;

const Days = (props: {currentDate: Date; setCurrentDate: (date: Date) => void; currentWeekDay: number; weekStart: Date;
    isDynamic: boolean }) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const slotsData = useSelector<storeType, SlotData[]>((state) => state.slots);
    const slots = slotsData.map(s => new Slot(s));
    const slotsLoading = useSelector<storeType, boolean>((state) => state.slotsLoading);
    const days:day[] = [];

    for (let i = 0; i < 7; i++) {
        const dayDate = new Date(props.currentDate);
        dayDate.setDate(dayDate.getDate() + i - props.currentWeekDay);
        if (slotsLoading) {
            days.push({
                typ: 'loading',
                date: dayDate,
                weekDay: i,
                slots: []
            });
            continue;
        }

        let tp:day_type = 'default';
        if (i > 4) {
            tp = 'weekend';
        }
        if (dayDate < today) {
            tp = 'past';
        }
        else if (dayDate.getTime() === today.getTime()) {
            tp = 'current';
        }
        days.push({
            typ: tp,
            date: dayDate,
            weekDay: i,
            slots: []
        })
    }

    let cur = 0;
    slots.map(slot => {
        while (slot.timestamp >= props.weekStart.getTime() / 1000 + (cur + 1) * 3600 * 24) {
            cur += 1;
        }
        if (cur < 7) {
            days[cur].slots.push(slot);
        }
    });

    const setFirstDayOfWeek = (date: Date) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + props.currentWeekDay);
        props.setCurrentDate(newDate);
    }

    return (
        <DaysWithHeaderStyle className={'weeksPU'}>
            <Week firstDayOfWeek={props.weekStart} setFirstDayOfWeek={setFirstDayOfWeek} />
            <H5>Время приема · <ByMoscowTime>по <span>московскому</span> времени</ByMoscowTime></H5>
            <DaysStyle>
                {
                    days.map((day, i) => <Day day={day} key={i} isDynamic={props.isDynamic} />)
                }
            </DaysStyle>
        </DaysWithHeaderStyle>
    );
};

export default Days;