import React, {useEffect, useState} from 'react';

const getDevice = (width_: number) => {
    //console.log(width_);
    if (width_ >= 1250) {
        return 'desktop';
    }
    if (width_ >= 1000) {
        return 'tablet';
    }
    return 'mobile';
}

interface ViewportContextType {viewport: {height: number; width: number}; device: 'mobile'|'tablet'|'desktop'};
export const ViewportContext = React.createContext<ViewportContextType>({
    viewport: {
        height: document.body.clientHeight,
        width: document.body.clientWidth
    },
    device: getDevice(document.body.clientWidth)
});

export const useViewport = () => React.useContext(ViewportContext);

export const ViewportProvider = (props: {children?: any}) => {
    const [width, setWidth] = useState(document.body.clientWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [scroll, setScroll] = useState(document.body.scrollTop);
    const [device, setDevice] = useState<'mobile'|'tablet'|'desktop'>(getDevice(width));

    useEffect(() => {
        window.addEventListener('load', function(event) {
            setWidth(document.body.clientWidth);
            setHeight(window.innerHeight);
            setScroll(window.scrollY);
        });

        window.addEventListener('resize', function(event) {
            setWidth(document.body.clientWidth);
            setHeight(window.innerHeight);
        });

        window.addEventListener('scroll', function() {
            setScroll(window.scrollY);
        });
    }, []);

    useEffect(() => setDevice(getDevice(width)), [width, height]);

    return (
        <ViewportContext.Provider value={{viewport: {height, width}, device}}>
            {props.children}
        </ViewportContext.Provider>
    );
}