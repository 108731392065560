import React, {useEffect, useState} from 'react';
import {H3, H4, H5} from "../shared/fonts/fonts";
import {Images} from "../../utils/images";
import styled from "styled-components";
import {work_place} from "../../utils/types";
import {getWorkPlaces} from "../../api/methods/getWorkPlaces";

const PlaceStyle = styled.a`
  display: grid;
  grid-auto-flow: column;
  padding: 0 34px;
  grid-gap: 42px;
  height: 114px;
  align-items: center;
  justify-content: start;

  border-radius: 16px;
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  
  &, & * {
    text-decoration: none;
  }
  
  .mobile & {
    & h4, & h5 {
      white-space: normal;
    }
    
    height: auto;
    padding: 10px 34px;
  }
`;

const Place = (props: work_place) => {
    return (
        <PlaceStyle href={props.link} target={'_blank'}>
            <img src={Images.icons.geolocationB} />
            <div>
                <H4>{props.title}</H4>
                <H5>{props.address}</H5>
            </div>
        </PlaceStyle>
    )
}

const WorkPlacesStyle = styled.div`
  margin: 92px 140px;
  position: relative;
  
  .mobile & {
    margin: 92px 10px;
  }
`;

const PlacesContainer = styled.div`
  margin-top: 90px;
  display: grid;
  grid-gap: 20px;
`;

const Cloud1 = styled.div`
  position: absolute;
  left: -105px;
  top: 378px;

  width: 47px;
  height: 47px;
  border-radius: 50%;
  background: ${props => props.theme.color.pu3};
  z-index: -1;
`;

const Cloud2 = styled.img`
  position: absolute;
  right: 0;
  top: -8px;
  z-index: -1;
`;

const WorkPlaces = () => {
    const [workPlaces, setWorkPlaces] = useState<work_place[]>([]);

    useEffect(() => {
        getWorkPlaces()
            .then(list => setWorkPlaces(list))
            .catch(error => console.log(error));
    }, []);

    if (workPlaces.length) {
        return (
            <WorkPlacesStyle>
                <Cloud1/>
                <Cloud2 src={Images.clouds.about.workPlacesCloud}/>
                <H3>Места работы</H3>
                <PlacesContainer>
                    {
                        workPlaces.map((wp, i) =>
                            <Place key={i} {...wp} />)
                    }
                </PlacesContainer>
            </WorkPlacesStyle>
        );
    }
    else {
        return <div />
    }
};

export default WorkPlaces;