import User, {UserData} from "./User";
import Slot, {SlotData} from "./Slot";

export interface MessageData {
    id: number;
    user: UserData;
    admin: boolean;
    text: string;
    timestamp: number;
    slot: SlotData|null;
}

class Message {
    id: number;
    user: User;
    admin: boolean;
    seen: MESSAGES_SEEN_STATUSES;
    text: string;
    timestamp: number;
    slot: Slot|null;

    constructor(data: MessageData, last_seen_message_id: number) {
        this.id = data.id;
        this.user = new User(data.user);
        this.admin = data.admin;
        this.seen = (last_seen_message_id === data.id)
            ? 2
            : (last_seen_message_id > data.id)
                ? 1
                : 0;
        this.text = data.text;
        this.timestamp = data.timestamp;
        this.slot = data.slot ? new Slot(data.slot) : null;
    }
}

const SEEN = 0;
const UNSEEN = 1;
const LAST_SEEN = 2;
type MESSAGES_SEEN_STATUSES = typeof SEEN | typeof UNSEEN | typeof LAST_SEEN;

export default Message;