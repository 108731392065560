import React from 'react';
import Form from "../shared/form/form";
import {Images} from "../../utils/images";
import {H2, H5} from "../shared/fonts/fonts";
import {ButtonPU} from "../shared/buttons/buttons";
import {pageProps} from "../../pages/auth";
import styled from "styled-components";

const FormStyle = styled(Form)`
  max-width: calc(100% - 700px);
  text-align: center;
  width: auto;
  
  & h2 {
    white-space: normal;
  }
  
  .mobile & {
    max-width: none;
  }
`;

const Success = (props: pageProps) => {
    return (
        <FormStyle>
            <img src={Images.icons.success}/>
            <H2>{props.text}</H2>
            <ButtonPU onClick={() => props.setPage('login', {})}><H5>Вернуться на главную</H5></ButtonPU>
        </FormStyle>
    );
};

export default Success;