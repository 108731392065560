import store from "../../redux/store";
import {setConsultations, setConsultationsLoading} from "../../redux/actions";
import get from "../get";

export const getConsultations = () => {
    setConsultationsLoading();
    get('consultations', {})
        .then(result =>
            store.dispatch(
                setConsultations(result.data.consultations)))
        .catch(error => console.log(error));
}