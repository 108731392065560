import {
    action,
    AddChatsType,
    AuthDataType,
    ChooseChatType,
    ChooseSlotType,
    ClearMessagesType,
    ClearRegDataType, ConsultationsLoadingType, ConsultationsType, CopySlotsType,
    LogoutType, MessagesLoadingType, MeType,
    PushMessagesType,
    RegDataType,
    SetLastSeenMessageIdType,
    SetMenuType,
    ShiftMessagesType, SlotsLoadingType,
    SlotsType,
    UpdateChatType
} from "./actions";
import {initState, storeType} from "./store";

export const reducer = (state:storeType = initState, action: action) => {
    switch (action.type) {
        case AuthDataType: {
            window.localStorage.setItem('token', action.payload.token);
            return {...state, token: action.payload.token};
        }
        case MeType: {
            window.localStorage.setItem('role', String(action.payload.role));
            return {...state, me: action.payload};
        }
        case LogoutType: {
            window.localStorage.removeItem('role')
            window.localStorage.removeItem('token');
            return {...state, token: null};
        }
        case RegDataType: {
            return {...state, regData: {...state.regData, ...action.payload}}
        }
        case ClearRegDataType: {
            return {...state, regData: {}}
        }
        case SlotsType: {
            return {...state, slots: [...action.payload], slotsLoading: false}
        }
        case SlotsLoadingType: {
            return {...state, slotsLoading: true}
        }
        case ChooseSlotType: {
            return {...state, slot: action.payload}
        }
        case CopySlotsType: {
            return {...state, copiedSlots: action.payload}
        }

        case ChooseChatType: {
            return {...state, currentChat: action.payload}
        }
        case AddChatsType: {
            return {...state, chats: [...state.chats, ...action.payload]}
        }
        case UpdateChatType: {
            const x = state.chats.findIndex(chat => chat.id === action.payload.id);
            if (x === -1) {
                return state
            }
            else {
                return {...state, chats: [...state.chats.slice(0, x), action.payload, ...state.chats.slice(x + 1)]}
            }
        }
        case PushMessagesType: {
            return {...state, messages: [...state.messages, ...action.payload]}
        }
        case ShiftMessagesType: {
            return {...state, messages: [...action.payload, ...state.messages]}
        }
        case ClearMessagesType: {
            return {...state, messages: []}
        }
        case MessagesLoadingType: {
            return {...state, messagesLoading: action.payload}
        }
        case SetLastSeenMessageIdType: {
            return {...state, lastSeenMessageId: action.payload}
        }

        case ConsultationsType: {
            return {...state, consultations: [...action.payload], consultationsLoading: false}
        }
        case ConsultationsLoadingType: {
            return {...state, consultationsLoading: true}
        }

        case SetMenuType: {
            return {...state, mobileMenu: action.payload}
        }

        default:
            return state
    }
}
