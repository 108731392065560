import React from 'react';
import {H2, H4, H5, P1, P1Pointed} from "../shared/fonts/fonts";
import styled from "styled-components";
import AboutLeftTop from "../../images/svgs/about/AboutLeftTop";
import AboutPhoto from "../../images/svgs/about/AboutPhoto";
import {Images} from "../../utils/images";

const TopContainer = styled.div`

  display: grid;
  position: relative;
  
  .mobile & {
    grid-template-columns: 100%;
    overflow-x: hidden;
  }

  .desktop & {
    margin-right: 140px;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
  }
`;

const LeftContainer = styled.div`
  .mobile & {
    justify-self: end;
    margin-right: -60px;
  }
`;

const InfoStyle = styled.div`
  display: grid;
  margin: 125px 0 90px 0;
  grid-template-rows: auto 10px auto 66px auto 20px auto 22px auto 40px auto 20px auto 20px auto 20px auto 20px auto;
  width: min-content;
  justify-self: center;
  
  & h4 {
    text-align: center;
  }
  
  .mobile & {
    width: auto;
    margin: 0 10px 90px 10px;
    & h2 {
    white-space: normal;
  }
`;

const Cloud1 = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  background: ${props => props.theme.color.pu2};
  left: 560px;
  top: 295px;
`;

const Cloud2 = styled.img`
  position: absolute;
  left: 100px;
  top: 590px;
  
  .mobile & {
    display: none;
  }
`;

const Documents = styled.div`
  display: flex;
  grid-gap: 12px;
  margin: 8px;
  border-top: 2px solid ${props => props.theme.color.dpu3};
  border-bottom: 2px solid ${props => props.theme.color.dpu3};
  padding: 8px;
  border-radius: 10px;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const DocumentPhotoStyle = styled.a<{src: string}>`
  display: grid;
  grid-gap: 8px;
  
  & div {
    display: block;
    background: url("${props => props.src}");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 80px;
    width: 100px;
    position: relative;
  }
  
  text-decoration: none;
  flex-basis: min-content;
  
  & h5 {
    font-size: 12px;
    white-space: normal;
    opacity: 0.7;
  }
  
  &:hover h5 {
    opacity: 1;
  }
`;

const DocumentPhoto = (props: {src: string, title: string}) => {
    return (
        <DocumentPhotoStyle src={props.src} target={'_blank'}
                           href={'./assets/sertificates/' + props.title + '.pdf'} >
            <div />
            <H5>{props.title}</H5>
        </DocumentPhotoStyle>
    )
}

const Top = () => {
    return (
        <TopContainer>
            <LeftContainer>
                <Cloud1 />
                <AboutLeftTop />
                <AboutPhoto />
                <Cloud2 src={Images.clouds.about.topCloud} />
            </LeftContainer>
            <InfoStyle>
                <H2>Сиюхова Заира Аслановна</H2>
                <div />
                <H4>Врач-эндокринолог</H4>
                <div />
                <P1>Образование:</P1>
                <div />
                <P1Pointed>• 2015 г. - ординатура по специальности «клиническая эндокринология», ФГБУ Эндокринологический научный центр МЗ РФ;</P1Pointed>
                <div />
                <P1Pointed>• 2013 г. - врач по специальности «лечебное дело», диплом с отличием, Российский национальный исследовательский медицинский университет им. Н.И.Пирогова.</P1Pointed>
                <div />
                <Documents>
                    <DocumentPhoto src={Images.previews.DOVO} title={'Диплом о высшем образовании'} />
                    <DocumentPhoto src={Images.previews.DOOO} title={'Диплом об окончании ординатуры'} />
                    <DocumentPhoto src={Images.previews.SSNE} title={'Сертификат специалиста НМИЦ Эндокринологии'} />
                    <DocumentPhoto src={Images.previews.SSENC} title={'Сертификат специалиста Эндокринологический научный центр'} />
                </Documents>
                <div />
                <P1>Путь как в эндокринологию, так и в медицину в целом, не был классическим – все решения оказались спонтанными. Однако сожалеть о выбранной специальности не пришлось ни дня – эндокринология прекрасна своей возможностью индивидуального подхода в лечении многих патологий в рамках доказательной медицины, сахарный диабет – наиболее яркий тому пример. Ничто не вдохновляет и не восстанавливает больше, чем слова благодарности пациентов – это лучшие награда, источник энергии и мотиватор.</P1>
                <div />
                <P1>Работа составляет огромную часть жизни, а время вне работы стараюсь проводить с семьей.</P1>
                <div />
                <P1>На мой взгляд, современная медицина сейчас – это великие возможности для непрерывного самообразования, необходимость критического мышления и способность принять обоснованные коррективы в прочно заложенные ранее взгляды.</P1>
                <div />
                <P1>Веду очный прием, рада быть частью клиник доказательной медицины городов Краснодар, Ростов-на-Дону, Москва. Доверие коллег – бесценно. К интересному опыту отнесу выступления в рамках информационных проектов в городах Краснодар, Сочи, Грозный, Алматы (Казахстан).</P1>
            </InfoStyle>
        </TopContainer>
    );
};

export default Top;
