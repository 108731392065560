import React from 'react';
import styled from "styled-components";

const FooterRightStyle = styled.svg`
  position: absolute;
  right: 0;
  bottom: 0;
`;

const FooterRight = () => {
    return (
        <FooterRightStyle width="439" height="285" viewBox="0 0 439 285" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.16919 178.371C23.6069 96.7301 90.6817 42.2198 171.879 23.6145C282.253 -1.67601 421.573 -28.4978 475.471 71.0658C531.624 174.794 446.012 289.575 358.917 369.12C284.849 436.767 179.96 469.334 91.7665 421.508C8.35115 376.272 -11.5552 271.368 7.16919 178.371Z" fill="#ACA1EC"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.16919 178.371C23.6069 96.7301 90.6817 42.2198 171.879 23.6145C282.253 -1.67601 421.573 -28.4978 475.471 71.0658C531.624 174.794 446.012 289.575 358.917 369.12C284.849 436.767 179.96 469.334 91.7665 421.508C8.35115 376.272 -11.5552 271.368 7.16919 178.371Z" fill="url(#pattern_footer_right)" fillOpacity="0.5"/>
            <defs>
                <pattern id="pattern_footer_right" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref="#image_gradient2" transform="matrix(0.00039741 0 0 0.000390625 -0.00868487 0)"/>
                </pattern>
            </defs>
        </FooterRightStyle>
    );
};

export default FooterRight;