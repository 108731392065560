import store from "../../../../redux/store";
import {SetLastSeenMessageId, setMessagesLoading, ShiftMessages} from "../../../../redux/actions";
import User from "../../../../entities/User";
import get from "../../../get";

export const getMessagesHistory = (message_id: number|undefined,
                                   count: number|undefined, user: User, controller: AbortController) =>
    new Promise((resolve: (count: number) => void) => {
        store.dispatch(setMessagesLoading(true));
        get('messages',
            {to_id: message_id, count, user_id: user.id},
            {signal: controller.signal})
            .then(result => {
                store.dispatch(ShiftMessages(result.data.messages.reverse()));
                store.dispatch(SetLastSeenMessageId(result.data.last_seen_message_id));
                resolve(result.data.messages.length);
            })
            .catch(error => console.log(error))
            .finally(() => {
                store.dispatch(setMessagesLoading(false));
            });
    });