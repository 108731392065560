import React, {useEffect} from 'react';
import styled from "styled-components";

const AboutPhotoStyle = styled.svg`
  filter: drop-shadow(48px 24px 48px rgba(24, 37, 56, 0.12));

  position: relative;
  margin: 94px 0 0 95px;
  
  .mobile & {
    margin: 0 0 -40px 95px
  }
`;

const AboutPhoto = () => {

    const scroll = () => {

    }

    useEffect(() => {
        window.addEventListener('scroll', scroll);
    }, []);

    return (
        <AboutPhotoStyle width="535" height="542" viewBox="0 0 535 542" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g filter="url(#filter0_d_31_893)">
                <path fillRule="evenodd" clipRule="evenodd" d="M222.14 24.2256C300.159 27.1807 360.67 80.9965 390.074 153.245C430.043 251.453 475.773 376.182 391.724 440.912C304.16 508.35 185.091 446.436 98.408 377.871C24.6902 319.561 -21.1897 227.512 9.83374 138.842C39.1763 54.9772 133.269 20.8594 222.14 24.2256Z" fill="url(#pattern_about_photo)"/>
            </g>
            <defs>
                <filter id="filter0_d_31_893" x="0" y="0" width="535" height="542" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dx="48" dy="24"/>
                    <feGaussianBlur stdDeviation="24"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0941176 0 0 0 0 0.145098 0 0 0 0 0.219608 0 0 0 0.12 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_31_893"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_31_893" result="shape"/>
                </filter>
                <pattern id="pattern_about_photo" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref="#image_photo" transform="matrix(0.000330341 0 0 0.000325156 -0.10915 -0.102466)"/>
                </pattern>
            </defs>
        </AboutPhotoStyle>
    );
};

export default AboutPhoto;
