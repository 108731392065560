import { reducer } from './reducers';
import {configureStore} from "@reduxjs/toolkit";
import {setAuthData} from "./actions";
import {MessageData} from "../entities/Message";
import {UserData} from "../entities/User";
import {ConsultationData} from "../entities/Consultation";
import {SlotData} from "../entities/Slot";
import {reg_data} from "../utils/types";

export interface storeType {
    token: string | null,
    me: UserData | null,
    user: UserData | null,

    slot: SlotData | null,

    regData: reg_data,

    chats: UserData[],
    currentChat: UserData | null,
    messages: MessageData[],
    messagesLoading: boolean,
    lastSeenMessageId: number,

    slots: SlotData[],
    slotsLoading: boolean,
    copiedSlots: SlotData[],

    consultations: ConsultationData[],
    consultationsLoading: boolean,

    mobileMenu: boolean
}

export const initState:storeType = {
    token: null,
    me: null,
    user: null,

    slot: null,

    regData: {},

    chats: [],
    currentChat: null,
    messages: [],
    messagesLoading: false,
    lastSeenMessageId: 0,

    slots: [],
    slotsLoading: false,
    copiedSlots: [],

    consultations: [],
    consultationsLoading: false,

    mobileMenu: false
}

const store = configureStore({
    reducer: reducer,
    preloadedState: initState
});

const start_token = window.localStorage.getItem('token');
start_token && store.dispatch(setAuthData(start_token));

export default store;
