import React from 'react';
import {Images} from "../../utils/images";
import {H4, H9} from "../shared/fonts/fonts";
import styled from "styled-components";

const DocumentStyle = styled.a`
  display: grid;
  box-shadow: 4px 4px 10px 2px rgba(24, 37, 56, 0.12);
  grid-auto-flow: column;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  padding: 4px 30px 4px 20px;
  grid-gap: 20px;
  border-radius: 12px;
  position: relative;
  z-index: 1;
  
  & h4 {
    white-space: normal;
    
    .mobile & {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;
export const Document = (props: {href: string; children: string}) => {
    return (
        <DocumentStyle href={props.href} target={'_blank'}>
            <img src={Images.icons.document} />
            <div>
                <H4>{props.children}</H4>
                <H9>.pdf</H9>
            </div>
        </DocumentStyle>
    );
}

const DocumentsStyle = styled.div`
  display: grid;
  justify-content: center;
  grid-auto-flow: column;
  grid-gap: 20px;
  padding: 0 24px 50px 24px;
  
  .mobile & {
    grid-auto-flow: row;
    justify-content: start;
    justify-items: start;
  }
`;

const Documents = () => {
    return (
        <DocumentsStyle>
            <Document href={'./assets/documents/Оферта.pdf'}>
                Оферта
            </Document>
            <Document href={'./assets/documents/Пользовательское соглашение.pdf'}>
                Пользовательское соглашение
            </Document>
        </DocumentsStyle>
    );
};

export default Documents;