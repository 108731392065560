import axios, {AxiosRequestConfig} from "axios";
import {api_host} from "../utils/constants";
import store from "../redux/store";

export const configDefault:() => AxiosRequestConfig = () => ({
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${store.getState().token}`
    }
});

const Post = (url: string, data: any, cfg?: AxiosRequestConfig) =>
    axios.post(api_host + '/' + url, data, {...configDefault(), ...cfg});

export default Post;
