import React from 'react';
import styled from "styled-components";
import {H3, H4, H5, P2} from "../shared/fonts/fonts";
import {ButtonWhite} from "../shared/buttons/buttons";
import {useSelector} from "react-redux";
import {storeType} from "../../redux/store";
import {setWraps} from "../../utils/functions";
import {useViewport} from "../../utils/ViewportContext";
import Consultation, {ConsultationData} from "../../entities/Consultation";

const ConsultationCardStyle = styled.div`
  padding: 36px 30px;
  background: ${props => props.theme.color.dpu5};
  display: grid;
  grid-gap: 30px;
  border-radius: 8px;
  justify-items: center;
  text-align: center;
  align-content: space-between;
  
  & p {
    text-align: left;
  }
  
  & > * {
    margin: 0;
    white-space: normal;
  }
  
  & > div {
    display: grid;
    grid-gap: 30px;
  }
  
  .mobile & h3 {
    font-size: 20px;
  }
`;

const ConsultationCard = (props: {consultation: Consultation}) => {
    return (
        <ConsultationCardStyle id={'consultations'}>
            <H3>{props.consultation.title}</H3>
            {
                props.consultation.description && <P2>{setWraps(props.consultation.description)}</P2>
            }
            <div>
                <H5>до {props.consultation.duration} мин.</H5>
                <H4>{props.consultation.price ? props.consultation.price + ' р.' : 'Без оплаты'}</H4>
                <ButtonWhite href={props.consultation.linkToView()}>
                    Записаться
                </ButtonWhite>
            </div>
        </ConsultationCardStyle>
    );
};

const ConsultationsStyle = styled.div`
  padding: 85px 24px 50px 24px;
  display: grid;
  grid-gap: 50px;
`;

const ConsultationsRow = styled.div`
  display: grid;
  gap: 16px;
  grid-auto-flow: column;
  //grid-auto-columns: 1fr;
`;

const Consultations = () => {
    const viewport = useViewport();
    const consultationsData = useSelector<storeType, ConsultationData[]>((state) => state.consultations);
    const consultations = consultationsData.map(c => new Consultation(c));

    const list:Consultation[][] = [];
    if (viewport.device === 'mobile') {
        consultations.map(c => list.push([c]));
    }
    else {
        let i = 0;
        while (consultations.length - i > 4) {
            list.push(consultations.slice(i, i + 3));
            i += 3;
        }
        if (consultations.length - i === 4) {
            list.push(consultations.slice(i, i + 2));
            list.push(consultations.slice(i + 2, i + 4));
        } else {
            list.push(consultations.slice(i));
        }
    }

    return (
        <ConsultationsStyle>
            {
                list.map((row, i) =>
                    <ConsultationsRow key={i}>
                        {
                            row.map((card, j) => <ConsultationCard key={j} consultation={card}/>)
                        }
                    </ConsultationsRow>
                )
            }
        </ConsultationsStyle>
    );
};

export default Consultations;
