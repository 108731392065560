import React from 'react';
import Template from "../components/template/template";
import Consultations from "../components/main/Consultations";
import {H1, P2} from "../components/shared/fonts/fonts";
import styled from "styled-components";

const Header = styled.div`
  margin: 80px 0 0 0;
  text-align: center;
  
  & h1 {
    white-space: normal;
  }
`;

const MicroInfo = styled.div`
  padding: 0 24px 50px 24px;
`;

const NoteButton = styled.a`
  vertical-align: middle;
  color: #948d85;
  cursor: pointer;
  text-decoration: none;
`;

const Note = styled.span`
  font-size: 24px;
  vertical-align: middle;
  font-weight: bold;
`;

const ConsultationsPage = () => {
    return (
        <Template>
            <Header>
                <H1>Онлайн информирование <NoteButton href={'#note'}>*</NoteButton></H1>
            </Header>
            <Consultations />
            <MicroInfo id={'note'}>
                <P2><Note>*</Note> Не является консультацией врача, медицинской услугой и фактом оказания медицинской помощи. Онлайн-информирование позволяет провести сбор жалоб, анамнеза, ознакомиться с результатами обследования, предположить возможные диагнозы, сформировать дальнейший маршрут по диагностике, обсудить изменение образа жизни, озвучить возможные варианты лечения согласно клиническим рекомендациям (группы лекарственных препаратов, методы терапии). После завершения онлайн-информирования вы не получаете письменного заключения. Онлайн-информирование не заменяет очную консультацию врача.</P2>
            </MicroInfo>
        </Template>
    );
};

export default ConsultationsPage;
