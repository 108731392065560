import React, {UIEventHandler, useEffect, useState} from 'react';
import styled from "styled-components";
import {H4, H5, P1} from "../shared/fonts/fonts";
import {useDispatch, useSelector} from "react-redux";
import {storeType} from "../../redux/store";
import {AddChats, ChooseChat} from "../../redux/actions";
import {AdminUser} from "../../utils/constants";
import * as ChatsAPI from "../../api/methods/messages/get/getChats";
import {useLocation} from "react-router-dom";
import {ButtonText} from "../shared/buttons/buttons";
import {Images} from "../../utils/images";
import User, {UserData} from "../../entities/User";

const UserPhoto = styled.div<{src: string, count?: number}>`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  
  background: ${props => props.src ? `url("${props.src}"), ` : ''}url("${Images.icons.avatar}");
  background-size: cover;
  background-position: center;
  position: relative;
  
  &::after {
    display: ${props => props.count ? 'grid' : 'none'};
    content: "${props => props.count && '+' + props.count}";
    position: absolute;
    
    right: -4px;
    top: -4px;
    font-size: 10px;
    background: ${props => props.theme.color.pi2};
    color: white;
    padding: 0 2px;
    border-radius: 4px;
  }
`;

const DialogStyle = styled.div`
  display: grid;
  grid-auto-flow: column;
  //justify-content: left;
  grid-template-columns: min-content auto auto;
  grid-gap: 20px;
  align-items: center;
  background: white;
  padding: 18px 24px;
  cursor: pointer;

  &.current {
    background: ${props => props.theme.color.dpu5};
  }
  
  & > a {
    justify-self: end;
  }
`;

const Dialog = (props: {chat: User}) => {
    const dispatch = useDispatch();
    const currentChatData = useSelector<storeType, UserData|null>((state) => state.currentChat);
    const currentChat = currentChatData ? new User(currentChatData) : null;
    const set = () => {
        dispatch(ChooseChat(props.chat.getValue()));
        //getChats();
    }

    return (
        <DialogStyle onClick={set} className={props.chat.id === currentChat?.id ? 'current' : ''}>
            <UserPhoto src={props.chat.avatar()} count={props.chat.unread} />
            <P1>{[props.chat.lastname, props.chat.firstname, props.chat.fatherName].join(' ')}</P1>
            <ButtonText href={props.chat.linkToView()}><H5>Перейти</H5></ButtonText>
        </DialogStyle>
    );
}

const DialogsStyle = styled.div`
  display: grid;
  grid-template-rows: min-content auto;
  overflow-x: hidden;
  
  & h4 {
    margin: 20px 24px;
  }
`;

const Container = styled.div`
  display: grid;
  border-top: 1px solid ${props => props.theme.color.dpu5};
  border-bottom: 1px solid ${props => props.theme.color.dpu5};
  overflow-y: scroll;
`;

const Dialogs = () => {
    const meData = useSelector<storeType, UserData | null>((state) => state.me);
    const me = meData ? new User(meData) : null;
    const chatsData = useSelector<storeType, UserData[]>((state) => state.chats);
    const chats = chatsData.map(c => new User(c));
    const currentChatData = useSelector<storeType, UserData|null>((state) => state.currentChat);
    const currentChat = currentChatData ? new User(currentChatData) : null;
    const dispatch = useDispatch();
    const [chatsLoading, setChatsLoading] = useState(false);

    const scroll:UIEventHandler<HTMLDivElement> = () => {
        const dialogs = document.getElementsByClassName('dialogs')[0].getBoundingClientRect();
        const dialogs_container = document.getElementsByClassName('dialogs-container')[0].getBoundingClientRect();
        //console.log(dialogs, dialogs_container);
        if (dialogs_container.height / 2 + dialogs_container.bottom > dialogs.bottom) {
            getNextChats();
        }
    }

    const getNextChats = () => {
        if (!chatsLoading) {
            setChatsLoading(true);
            ChatsAPI.getNextChats()
                .finally(() => setChatsLoading(false));
        }
    }

    useEffect(() => {
        if (me && me.role.isAdmin()) {
            getNextChats();
        }
        else {
            const chat_ = AdminUser;
            dispatch(AddChats([chat_.getValue()]));
            dispatch(ChooseChat(chat_.getValue()));
        }
    }, [meData]);

    const location = useLocation();
    useEffect(() => {
        if (currentChat === null) {
            const user_id_str = (new URLSearchParams(location.search).get('id')) || '';
            const user_id = /^[0-9]+$/.test(user_id_str) ? parseInt(user_id_str) : undefined;
            const chat_ = chats.find(c => c.id === user_id);

            chat_ && dispatch(ChooseChat(chat_.getValue()));
        }
    }, [chats, currentChatData, location.search]);

    return (
        <DialogsStyle>
            <H4>Диалоги</H4>
            <Container className={'dialogs-container'} onScroll={scroll}>
                <div className={'dialogs'}>
                    {
                        chats.map((chat, i) =>
                            <Dialog key={i} chat={chat} />)
                    }
                </div>
            </Container>
        </DialogsStyle>
    );
};

export default Dialogs;
