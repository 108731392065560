import React, {useState} from 'react';
import styled from "styled-components";
import Slot from "./Slot";
import {day} from "../../../utils/types";
import {Images} from "../../../utils/images";
import {weekDays} from "../../../utils/constants";
import Loading from "../form/loading";

const DayStyle = styled.div`
  height: 420px;
  display: grid;
  border-radius: 9px;
  border: 1px solid #D9D9D9;
  border-right: 0;
  grid-template-rows: min-content 26px 255px auto;
  
  &:nth-last-child(1) {
    border-right: 1px solid #D9D9D9;
  }
    
    &.past {
        background: ${props => props.theme.color.bl4};
        border-color: white;
    }
`;

const WeekDay = styled.div`
  color: #919095;
  font-size: 13.5px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  
  .current & {
    color: ${props => props.theme.color.pi2};
  }
`;

const DayNumber = styled.div`
  color: ${props => props.theme.color.bl1};
  text-align: center;
  font-size: 25px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  width: 34px;
  height: 34px;
  display: grid;
  align-items: center;
  border-radius: 50%;
  
  .weekend & {
    color: ${props => props.theme.color.bl3};
  }

  .current & {
    color: white;
    background: ${props => props.theme.color.b1};
  }
`;

const Numbers = styled.div`
  display: grid;
  grid-gap: 5px;
  padding-top: 26px;
  align-content: start;
  justify-items: center;
`;

const Slots = styled.div`
  display: grid;
  grid-gap: 3px;
  align-content: start;
  overflow: hidden;
`;

const ButtonDown = styled.button`
  display: grid;
  height: 26px;
  align-content: center;
  justify-content: center;
  width: 100%;
  background: none;
  border: none;
  cursor: pointer;
`;

const ButtonUp = styled(ButtonDown)`
  & img {
    transform: rotate(180deg);
  }
`;

const TextBlock = styled.div`
    width: 100%;
    height: 100%;

    color: ${props => props.theme.color.bl2};
    text-align: center;
    font-size: 13.5px;
    font-weight: 600;
    line-height: 18px;

    display: grid;
    align-content: center;
    
    .mobile & {
        writing-mode: vertical-rl;
    }
`;

const DynamicEmptyPastText = <>Запись уже недоступна</>;
const DynamicEmptyActualText = <>Доступного времени для записи нет</>;
const StaticEmptyText = <>Записей нет</>;

const Day = (props: {day: day, isDynamic: boolean}) => {
    const [offset, setOffset] = useState(0);

    let text = StaticEmptyText;
    if (props.isDynamic) {
        if (props.day.typ === 'past') {
            text = DynamicEmptyPastText;
        }
        else {
            text = DynamicEmptyActualText;
        }
    }

    let dayBody = <TextBlock><Loading /></TextBlock>;

    if (props.day.typ !== 'loading') {
        if (props.day.slots.length === 0) {
            dayBody = <TextBlock>{text}</TextBlock>;
        } else {
            dayBody = <Slots>
                {
                    props.day.slots.map((slot, i) =>
                        <Slot offset={offset} key={i} slot={slot}/>)
                }
            </Slots>;
        }
    }

    return (
        <DayStyle className={props.day.typ}>
            <Numbers>
                <WeekDay>{weekDays[props.day.weekDay]}</WeekDay>
                <DayNumber>{props.day.date.getDate()}</DayNumber>
            </Numbers>
                {
                    offset > 0
                        ?
                        <ButtonUp onClick={() => setOffset(offset - 1)}>
                            <img src={Images.icons.down} />
                        </ButtonUp>
                        : <div />
                }
                {dayBody}
                {
                    offset + 6 < props.day.slots.length
                        ?
                        <ButtonDown onClick={() => setOffset(offset + 1)}>
                            <img src={Images.icons.down} />
                        </ButtonDown>
                        : <div />
                }
        </DayStyle>
    );
};

export default Day;