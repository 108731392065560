import React, {useState} from 'react';
import Template from "../components/template/template";
import Login from "../components/auth/Login";
import Register from "../components/auth/Register";
import Code from "../components/auth/Code";
import Success from "../components/auth/Success";
import Recovery from "../components/auth/Recovery";
import NewPassword from "../components/auth/NewPassword";

const pages = {
    login: Login,
    register: Register,
    code: Code,
    success: Success,
    recovery: Recovery,
    newPass: NewPassword
}
export type pageNames = 'login'|'register'|'code'|'success'|'recovery'|'newPass';

interface props_ {
    text?: string;
    next?: (value?: any) => void;
    prev?: () => void;
}

export interface pageProps extends props_ {
    setPage: (page: pageNames, props: props_) => void;
}

const Auth = () => {
    const [page, setPage_] = useState<pageNames>('login');
    const [pageData, setPageData] = useState<props_>({});
    const CPage = pages[page];

    const setPage = (page: pageNames, pageData_: props_) => {
        setPageData({...pageData_});
        setPage_(page);
        window.scrollTo({top: 0});
    }

    return (
        <Template>
            {
                <CPage setPage={setPage} {...pageData} />
            }
        </Template>
    );
};

export default Auth;
