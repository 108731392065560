import React, {useEffect, useState} from 'react';
import {
    ElementAddStyle, ElementEditButtonsCenter,
    ElementHeaderActions,
    ElementHeaderButtonsOrder, ElementHeaderDeleteContainer, ElementHeaderEmpty, ElementHeaderStyle,
    ElementsContainer,
    ElementStyle
} from "../shared/list";
import {work_place, work_place_editable} from "../../../utils/types";
import {H5} from "../../shared/fonts/fonts";
import {ButtonPU, ButtonText, ButtonTextAngry, ButtonWhite} from "../../shared/buttons/buttons";
import {InputText, InputTextArea} from "../../shared/form/input";
import post from "../../../api/post";
import {useSelector} from "react-redux";
import {storeType} from "../../../redux/store";
import {getWorkPlaces} from "../../../api/methods/getWorkPlaces";

type setWorkPlacesFunction = (value: work_place_editable[]) => void;
type setEditWPFunction = (value: number) => void;

interface Props {
    index: number,
    workPlaces: work_place_editable[];
    length: number;
    setWorkPlaces: setWorkPlacesFunction,
    setEditWP: setEditWPFunction
}

interface HeaderProps extends Props {
    editMode: boolean,
    children: any
}

const close = (props: Props) => props.setEditWP(-1);
const choose = (props: Props) => props.setEditWP(props.index);

const set = (props: Props, data: work_place_editable) =>
    props.setWorkPlaces([
        ...props.workPlaces.slice(0, props.index),
        {...props.workPlaces[props.index], ...data},
        ...props.workPlaces.slice(props.index + 1, props.workPlaces.length)]);

const drop = (props: Props) => {
    props.setWorkPlaces([
        ...props.workPlaces.slice(0, props.index),
        ...props.workPlaces.slice(props.index + 1, props.workPlaces.length)]);
    close(props);
}

const swap = (props: Props, vector: -1|1) => {
    if (props.index === 0 && vector === -1) {
        return;
    }
    if (props.index === props.workPlaces.length - 1 && vector === 1) {
        return;
    }
    const start = vector === 1 ? props.index : props.index - 1;
    props.setWorkPlaces([
        ...props.workPlaces.slice(0, start),
        ...[props.workPlaces[start + 1], props.workPlaces[start]],
        ...props.workPlaces.slice(start + 2, props.workPlaces.length)]);
}

const EditWorkPlace = (props: Props) => {
    const setTitle = (e: any) => set(props, {title: e.target.value});
    const setAddress = (e: any) => set(props, {address: e.target.value});
    const setLink = (e: any) => set(props, {link: e.target.value});

    return (
        <ElementStyle>
            <Header {...props} editMode={true}>{props.workPlaces[props.index].title}</Header>
            <InputText header={'Название:'}
                       input={{value: props.workPlaces[props.index].title || '', onInput: setTitle}} />
            <InputTextArea header={'Адрес:'}
                       input={{value: props.workPlaces[props.index].address || '', onInput: setAddress}} />
            <InputText header={'Ссылка:'}
                       input={{placeholder: 'Можно оставить пустым',
                           value: props.workPlaces[props.index].link || '', onInput: setLink}} />
        </ElementStyle>
    )
}

const Header = (props: HeaderProps) => {
    const [deleteApply, setDeleteApply] = useState(false);

    useEffect(() => setDeleteApply(false), [props.workPlaces]);

    return (
        <ElementHeaderStyle>
            <H5>{props.children || <ElementHeaderEmpty>Введите название</ElementHeaderEmpty>}</H5>
            <ElementHeaderActions>
                {
                    !props.editMode &&
                    <ElementHeaderButtonsOrder>
                        {
                            props.index > 0
                                ? <div onClick={() => swap(props,-1)} className={'active'}>▲</div>
                                : <div>△</div>
                        }
                        {
                            props.index < props.length - 1
                                ? <div onClick={() => swap(props,1)} className={'active'}>▼</div>
                                : <div>▽</div>
                        }
                    </ElementHeaderButtonsOrder>
                }
                {
                    props.editMode ?
                        <ButtonText onClick={() => close(props)}>
                            <H5>Свернуть</H5>
                        </ButtonText>
                        :
                        <ButtonText onClick={() => choose(props)}>
                            <H5>Развернуть</H5>
                        </ButtonText>
                }
                {
                    deleteApply ?
                        <ElementHeaderDeleteContainer>
                            <H5>Вы уверены?</H5>
                            <ButtonTextAngry onClick={() => drop(props)}>
                                <H5>Удалить</H5>
                            </ButtonTextAngry>
                            <ButtonText onClick={() => setDeleteApply(false)}>
                                <H5>Отмена</H5>
                            </ButtonText>
                        </ElementHeaderDeleteContainer>
                        :
                        <ButtonTextAngry onClick={() => setDeleteApply(true)}>
                            <H5>Удалить</H5>
                        </ButtonTextAngry>
                }
            </ElementHeaderActions>
        </ElementHeaderStyle>
    );
}

const WorkPlace = (props: Props) => {
    return (
        <ElementStyle>
            <Header {...props} editMode={false}>{props.workPlaces[props.index].title}</Header>
        </ElementStyle>
    )
}

const WorkPlaces = () => {
    const [realWorkPlaces, setRealWorkPlaces] = useState<work_place[]>([]);
    const [workPlaces, setWorkPlaces] = useState<work_place_editable[]>([]);
    const [editWP, setEditWP] = useState<number>(-1);
    const token = useSelector<storeType, string|null>((state) => state.token);

    const [empty, setEmpty] = useState(false);
    const [edited, setEdited] = useState(false);

    const add = () =>
        setWorkPlaces([{}, ...workPlaces]);

    const load = () => {
        getWorkPlaces()
            .then(list => {
                setEditWP(-1);
                setRealWorkPlaces(list);
                setWorkPlaces(list);
            })
            .catch(error => console.log(error));
    }

    const cancel = () => setWorkPlaces(realWorkPlaces);

    const save = () => {
        if (empty || !edited) {
            return;
        }
        else {
            post('editWorkPlaces', {token, list: workPlaces})
                .then(() => load())
                .catch(error => console.log(error));
        }
    }

    useEffect(() => load(), []);

    useEffect(() => {
        setEmpty(!workPlaces.every(wp => wp.title && wp.address));
        setEdited(!(workPlaces.length === realWorkPlaces.length &&
            workPlaces.every((wp, i) => (
                wp.title === realWorkPlaces[i].title &&
                wp.address === realWorkPlaces[i].address &&
                wp.link === realWorkPlaces[i].link)
            ))
        );
    }, [workPlaces]);

    return (
        <ElementsContainer>
            <ElementAddStyle onClick={add}>
                <H5>Добавить новый адрес</H5>
            </ElementAddStyle>
            {
                workPlaces.map((wp, i) =>
                    i === editWP ?
                        <EditWorkPlace length={workPlaces.length} key={i} setWorkPlaces={setWorkPlaces}
                                       setEditWP={setEditWP}
                                       workPlaces={workPlaces} index={i} />
                        :
                        <WorkPlace length={workPlaces.length} key={i} index={i} setWorkPlaces={setWorkPlaces}
                                   setEditWP={setEditWP}
                                   workPlaces={workPlaces} />
                )
            }
            <ElementEditButtonsCenter>
                <ButtonWhite onClick={cancel} className={edited ? '' : 'disabled'}>
                    <H5>Сбросить изменения</H5>
                </ButtonWhite>
                <ButtonPU onClick={save} className={(edited && !empty) ? '' : 'disabled'}>
                    <H5>Сохранить</H5>
                </ButtonPU>
            </ElementEditButtonsCenter>
        </ElementsContainer>
    );
};

export default WorkPlaces;
