import React, {useState} from 'react';
import Success from "../components/auth/Success";
import Template from "../components/template/template";
import {H2, H5} from "../components/shared/fonts/fonts";
import InfoRow from "../components/appointment/InfoRow";
import {InputTextArea} from "../components/shared/form/input";
import Error from "../components/auth/Error";
import {ButtonPU} from "../components/shared/buttons/buttons";
import Form from "../components/shared/form/form";
import styled from "styled-components";
import {useLocation} from "react-router-dom";
import post from "../api/post";
import {useSelector} from "react-redux";
import {storeType} from "../redux/store";
import User from "../entities/User";

const Fields = styled.div`
  display: grid;
  grid-gap: 20px;
  width: 100%;
  
  .mobile & textarea {
    width: 100%;
  }
`;

const Buttons = styled.div`
  display: grid;
  grid-gap: 20px;
  justify-items: center;
`;

const WriteReviewForm = (props: {setGoSuccess: (value: boolean) => void}) => {
    const token = useSelector<storeType, string|null>((state) => state.token);
    const [textLike, setTextLike_] = useState('');
    const [textUnLike, setTextUnLike_] = useState('');
    const [textComment, setTextComment_] = useState('');
    const [error, setError] = useState<string|null>(null);

    const setTextLike = (e: any) => setTextLike_(e.target.value);
    const setTextUnLike = (e: any) => setTextUnLike_(e.target.value);
    const setTextComment = (e: any) => setTextComment_(e.target.value);

    const location = useLocation();
    const slot_string = new URLSearchParams(location.search).get('slot');
    if (!slot_string || !/^[0-9]+$/.test(slot_string)) {
        return (
            <Form>
                <H2>Ошибка!</H2>
                <H5>Попробуйте позже.</H5>
            </Form>
        )
    }
    const slot_id = parseInt(slot_string);

    const next = () => {
        post('writeReview', {token, slot_id,
            text: {like: textLike, unlike: textUnLike, comment: textComment}})
            .then(() => props.setGoSuccess(true))
            .catch(error => {
                if (error.response && error.response.data && error.response.data.text) {
                    setError(error.response.data.text);
                }
                else {
                    setError('Неизвестная ошибка. Попробуйте позже.');
                }
            });
    }

    return (
        <Form>
            <H2>Оставьте отзыв</H2>
            <Fields>
                <InfoRow header={'Понравилось:'}>
                    <InputTextArea status={textLike ? 'success' : ''}
                                   input={{value: textLike, onInput: setTextLike,
                                       placeholder: 'Опишите, что вам понравилось'}} />
                </InfoRow>
                <InfoRow header={'Не понравилось:'}>
                    <InputTextArea status={textUnLike ? 'success' : ''}
                                   input={{value: textUnLike, onInput: setTextUnLike,
                                       placeholder: 'Опишите, что вам не понравилось'}} />
                </InfoRow>
                <InfoRow header={'Комментарий:'}>
                    <InputTextArea status={textComment ? 'success' : ''}
                                   input={{value: textComment, onInput: setTextComment,
                                       placeholder: 'Добавьте любой комментарий'}} />
                </InfoRow>
            </Fields>
            <Buttons>
                {
                    error && <Error>{error}</Error>
                }
                <ButtonPU onClick={next} className={(textLike || textUnLike || textComment) ? '' : 'disabled'}>
                    <H5>Отправить</H5>
                </ButtonPU>
            </Buttons>
        </Form>
    );
};
const WriteReview = () => {
    const [goSuccess, setGoSuccess] = useState(false);

    return (
        <Template>
            {
                goSuccess ?
                    <Success text={'Отзыв отправлен!'} setPage={() => window.open(User.linkToView(), '_self')} />
                    :
                    <WriteReviewForm setGoSuccess={setGoSuccess} />
            }
        </Template>
    );
};

export default WriteReview;