import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {H4, P1, P2} from "../shared/fonts/fonts";
import {review} from "../../utils/types";
import ReviewsArrow from "../../images/svgs/icons/ReviewsArrow";
import post from "../../api/post";
import {Images} from "../../utils/images";
import User from "../../entities/User";
import {getReviews} from "../../api/methods/reviews/get/getReviews";

const UserPhoto = styled.div<{src: string}>`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  
  background: ${props => props.src ? `url("${props.src}"), ` : ''}url("${Images.icons.avatar}");
  background-size: cover;
  background-position: center;
`;

const ReviewStyle = styled.div<{chosen: number; offset: number}>`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: 0.2fr 1fr 0.4fr 2.5fr 0.2fr;
  
  transition: opacity 0.2s ease-in-out, left 0.2s ease-in-out;
  opacity: ${props => props.chosen ? 1 : 0};
  position: relative;
  left: ${props => props.offset * 100}%;

  .mobile & {
    grid-auto-flow: row;
    grid-template-columns: 100%;
  }
`;

const UserInfo = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 16px;
  justify-content: center;
  
  & * {
    margin: 0;
  }
`;

const LargeChar = styled.div`
  font-size: 72px;
  font-family: Inter;
  font-weight: 800;
  line-height: 98px;
  color: ${props => props.theme.color.b2};
  align-self: start;
`;

const Text = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  justify-content: start;
  
  & div {
  }
  
  & p {
    margin: 30px 0 0 0;
  }
`;

const P1Answer = styled(P1)`
  & b {
    color: ${props => props.theme.color.dpu2};
  }
`;

const Review = (props: { review: review; current: number; me: number }) => {


    return (
        <ReviewStyle chosen={+(props.me === props.current)} offset={-props.current}>
            <div />
            <UserInfo>
                <UserPhoto src={props.review.user?.avatar() || User.avatar()} />
                <div>
                    <H4>{props.review.user
                        ? (props.review.user.firstname +
                            (props.review.user.fatherName ? ' ' +
                                props.review.user.fatherName : '') + ' ' +
                            props.review.user.lastname + '.')
                        : props.review.user_value}</H4>
                    {
                        props.review.user ? <P2>{props.review.user.age()}</P2> : null
                    }
                </div>
            </UserInfo>
            <div />
            <Text>
                <LargeChar>“</LargeChar>
                <div>
                    {
                        props.review.text_like.length > 0 && <P1><b>Понравилось</b><br/>{props.review.text_like}</P1>
                    }
                    {
                        props.review.text_unlike.length > 0 && <P1><b>Не понравилось</b><br/>{props.review.text_unlike}</P1>
                    }
                    {
                        props.review.text_comment.length > 0 && <P1><b>Комментарий</b><br/>{props.review.text_comment}</P1>
                    }
                    {
                        props.review.answer && <P1Answer><b>Ответ администратора</b><br/>{props.review.answer}</P1Answer>
                    }
                </div>
            </Text>
            <div />
        </ReviewStyle>
    )
}

const ReviewsStyle = styled.div`
  display: grid;
  margin: 60px 0;
  grid-template-columns: 100%;
  position: relative;
  overflow: hidden;
`;

const ReviewsButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 60px calc(100% - 120px) 60px;
  margin: 60px 0 102px 0;
`;

const ArrowButtonRight = styled.div`
  display: grid;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;

  & > img {
    //transition: transform 0.2s ease-in-out;
  }
  
  &:hover > img {
    //transform: rotate(360deg);
  }
`;

const ArrowButtonLeft = styled(ArrowButtonRight)`
  & > svg {
    transform: rotate(180deg);
  }
`;

const ReviewsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 100%;
  align-items: start;
`;

const Points = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-gap: 6px;
`;

const Point = styled.div<{chosen: number}>`
  height: 10px;
  width: 10px;
  background: ${props => props.chosen ? props.theme.color.b2 : props.theme.color.dpu4};
  border-radius: 50%;
`;

const Reviews = () => {
    const [reviews, setReviews] = useState<review[]>([]);
    const [current, setCurrent] = useState(0);

    useEffect(() => {
        getReviews()
            .then(setReviews)
            .catch(error => console.log(error));
    }, []);

    if (reviews.length === 0) {
        return <></>
    }

    return (
        <ReviewsStyle>
            <ReviewsButtons>
                <ArrowButtonLeft onClick={() => setCurrent((reviews.length + current - 1) % reviews.length)}>
                    <ReviewsArrow />
                </ArrowButtonLeft>
                <ReviewsContainer>
                    {
                        reviews.map((review, i) =>
                            <Review key={i} review={review} current={current} me={i} />)
                    }
                </ReviewsContainer>
                <ArrowButtonRight onClick={() => setCurrent((current + 1) % reviews.length)}>
                    <ReviewsArrow />
                </ArrowButtonRight>
            </ReviewsButtons>
            <Points>
                {
                    reviews.map((review, i) => <Point key={i} chosen={+(current === i)} />)
                }
            </Points>
        </ReviewsStyle>
    );
};

export default Reviews;