import store from "../../../../redux/store";
import {setSlots, setSlotsLoading} from "../../../../redux/actions";
import get from "../../../get";

export const getWeek = (start: number, cons_id?: number) => {
    store.dispatch(setSlots([]));
    store.dispatch(setSlotsLoading());
    const data:any = {from: start};
    if (cons_id) {
        data.consultation_id = cons_id;
    }
    get('slots/available', data)
        .then(result => {
            store.dispatch(setSlots(result.data.slots));
        })
        .catch(error => console.log(error))
}