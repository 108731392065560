import React, {useState} from 'react';
import Form from "../shared/form/form";
import {H2, H5, H6, P2} from "../shared/fonts/fonts";
import styled from "styled-components";
import {pageProps} from "../../pages/appointment";
import {ButtonPU, ButtonText} from "../shared/buttons/buttons";
import Error from "../auth/Error";
import InfoRow from "./InfoRow";
import Loading from "../shared/form/loading";
import {communications, URLs, weekDays} from "../../utils/constants";
import {setWraps, toLocaleStringByMoscow} from "../../utils/functions";
import {CheckboxBlock} from "../shared/form/checkbox";
import Link from "../shared/form/link";
import {InputTextArea} from "../shared/form/input";
import {ByMoscowTime} from "./Main";
import Slot from "../../entities/Slot";
import {appoint} from "../../api/methods/slots/update/appoint";

export const Fields = styled.div`
  display: grid;
  grid-gap: 20px;
  width: 100%;
  
  .mobile & textarea {
    width: 100%;
  }
`;

export const Buttons = styled.div`
  display: grid;
  grid-gap: 20px;
  justify-items: center;
`;

interface ConfirmationProps extends pageProps {
    slot: Slot
}

export const dateToString = (ts: number) => {
    const d_ = new Date(ts * 1000);
    const s_ = toLocaleStringByMoscow(d_);
    return s_.slice(0, 10) + ', ' + weekDays[(d_.getDay() + 6) % 7];
}

export const timeToString = (ts: number) => {
    const d_ = new Date(ts * 1000);
    const s_ = toLocaleStringByMoscow(d_);
    return s_.slice(12, 17);
}

const DateStyle = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-gap: 8px;
  align-items: center;
  
  & :nth-child(2) {
    opacity: 0.5;
  }
`;

export const DateBlock = (props: {slot: Slot}) =>
    <DateStyle>
        <H5>
            {timeToString(props.slot.timestamp)}
        </H5>
        <span>-</span>
        <H5>
            {timeToString(props.slot.timestamp + props.slot.consultation.duration * 60)}
        </H5>
        <H5><ByMoscowTime>по <span>московскому</span> времени</ByMoscowTime></H5>
    </DateStyle>;

export const Links = styled.div`
  display: grid;
`;

export const CommunicationStyle = styled.div`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  justify-content: start;
  grid-gap: 10px;
  margin: 5px 0;
  
  & img {
    border-radius: 20%;
  }
`;

export const Communication = (props: {comm_link: string}) => {
    const communication = communications.find(c_ => c_.link === props.comm_link);

    return (<CommunicationStyle>
        <img src={communication?.icon} />
        <H5>{communication?.title}</H5>
    </CommunicationStyle>)
};

export const Requisite = styled.span`
  background: #D5D5D5;
  border-radius: 6px;
  padding: 0 6px;
  font-weight: inherit;
  font-size: 13px;
`;

export const PaymentStyle = styled.div`
  border-radius: 10px;
  padding: 9px;
  background: ${props => props.theme.color.dpu5};
  border: 4px solid ${props => props.theme.color.dpu4};
`;

const Confirmation = (props: ConfirmationProps) => {
    const [error, setError] = useState<string|null>(null);
    const [check, setCheck] = useState(false);
    const [communicationValue, setCommunicationValue_] = useState('');
    const setCommunicationValue = (e: any) => setCommunicationValue_(e.target.value.slice(0, 255));

    const [showPayment, setShowPayment] = useState(false);

    const pay = () => setShowPayment(true);

    const next = () => {
        appoint(props.slot, communicationValue)
            .then(result => {
                if (result.data.needPayment) {
                    /*props.setPage('payment', {
                        next: (f: (p: paymentData, slot: slot) => void) => f(result.data.payment, props.slot)
                    });*/
                    setError('В настоящее время оплата не работает. Ведутся технические работы.');
                }
                else {
                    window.open(URLs.appointmentResult + '?id=' + props.slot.id, '_self');
                }
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.text) {
                    setError(error.response.data.text);
                }
                else {
                    setError('Неизвестная ошибка. Попробуйте позже.');
                }
            })
    }

    const prev = () => props.setPage('login', {});

    if (!props.slot) {
        return (<Form>
            <H2>Запись на онлайн-информирование</H2>
            <Fields>
                <Loading />
            </Fields>
            <Buttons>
                <ButtonText onClick={prev}>
                    <H6>Вернуться</H6>
                </ButtonText>
            </Buttons>
        </Form>);
    }

    return (
        <Form>
            <H2>Запись на онлайн-информирование</H2>
            <Fields>
                <InfoRow header={'Название:'}><H5>{props.slot.consultation.title}</H5></InfoRow>
                {
                    props.slot.consultation.description &&
                    <InfoRow header={'Описание:'}><H5>{setWraps(props.slot.consultation.description)}</H5></InfoRow>
                }
                <InfoRow header={'Длительность:'}><H5>{props.slot.consultation.duration} мин.</H5></InfoRow>
                <InfoRow header={'Стоимость:'}><H5>{props.slot.consultation.price} руб.</H5></InfoRow>
                <InfoRow header={'Время:'}>
                    <H5>
                        {dateToString(props.slot.timestamp)}
                    </H5>
                    <DateBlock slot={props.slot} />
                </InfoRow>
                <InfoRow header={'Средства связи:'}>
                    {props.slot.consultation.communications.map((c, i) => <Communication key={i} comm_link={c} />)}
                </InfoRow>
                <InfoRow header={'Контакты для связи:'} required={true}>
                <InputTextArea status={communicationValue ? 'success' : ''}
                           input={{value: communicationValue, onInput: setCommunicationValue, name: 'comm_value',
                               placeholder: 'Укажите контакты для связи хотя бы одним из указанных выше способов'}} />
                </InfoRow>
                <InfoRow header={'Я ознакомлен и согласен со следующими документами:'}>
                    <CheckboxBlock checkbox={{value: check, onChange: () => setCheck(!check)}}>
                        <Links>
                            <Link target={'_blank'} href={'./assets/documents/Оферта.pdf'}>
                                Оферта
                            </Link>
                            <Link target={'_blank'} href={'./assets/documents/Политика конфиденциальности.pdf'}>
                                Политика конфиденциальности
                            </Link>
                            <Link target={'_blank'} href={'./assets/documents/Политика обработки ПД.pdf'}>
                                Политика обработки персональных данных
                            </Link>
                            <Link target={'_blank'} href={'./assets/documents/Пользовательское соглашение.pdf'}>
                                Пользовательское соглашение
                            </Link>
                            <Link target={'_blank'} href={'./assets/documents/Согласие на обработку ПД.pdf'}>
                                Согласие на обработку персональных данных
                            </Link>
                        </Links>
                    </CheckboxBlock>
                </InfoRow>
                {
                    showPayment &&
                    <PaymentStyle>
                        <InfoRow header={''}>
                            <P2>Оплата производится:<br />
                                - по номеру счета <Requisite>40817810101000765922</Requisite><br/>
                                - или номеру карты <Requisite>2202 2067 3979 4852</Requisite> <i>Сбер, Заира Аслановна С.</i><br/>
                                <br/>
                                <b>После оплаты просьба прислать чек в WhatsApp или Telegram <Requisite>+7 (918) 010-85-55</Requisite>.</b><br/>
                                При отсутствии оплаты в течение суток после бронирования времени, бронь будет отменена.<br/>
                                Если у Вас возникли вопросы по поводу оплаты, просьба написать на почту doc_zaira@mail.ru.<br/>
                            </P2>
                        </InfoRow>
                    </PaymentStyle>
                }
            </Fields>
            <Buttons>
                {
                    error && <Error>{error}</Error>
                }
                {(props.slot.consultation.price > 0 && showPayment === false) ?
                    /*<ButtonPU onClick={next} className={check && communicationValue ? '' : 'disabled'}>
                        <H5>Перейти к оплате</H5>
                    </ButtonPU>*/
                    <ButtonPU onClick={pay} className={check && communicationValue ? '' : 'disabled'}>
                        <H5>Перейти к оплате</H5>
                    </ButtonPU>
                    :
                    <ButtonPU onClick={next} className={check && communicationValue ? '' : 'disabled'}>
                        <H5>Записаться</H5>
                    </ButtonPU>
                }
                <ButtonText onClick={prev}>
                    <H6>Вернуться</H6>
                </ButtonText>
            </Buttons>
        </Form>
    );
};

export default Confirmation;
