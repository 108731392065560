import React, {useEffect, useState} from 'react';
import {processPhone} from "../../utils/functions";
import {useDispatch, useSelector} from "react-redux";
import post from "../../api/post";
import {setRegData} from "../../redux/actions";
import Form from "../shared/form/form";
import {H2, H5, H6} from "../shared/fonts/fonts";
import {InputText} from "../shared/form/input";
import {ButtonPU, ButtonText} from "../shared/buttons/buttons";
import Error from "./Error";
import styled from "styled-components";
import {pageProps} from "../../pages/auth";
import store, {storeType} from "../../redux/store";

const Fields = styled.div`
  display: grid;
  grid-gap: 22px;
  width: 100%;
`;

const Buttons = styled.div`
  display: grid;
  grid-gap: 20px;
  justify-items: center;
`;

const Recovery = (props: pageProps) => {
    const regData = useSelector<storeType, any>((state) => state.regData);

    const phone = processPhone(regData.phone || '');
    const [phoneStatus, setPhoneStatus] = useState<string|null>(null);
    const statuses = [phoneStatus];

    const [buttonActive, setButtonActive] = useState(false);
    const [error, setError] = useState<string|null>(null);
    const dispatch = useDispatch();

    const checkPhone = (phone_: string) => {
        if (phone_.length === 11) {
            setPhoneStatus('success');
        }
        else if (phone_.length > 11) {
            setPhoneStatus('error');
        }
        else {
            setPhoneStatus(null);
        }
    }
    const setPhone = (e: any) => {
        const phone_ = e.target.value.replace(/[^0-9]/g, '');
        checkPhone(phone_);
        dispatch(setRegData({phone: phone_}));
    }

    const recover = (code: string) => {
        post('checkRecoverCode', {phone: store.getState().regData.phone, code})
            .then(result => {
                dispatch(setRegData({recoverToken: result.data.token}));
                props.setPage('newPass', {});
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.text) {
                    setError(error.response.data.text);
                }
                else {
                    setError('Неизвестная ошибка. Попробуйте позже.');
                }
            })
    }

    const next = () => {
        const sendPhone = phone.replace(/[^0-9]/g, '');
        post('recover', {phone: sendPhone})
            .then(() => {
                //dispatch(setRegData({code: result.data.code}));
                setError(null);
                props.setPage('code', {text: 'Восстановление пароля', next: recover,
                    prev: () => props.setPage('recovery', {})});
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.text) {
                    setError(error.response.data.text);
                }
                else {
                    setError('Неизвестная ошибка. Попробуйте позже.');
                }
            })
    }

    useEffect(() => {
        if (statuses.every(status => status === 'success')) {
            setButtonActive(true);
        }
        else {
            setButtonActive(false);
        }
    }, statuses);

    useEffect(() => {
        checkPhone(phone.replace(/[^0-9]/g, ''));
    }, []);

    return (
        <Form>
            <H2>Восстановление пароля</H2>
            <Fields>
                <InputText status={phoneStatus} header={'Номер телефона'}
                           input={{value: phone, onInput: setPhone, name: 'username'}} />
                <ButtonText onClick={() => props.setPage('login', {})}><H6>Войти</H6></ButtonText>
            </Fields>
            <Buttons>
                {
                    error && <Error>{error}</Error>
                }
                <ButtonPU onClick={next} className={buttonActive ? '' : 'disabled'}><H5>Далее</H5></ButtonPU>
            </Buttons>
        </Form>
    );
};

export default Recovery;