import React, {useEffect, useState} from 'react';
import {H2, H5, H6} from "../shared/fonts/fonts";
import {InputText} from "../shared/form/input";
import {ButtonPU, ButtonText} from "../shared/buttons/buttons";
import Form from "../shared/form/form";
import styled from "styled-components";
import {pageProps} from "../../pages/auth";
import {useDispatch, useSelector} from "react-redux";
import {processPhone} from "../../utils/functions";
import {setRegData} from "../../redux/actions";
import post from "../../api/post";
import store, {storeType} from "../../redux/store";
import Error from "./Error";
import {CheckboxBlock} from "../shared/form/checkbox";
import Link from "../shared/form/link";
import InfoRow from "../appointment/InfoRow";

const Fields = styled.div`
  display: grid;
  grid-gap: 22px;
  width: 100%;
`;

const InputList = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-gap: 10px;
  
  & input {
    width: 100%;
  }
  
  .mobile & {
    grid-auto-flow: row;
  }
`;

const Buttons = styled.div`
  display: grid;
  grid-gap: 20px;
  justify-items: center;
`;

const Links = styled.div`
  display: grid;
`;

const Register = (props: pageProps) => {
    const regData = useSelector<storeType, any>((state) => state.regData);

    const lastname = regData.lastname || '';
    const [lnStatus, setLnStatus] = useState<string|null>(null);
    const firstname = regData.firstname || '';
    const [fnStatus, setFnStatus] = useState<string|null>(null);
    const fatherName = regData.fatherName || '';
    const [faNStatus, setFaNStatus] = useState<string|null>(null);

    const birthdate = regData.birthdate ? (new Date(regData.birthdate * 1000)).toISOString().slice(0, 10) : '';
    const [bdStatus, setBdStatus] = useState<string|null>(null);
    const phone = processPhone(regData.phone || '');
    const [pStatus, setPStatus] = useState<string|null>(null);
    const email = regData.email || '';
    const [eStatus, setEStatus] = useState<string|null>(null);
    const password = regData.password || '';
    const [passStatus, setPassStatus] = useState<string|null>(null);

    const [check, setCheck] = useState(false);

    const [checkStatus, setCheckStatus] = useState<string|null>(null);

    useEffect(() => {
        setCheckStatus(check ? 'success' : null);
    }, [check]);

    const [error, setError] = useState<string|null>(null);

    const dispatch = useDispatch();

    const checkFIO = (value: string, empty?: boolean) => {
        if (value.length === 0 && !empty) {
            return null;
        }
        else if (/[^А-я\-\s]/.test(value)) {
            return 'error';
        }
        else {
            return 'success';
        }
    }
    const setLastname = (e: any) => {
        setLnStatus(checkFIO(e.target.value));
        dispatch(setRegData({lastname: e.target.value}));
    }
    const setFirstname = (e: any) => {
        setFnStatus(checkFIO(e.target.value));
        dispatch(setRegData({firstname: e.target.value}));
    }
    const setFatherName = (e: any) => {
        setFaNStatus(checkFIO(e.target.value, true));
        dispatch(setRegData({fatherName: e.target.value}));
    }

    const checkEmail = (value: string) => {
        if (value.length === 0) {
            setEStatus(null)
        }
        else if (/[^0-9-]/.test(value)) {
            setEStatus('success')
        }
        else {
            setEStatus('error')
        }
    }
    const setEmail = (e: any) => {
        checkEmail(e.target.value);
        dispatch(setRegData({email: e.target.value}));
    }
    const checkBirthDate = (value: number|null) => {
        if (value) {
            const date_ = Math.floor((new Date(value)).getTime() / 1000);
            const now = (new Date()).getTime() / 1000;
            if (date_ > now || (now - date_) / (3600 * 24 * 365) > 150) {
                setBdStatus('error');
            }
            else {
                setBdStatus('success')
            }
        }
        else {
            setBdStatus(null)
        }
    }
    const setBirthDate = (e: any) => {
        const date_ = e.target.value ? Math.floor((new Date(e.target.value)).getTime() / 1000) : null;
        checkBirthDate(date_);
        dispatch(setRegData({birthdate: date_}));
    }

    const checkPassword = (value: string) => {
        if (value.length > 0) {
            setPassStatus('success');
        }
        else {
            setPassStatus(null);
        }
    }
    const setPassword = (e: any) => {
        checkPassword(e.target.value);
        dispatch(setRegData({password: e.target.value}))
    }
    const checkPhone = (phone_: string) => {
        if (phone_.length === 11) {
            setPStatus('success');
        }
        else if (phone_.length > 11) {
            setPStatus('error');
        }
        else {
            setPStatus(null);
        }
    }
    const setPhone = (e: any) => {
        const phone_ = e.target.value.replace(/[^0-9]/g, '');
        checkPhone(phone_);
        dispatch(setRegData({phone: phone_}));
    }

    const [buttonActive, setButtonActive] = useState(false);
    const statuses = [lnStatus, fnStatus, faNStatus, bdStatus, pStatus, eStatus, passStatus, checkStatus];

    useEffect(() => {
        if (statuses.every(status => status === 'success')) {
            setButtonActive(true);
        }
        else {
            setButtonActive(false);
        }
    }, [statuses]);

    useEffect(() => {
        setLnStatus(checkFIO(lastname));
        setFnStatus(checkFIO(firstname));
        setFaNStatus(checkFIO(fatherName, true));
        checkEmail(email);
        checkBirthDate(birthdate ? Math.floor((new Date(birthdate)).getTime() / 1000) : null);
        checkPassword(password);
        checkPhone(phone.replace(/[^0-9]/g, ''));
    }, []);

    const reg = (code: string) => {
        post('register', {...regData, code})
            .then(() => {
                props.setPage('success', {text: 'Регистрация прошла успешно!'});
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.text) {
                    setError(error.response.data.text);
                }
                else {
                    setError('Неизвестная ошибка. Попробуйте позже.');
                }
            })
    }

    const next = () => {
        post('checkPhone', {phone: store.getState().regData.phone, email: store.getState().regData.email})
            .then(() => {
                //dispatch(setRegData({code: result.data.code}));
                setError(null);
                props.setPage('code', {text: 'Подтверждение телефона', next: reg,
                    prev: () => props.setPage('register', {})});
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.text) {
                    setError(error.response.data.text);
                }
                else {
                    setError('Неизвестная ошибка. Попробуйте позже.');
                }
            })
    }

    return (
        <Form>
            <H2>Регистрация</H2>
            <Fields>
                <InputList>
                    <InputText status={lnStatus} header={'Фамилия'} input={{value: lastname, onInput: setLastname, name: 'lastname'}} />
                    <InputText status={fnStatus} header={'Имя'} input={{value: firstname, onInput: setFirstname, name: 'firstname'}} />
                    <InputText status={faNStatus} header={'Отчество'} input={{value: fatherName, onInput: setFatherName, name: 'fatherName'}} />
                </InputList>
                <InputText status={bdStatus} header={'Дата рождения'}
                           input={{value: birthdate, onInput: setBirthDate, name: 'birthdate', type: 'date'}} />
                <InputText status={pStatus} header={'Номер телефона'} input={{value: phone, onInput: setPhone, name: 'phone'}} />
                <InputText status={eStatus} header={'Почта'} input={{value: email, onInput: setEmail,
                    name: 'email', placeholder: 'example@mail.ru'}} />
                <InputText status={passStatus} header={'Пароль'} input={{value: password, onInput: setPassword, name: 'password', type: 'password'}} />
                <InfoRow header={'Я ознакомлен и согласен со следующими документами:'}>
                    <CheckboxBlock checkbox={{value: check, onChange: () => setCheck(!check)}}>
                        <Links>
                            <Link target={'_blank'} href={'./assets/documents/Политика конфиденциальности.pdf'}>
                                Политика конфиденциальности
                            </Link>
                            <Link target={'_blank'} href={'./assets/documents/Политика обработки ПД.pdf'}>
                                Политика обработки персональных данных
                            </Link>
                            <Link target={'_blank'} href={'./assets/documents/Пользовательское соглашение.pdf'}>
                                Пользовательское соглашение
                            </Link>
                            <Link target={'_blank'} href={'./assets/documents/Согласие на обработку ПД.pdf'}>
                                Согласие на обработку персональных данных
                            </Link>
                        </Links>
                    </CheckboxBlock>
                </InfoRow>
            </Fields>
            <Buttons>
                {
                    error && <Error>{error}</Error>
                }
                <ButtonPU onClick={next} className={buttonActive ? '' : 'disabled'}><H5>Продолжить</H5></ButtonPU>
                <ButtonText onClick={() => props.setPage('login', {})}><H6>У меня уже есть аккаунт</H6></ButtonText>
            </Buttons>
        </Form>
    );
};

export default Register;