import store from "../../redux/store";
import get from "../get";
import {UserData} from "../../entities/User";
import {setMe} from "../../redux/actions";

export const getUser = (user_id?: number) =>
    new Promise((resolve: (user: UserData) => void, reject: (error: any) => void) => {
        get(`user/${user_id || 'me'}`)
            .then(result => resolve(result.data.user))
            .catch(error => console.log(error))
    });

export const loadMe = () => {
    getUser()
        .then(user => store.dispatch(setMe(user)))
        .catch(error => console.log(error))
}