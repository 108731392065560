import React, {useEffect} from 'react';
import {ThemeProvider} from "styled-components";
import Theme from "./utils/theme";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {routes} from "./utils/routes";
import Auth from "./pages/auth";
import GlobalStyle from "./utils/globalStyle";
import {useViewport, ViewportProvider} from "./utils/ViewportContext";
import {storeType} from "./redux/store";
import {useSelector} from "react-redux";
import User, {UserData} from "./entities/User";
import {getConsultations} from "./api/methods/getConsultations";
import {loadMe} from "./api/methods/getUser";
import Role, {ROLES_VALUES} from "./entities/Role";

const AppDiv = () => {
    const viewport = useViewport();
    const meData = useSelector<storeType, UserData | null>((state) => state.me);
    const me = meData ? new User(meData) : null;
    const token = useSelector<storeType, string | null>((state) => state.token);
    const role_code = parseInt(window.localStorage.getItem('role') || '0');
    const role = new Role(role_code as ROLES_VALUES);

    useEffect(() => {
        getConsultations();
        token && loadMe();
    }, [token]);

    return (
        <div className={"App " + viewport.device}>
            <GlobalStyle />
            <BrowserRouter>
                <Routes>
                    { routes.map((route, i) =>
                        <Route key={i} path={route.url}
                               element={(me ? me.role.hasRightIn(route.role) : role.hasRightIn(route.role)) ? <route.page/> : <Auth />}
                        />
                    ) }
                </Routes>
            </BrowserRouter>
        </div>
    )
}

function App() {
    return (
        <ThemeProvider theme={Theme}>
            <ViewportProvider>
                <AppDiv />
            </ViewportProvider>
        </ThemeProvider>
  );
}

export default App;
