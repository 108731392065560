import React from 'react';
import styled from "styled-components";
import {timeToString} from "../../../utils/functions";
import {useDispatch, useSelector} from "react-redux";
import {ChooseSlot} from "../../../redux/actions";
import {storeType} from "../../../redux/store";
import SlotClass, {SlotData} from "../../../entities/Slot";

const SlotStyle = styled.div<{offset: number; color: string}>`
  color: ${props => props.theme.color.bl1};
  text-align: center;
  font-size: 13.5px;
  font-weight: 400;
  line-height: 18px;
  border: 4px solid #${props => props.color};
  border-top: 2px solid ${props => props.theme.color.bl5};
  border-bottom: 2px solid ${props => props.theme.color.bl5};

  padding: 9px 4px;
  border-radius: 86px;
  background: ${props => props.theme.color.bl5};
  margin: 0 6px;

  position: relative;
  top: ${props => props.offset * -43}px;
  transition: top 0.2s ease-in-out;

  cursor: pointer;
  
  &.active {
    background: ${props => props.theme.color.dpu2};
    color: white;
    font-weight: 600;
  }
`;

const Slot = (props: {slot: SlotClass; offset: number}) => {
    const dispatch = useDispatch();
    const currentSlotData = useSelector<storeType, SlotData|null>((state) => state.slot);
    const currentSlot = currentSlotData ? new SlotClass(currentSlotData) : null;

    const click = () => {
        if (currentSlot?.id === props.slot.id) {
            dispatch(ChooseSlot(null));
        }
        else {
            dispatch(ChooseSlot(props.slot.getValue()));
        }
    }

    return (
        <SlotStyle onClick={click} color={props.slot.consultation.color || ''} offset={props.offset}
                   className={currentSlot?.id === props.slot.id ? 'active' : ''}>
            {timeToString(props.slot.timestamp)}
        </SlotStyle>
    );
};

export default Slot;