import store from "../../../../redux/store";
import {setSlots, setSlotsLoading} from "../../../../redux/actions";
import get from "../../../get";

export const getUserAppointments = (start: number, user_id?: number) => {
    store.dispatch(setSlots([]));
    store.dispatch(setSlotsLoading());
    const data:any = {from: start};
    if (user_id) {
        data.user_id = user_id;
    }
    get('appointments/user', data)
        .then(result => {
            store.dispatch(setSlots(result.data.appointments));
        })
        .catch(error => console.log(error))
}
