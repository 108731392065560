import React from 'react';
import styled from "styled-components";
import {Images} from "../../../utils/images";

const FooterLeftStyle = styled.svg`
  position: absolute;
  left: 0;
  bottom: 0;
`;

const FooterLeft = () => {
    return (
        <FooterLeftStyle width="290" height="286" viewBox="0 0 290 286" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <path fillRule="evenodd" clipRule="evenodd" d="M275.194 135.812C311.342 207.552 272.787 282.266 222.97 335.778C181.651 380.163 119.687 391.483 60.7566 366.156C-12.6583 334.605 -89.5705 281.772 -90.7988 200.929C-92.2629 104.564 -33.7309 18.7277 55.2376 2.89852C142.536 -12.6335 231.506 49.1095 275.194 135.812Z" fill="#B994E9"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M275.194 135.812C311.342 207.552 272.787 282.266 222.97 335.778C181.651 380.163 119.687 391.483 60.7566 366.156C-12.6583 334.605 -89.5705 281.772 -90.7988 200.929C-92.2629 104.564 -33.7309 18.7277 55.2376 2.89852C142.536 -12.6335 231.506 49.1095 275.194 135.812Z" fill="url(#pattern_footer_left)" fillOpacity="0.5"/>
            <defs>
                <pattern id="pattern_footer_left" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref="#image_gradient1" transform="matrix(0.000390625 0 0 0.000414542 0 -0.030614)"/>
                </pattern>
            </defs>
        </FooterLeftStyle>
    );
};

export default FooterLeft;