import React, {useEffect, useState} from 'react';
import Template from "../components/template/template";
import Appointment from "../components/appointment/Appointment";
import {useLocation} from "react-router-dom";

const AppointmentView = () => {
    const loc = useLocation();
    const id_string = new URLSearchParams(loc.search).get("id");
    const [id, setId] = useState<number|null>(null);

    useEffect(() => {
        if (id_string && /^[0-9]+$/.test(id_string)) {
            setId(parseInt(id_string));
        }
    }, [id_string]);

    return (
        <Template>
            <Appointment />
        </Template>
    );
};

export default AppointmentView;
