import env from "../../env";
import store from "../../redux/store";
import post from "../../api/post";

interface successResult {
    success: 1,
    file: {
        url: string,
    }
}

interface errorResult {
    success: 0
}

type result = successResult | errorResult;
const uploadByFile = (file: File) =>
    new Promise((resolve: (value: result) => void) => {
        let formData = new FormData();
        formData.set('token', store.getState().token || '');
        formData.set('file', file);

        post('loadArticleFile', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(result => {
                resolve({
                    success: 1,
                    file: {
                        url: env.API_HOST + '/assets/files/' + result.data.filename,
                    }
                });
            })
            .catch(() => {
                resolve({
                    success: 0
                });
            })
    });

export default uploadByFile;