const Localization = {
    ui: {
        "blockTunes": {
            "toggler": {
                "Click to tune": "Нажмите, чтобы настроить",
                "or drag to move": "или перетащите"
            },
        },
        "inlineToolbar": {
            "converter": {
                "Convert to": "Конвертировать в"
            }
        },
        "toolbar": {
            "toolbox": {
                "Add": "Добавить"
            }
        }
    },

    toolNames: {
        "Text": "Параграф",
        "Heading": "Заголовок",
        "List": "Список",
        "Warning": "Примечание",
        "Checklist": "Чеклист",
        "Quote": "Цитата",
        "Code": "Код",
        "Delimiter": "Разделитель",
        "Raw HTML": "HTML-фрагмент",
        "Table": "Таблица",
        "Link": "Ссылка",
        "Marker": "Маркер",
        "Bold": "Полужирный",
        "Italic": "Курсив",
        "InlineCode": "Моноширинный",
        "Image": "Изображение",
        "Attachment": "Файл"
    },

    tools: {
        "warning": {
            "Title": "Название",
            "Message": "Сообщение",
        },
        "link": {
            "Add a link": "Вставьте ссылку",
            "Caption": "Подпись",
        },
        "stub": {
            'The block can not be displayed correctly.': 'Блок не может быть отображен'
        },
        "image": {
            "Caption": "Подпись",
            "Select an Image": "Выбрать изображение",
            "With border": "Граница",
            "With background": "Фон",
            "Stretch image": "По ширине"
        },
        "attaches": {
            "Select file to upload": "Выбрать файл для загрузки",
            "File title": "Имя файла",
        }
    },

    blockTunes: {
        "delete": {
            "Delete": "Удалить",
            "Click to delete": "Удалить"
        },
        "moveUp": {
            "Move up": "Переместить вверх"
        },
        "moveDown": {
            "Move down": "Переместить вниз"
        }
    },
}

export default Localization;