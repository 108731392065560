import React from 'react';
import {Select} from "../form/input";
import {H5, H6, H9} from "../fonts/fonts";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {storeType} from "../../../redux/store";
import Consultation, {ConsultationData} from "../../../entities/Consultation";

const SelectContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  
  .mobile & select {
    width: 100%;
  }
`;

const SelectRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  justify-content: start;
  align-items: center;
  
  & h5:nth-child(2) {
    color: ${props => props.theme.color.bl2};
  }
`;

const Color = styled.div<{color: string}>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #${props => props.color};
`;

const Legend = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  justify-content: space-between;
  
  & h6 {
    white-space: normal;
  }
  
  & > div {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
  }
`;

const SelectConsultation = (props: {allowAny?: boolean; cons: Consultation|undefined; setCons: (e: any) => void}) => {
    const consultationsData = useSelector<storeType, ConsultationData[]>((state) => state.consultations);
    const consultations = consultationsData.map(c => new Consultation(c));

    return (
        <SelectContainer>
            <Select header={'Вид обращения: '} input={{value: props.cons?.id, onChange: props.setCons}}>
                {
                    props.allowAny && <option value={0}>Не выбран</option>
                }
                {
                    consultations.map((cons_, i) =>
                        <option key={i} value={cons_.id}>{cons_.title}</option>)
                }
            </Select>
            {
                props.cons ? [
                    <SelectRow key={1}>
                        <H5>Длительность:</H5><H5>{props.cons.duration} мин</H5>
                    </SelectRow>,
                    <SelectRow key={2}>
                        <H5>Цвет:</H5><Color color={props.cons.color} />
                    </SelectRow>
                    ]
                    : (props.allowAny ?
                        consultations.map((cons_, i) =>
                            <Legend key={i}>
                                <H6>{cons_.title}</H6>
                                <div>
                                    <H9>{cons_.duration} мин</H9>
                                    <Color color={cons_.color} />
                                </div>
                            </Legend>)
                        : null)
            }
        </SelectContainer>
    );
};

export default SelectConsultation;
