import React from 'react';
import styled from "styled-components";
import {Images} from "../../../utils/images";
import {ButtonGray, ButtonPU, ButtonWhite} from "../../shared/buttons/buttons";
import {H5, P1} from "../../shared/fonts/fonts";
import {Links, URLs} from "../../../utils/constants";
import ButtonAuth from "../../shared/buttons/ButtonAuth";
import {useDispatch, useSelector} from "react-redux";
import {storeType} from "../../../redux/store";
import {useViewport} from "../../../utils/ViewportContext";
import {SetMenu} from "../../../redux/actions";
import TelegramPink from "../../../images/svgs/icons/TelegramPink";
import User, {UserData} from "../../../entities/User";
import Consultation from "../../../entities/Consultation";

const HeaderStyle = styled.div`
  height: 72px;
  background: ${props => props.theme.color.white};
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content minmax(20px, 70px) auto min(20px) auto;
  align-items: center;
  padding: 0 24px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  z-index: 2;
  position: relative;
  
  .mobile & {
    grid-template-columns: none;
    justify-content: space-between;
  }
`;

const Logo = styled.a`
  margin-top: 3px;

  .mobile &, .mobile & img {
    height: 30px;
    margin-top: 0;
  }
`;

const HeaderButtonStyle = styled.a`
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  
  & > * {
    margin: 0;
  }
  
  &:hover {
    text-decoration: underline;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  grid-gap: clamp(10px, 8% - 20px, 36px);
`;

const ButtonsContainer = styled.div`
  display: flex;
  grid-gap: 14px;
  justify-self: end;
`;

const ButtonMenu = styled(ButtonWhite)`
  padding: 13px;
  background-color: ${props => props.theme.color.pu2};
  border-color: ${props => props.theme.color.pu2};
  
  &.active {
    background: white;
  }
`;

const MenuMobileStyle = styled.div`
  position: fixed;
  overflow: hidden;
  bottom: 0;
  height: calc(100% - 72px);
  z-index: 5;
  background: white;
  padding: 40px 0 14px 0;
  box-sizing: border-box;
  left: -100%;
  transition: left 0.2s ease-in-out;
  
  display: grid;
  align-content: space-between;
  width: 100%;
  
  &.active {
    left: 0;
  }
`;

const MenuMobileButton = styled.a`
  padding: 10px 24px;
  text-decoration: none;
  display: grid;
`;

const MenuMobileBottom = styled.div`
  padding: 0 24px;
  display: grid;
  justify-content: center;
  grid-gap: 10px;
  justify-items: center;
  
  & > div {
    padding: 48px 0 58px 0;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 20px;
    justify-content: center;
    align-items: center;
  }
`;

export const MenuMobile = () => {
    const mobileMenu = useSelector<storeType, boolean>((state) => state.mobileMenu);
    const meData = useSelector<storeType, UserData | null>((state) => state.me);
    const me = meData ? new User(meData) : null;

    return (
        <MenuMobileStyle className={mobileMenu ? 'active' : ''}>
            <div>
                <MenuMobileButton href={URLs.main}><P1>Главная</P1></MenuMobileButton>
                <MenuMobileButton href={URLs.consultations}><P1>Обратиться онлайн</P1></MenuMobileButton>
                <MenuMobileButton href={URLs.about}><P1>Обо мне</P1></MenuMobileButton>
                <MenuMobileButton href={URLs.info}><P1>Полезная информация</P1></MenuMobileButton>
                {
                    me && <MenuMobileButton href={User.linkToView()}><P1>Личный кабинет</P1></MenuMobileButton>
                }
            </div>
            <MenuMobileBottom>
                {
                    me === null && <ButtonAuth />
                }
                {
                    me && me.role.isAdmin() ?
                        <ButtonGray href={URLs.admin}><H5>Панель администратора</H5></ButtonGray>
                        :
                        <ButtonPU href={Consultation.linkToView()}><H5>Обратиться онлайн</H5></ButtonPU>
                }
                <div>
                    <a href="https://prodoctorov.ru/krasnodar/vrach/458594-siuhova/"  target="_blank">
                        <img src="https://prodoctorov.ru/rating/d458594/" title="Рейтинг - Сиюхова З. А." />
                    </a>
                    <a href={Links.telegram}><TelegramPink /></a>
                    {/*<a href={Links.instagram}><InstagramPink /></a>*/}
                </div>
            </MenuMobileBottom>
        </MenuMobileStyle>
    )
}

const Header = () => {
    const viewport = useViewport();
    const meData = useSelector<storeType, UserData | null>((state) => state.me);
    const me = meData ? new User(meData) : null;
    const mobileMenu = useSelector<storeType, boolean>((state) => state.mobileMenu);
    const dispatch = useDispatch();

    const open = () => dispatch(SetMenu(true));
    const close = () => dispatch(SetMenu(false));

    return (
        <HeaderStyle>
            {
                viewport.device === "mobile" ?
                    <>
                        {
                            mobileMenu ?
                                <ButtonMenu className={'active'} onClick={close}>
                                    <img src={Images.icons.menu_pink} />
                                </ButtonMenu>
                                :
                                <ButtonMenu onClick={open}>
                                    <img src={Images.icons.menu} />
                                </ButtonMenu>
                        }
                        <Logo href={URLs.main}><img src={Images.logo}/></Logo>
                    </>
                    :
                    <>
                        <Logo href={URLs.main}><img src={Images.logo}/></Logo>
                        <div />
                        <MenuContainer>
                            <HeaderButtonStyle href={URLs.main}>
                                <H5>Главная</H5>
                            </HeaderButtonStyle>
                            <HeaderButtonStyle href={URLs.consultations}>
                                <H5>Обратиться онлайн</H5>
                            </HeaderButtonStyle>
                            <HeaderButtonStyle href={URLs.info}>
                                <H5>Полезная информация</H5>
                            </HeaderButtonStyle>
                            <HeaderButtonStyle href={URLs.about}>
                                <H5>Обо мне</H5>
                            </HeaderButtonStyle>
                        </MenuContainer>
                        <div />
                        <ButtonsContainer>
                            <ButtonAuth />
                            {
                                me && me.role.isAdmin() ?
                                    <ButtonGray href={URLs.admin}><H5>Панель администратора</H5></ButtonGray>
                                :
                                    <ButtonPU href={Consultation.linkToView()}><H5>Обратиться онлайн</H5></ButtonPU>
                            }
                        </ButtonsContainer>
                    </>
            }
        </HeaderStyle>
    );
};

export default Header;
