import {route} from "./types";
import Main from "../pages/main";
import About from "../pages/about";
import {URLs} from "./constants";
import Info from "../pages/info";
import Auth from "../pages/auth";
import Appointment from "../pages/appointment";
import Chat from "../pages/chat";
import Lk from "../pages/lk";
import Admin from "../pages/admin";
import Article from "../pages/article";
import ArticleEdit from "../pages/articleEdit";
import AppointmentResult from "../pages/appointmentResult";
import Consultations from "../pages/consultations";
import WriteReview from "../pages/writeReview";
import AppointmentView from "../pages/appointmentView";
import {ROLES} from "../entities/Role";

export const routes:route[] = [
    {
        url: URLs.main,
        page: Main,
        role: ROLES.VISITOR
    },
    {
        url: URLs.about,
        page: About,
        role: ROLES.VISITOR
    },
    {
        url: URLs.info,
        page: Info,
        role: ROLES.VISITOR
    },
    {
        url: URLs.auth,
        page: Auth,
        role: ROLES.VISITOR
    },
    {
        url: URLs.appointment,
        page: Appointment,
        role: ROLES.USER
    },
    {
        url: URLs.chat,
        page: Chat,
        role: ROLES.USER
    },
    {
        url: URLs.lk,
        page: Lk,
        role: ROLES.USER
    },
    {
        url: URLs.admin,
        page: Admin,
        role: ROLES.ADMIN
    },
    {
        url: URLs.article,
        page: Article,
        role: ROLES.VISITOR
    },
    {
        url: URLs.articleEdit,
        page: ArticleEdit,
        role: ROLES.USER
    },
    {
        url: URLs.appointmentResult,
        page: AppointmentResult,
        role: ROLES.USER
    },
    {
        url: URLs.consultations,
        page: Consultations,
        role: ROLES.VISITOR
    },
    {
        url: URLs.writeReview,
        page: WriteReview,
        role: ROLES.USER
    },
    {
        url: URLs.appointmentView,
        page: AppointmentView,
        role: ROLES.USER
    },
]
