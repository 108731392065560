import React, {useState} from 'react';
import Template from "../components/template/template";
import styled from "styled-components";
const ClassicEditor = require("@ckeditor/ckeditor5-build-classic");
const CKEditor = require("@ckeditor/ckeditor5-react");

const Editor = styled.div`
  margin: 50px;
`;
const Article = () => {
    const [text, setText] = useState("")
    return (
        <Template>
            <Editor>
                <CKEditor.CKEditor
                    editor={ClassicEditor}
                    data={text}
                    onChange={(event: any, editor: any) => {
                        const data = editor.getData()
                        setText(data)
                    }}
                />
            </Editor>
        </Template>
    )
};

export default Article;