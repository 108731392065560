import React, {useState} from 'react';
import styled from "styled-components";
import {H9} from "../shared/fonts/fonts";
import post from "../../api/post";
import {useSelector} from "react-redux";
import {storeType} from "../../redux/store";
import {useViewport} from "../../utils/ViewportContext";
import {Images} from "../../utils/images";
import User, {UserData} from "../../entities/User";

const UserPhoto = styled.div<{src: string}>`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  
  background: ${props => props.src ? `url("${props.src}"), ` : ''}url("${Images.icons.avatar}");
  background-size: cover;
  background-position: center;

  display: grid;
  grid-template-rows: 60% 40%;
  overflow: hidden;
  
  & > label {
    opacity: 0;
  }
  
  &:hover > label {
    opacity: 1;
  }
`;

const InputStyle = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -2;
  width: inherit;
`;

const Upload = styled.label`
  background-color: ${props => props.theme.color.b1}50;
  cursor: pointer;
  & h6 {
    background-color: ${props => props.theme.color.b1};
    color: ${props => props.theme.color.white};
    border-radius: 10px;
    padding: 0 4px;
  }
  
  &:hover {
    background-color: ${props => props.theme.color.b1}80;
  }
  
  display: grid;
  justify-content: center;
  align-content: end;
  padding-bottom: 5px;
`;

const Delete = styled(Upload)`
  background-color: ${props => props.theme.color.pi2}50;
  & h6 {
    background-color: ${props => props.theme.color.pi2};
  }
  &:hover {
    background-color: ${props => props.theme.color.pi2}80;
  }
  align-content: start;
  padding-top: 5px;
`;

const Photo = () => {
    const viewport = useViewport();
    const [edit, setEdit] = useState(false);

    const token = useSelector<storeType, string|null>((state) => state.token);
    const meData = useSelector<storeType, UserData | null>((state) => state.me);
    const me = meData ? new User(meData) : null;

    const loadImage = (e: any) => {
        if (e.target.files && e.target.files[0] && token) {
            let formData = new FormData();
            formData.set('token', token);
            formData.set('photo', e.target.files[0]);

            post('loadImage', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then()
                .catch(error => console.log(error))
                .finally(() => {
                    setEdit(false);
                });
        }
    }

    const deleteImage = () =>
        post('deleteImage', {token})
            .then(() => {})
            .catch(error => console.log(error))
            .finally(() => {
                setEdit(false);
            });

    return (
        <UserPhoto src={me ? me.avatar() : User.avatar()} onClick={() => setEdit(true)}>
            <InputStyle id={'upload-photo'} onChange={loadImage} type={'file'}/>
            {
                (viewport.device === 'desktop' || edit) &&
                <>
                    <Upload htmlFor={'upload-photo'}><H9>Загрузить</H9></Upload>
                    <Delete onClick={deleteImage}><H9>Удалить</H9></Delete>
                </>
            }
        </UserPhoto>
    );
};

export default Photo;