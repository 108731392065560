import React, {useState} from 'react';
import {H5} from "../../shared/fonts/fonts";
import {Select} from "../../shared/form/input";
import {weekDays} from "../../../utils/constants";
import {
    CellAppointmentStyle, CellBookedStyle,
    CellEmptyStyle,
    CellSlotAddedStyle,
    CellSlotBusyStyle,
    CellSlotStyle,
    CellStyle,
    DayTimetableStyle
} from "../shared/table";
import {timeToString, toLocaleDateStringByMoscow} from "../../../utils/functions";
import Slot from "../../../entities/Slot";
import Consultation from "../../../entities/Consultation";

const opts = [5, 10, 15, 30, 60];
type CellType = typeof Cell.empty |
    typeof Cell.slot |
    typeof Cell.added_slot |
    typeof Cell.busy |
    typeof Cell.appointment |
    typeof Cell.booked;

export class Cell {
    static empty = 0;
    static slot = 1;
    static added_slot = 2;
    static busy = 3;
    static appointment = 4;
    static booked = 5;
    type: CellType;
    slot: Slot | null;
    count: number;
    timestamp: number;
    is_actual: boolean;

    constructor(type: CellType, slot: Slot | null, timestamp: number) {
        this.type = type;
        this.slot = slot;
        this.count = 1;
        this.timestamp = timestamp;

        if (slot) {
            this.is_actual = slot.isActual();
        }
        else if (timestamp) {
            this.is_actual = timestamp > (new Date().getTime() / 1000);
        }
        else {
            this.is_actual = false;
        }
    }

    copyCountUp = () => {
        const cell = new Cell(this.type, this.slot, this.timestamp);
        cell.count = this.count + 1;
        return cell;
    }

    isActualClass = () => this.is_actual ? undefined : 'inActual';

    body = (i: number, functions: functions) =>
        [<CellSlotBusyStyle className={this.isActualClass()} key={i}><td>{timeToString(this.timestamp)}</td></CellSlotBusyStyle>];
}

class CellEmpty extends Cell {
    constructor(timestamp: number) {
        super(Cell.empty, null, timestamp);
    }

    body = (i: number, functions: functions) =>
        [<CellEmptyStyle className={this.isActualClass()} key={i}
                         onClick={() => this.is_actual && functions.addSlot(this.timestamp)}>
            <td>{timeToString(this.timestamp)}</td>
        </CellEmptyStyle>];
}

class CellSlot extends Cell {
    slot: Slot;
    constructor(slot: Slot) {
        super(Cell.slot, slot, slot.timestamp);
        this.slot = slot;
    }

    copyCountUp = () => {
        const cell = new CellSlot(this.slot);
        cell.count = this.count + 1;
        return cell;
    }

    body = (i: number, functions: functions) =>
        [<CellSlotStyle className={this.isActualClass()} key={i} color={this.slot?.consultation.color}
                        onClick={() => this.is_actual && this.slot && functions.deleteSlot(this.slot)}>
            <td rowSpan={this.count}>{timeToString(this.timestamp)}</td>
        </CellSlotStyle>,
            ...[...Array(this.count - 1)].map((a, j) =>
                <CellStyle className={this.isActualClass()} key={i + '.' + j} />)]
}

class CellAddedSlot extends Cell {
    slot: Slot;
    constructor(slot: Slot) {
        super(Cell.added_slot, slot, slot.timestamp);
        this.slot = slot;
    }

    copyCountUp = () => {
        const cell = new CellAddedSlot(this.slot);
        cell.count = this.count + 1;
        return cell;
    }

    body = (i: number, functions: functions) =>
        [<CellSlotAddedStyle className={this.isActualClass()} color={this.slot?.consultation.color} key={i}
                             onClick={() => this.is_actual && functions.deleteAddedSlot(this.timestamp)}>
            <td rowSpan={this.count}>{timeToString(this.timestamp)}</td>
        </CellSlotAddedStyle>,
            ...[...Array(this.count - 1)].map((a, j) =>
                <CellStyle className={this.isActualClass()} key={i+'.'+j} />)];
}

class CellBusy extends Cell {
    constructor(timestamp: number) {
        super(Cell.busy, null, timestamp);
    }
}

class CellAppointment extends Cell {
    slot: Slot;
    constructor(slot: Slot) {
        super(Cell.appointment, slot, slot.timestamp);
        this.slot = slot;
    }

    copyCountUp = () => {
        const cell = new CellAppointment(this.slot);
        cell.count = this.count + 1;
        return cell;
    }

    body = (i: number, functions: functions) =>
        [<CellAppointmentStyle className={this.isActualClass()} color={this.slot?.consultation.color} key={i}>
            <td rowSpan={this.count}>{timeToString(this.timestamp)}</td>
        </CellAppointmentStyle>,
            ...[...Array(this.count - 1)].map((a, j) =>
                <CellStyle className={this.isActualClass()} key={i+'.'+j} />)]
}

class CellBooked extends Cell {
    slot: Slot;
    constructor(slot: Slot) {
        super(Cell.booked, slot, slot.timestamp);
        this.slot = slot;
    }

    copyCountUp = () => {
        const cell = new CellBooked(this.slot);
        cell.count = this.count + 1;
        return cell;
    }

    body = (i: number, functions: functions) =>
        [<CellBookedStyle className={this.isActualClass()} color={this.slot?.consultation.color} key={i}>
            <td rowSpan={this.count}>{timeToString(this.timestamp)}</td>
        </CellBookedStyle>,
            ...[...Array(this.count - 1)].map((a, j) =>
                <CellStyle className={this.isActualClass()} key={i+'.'+j} />)];
}

interface functions {
    addSlot: (ts: number) => void,
    deleteAddedSlot: (ts: number) => void,
    deleteSlot: (slot: Slot) => void
}

export const getCell = (preCell: Cell | null, addedSlots: Slot[], slots: Slot[], consultation: Consultation,
                        timestamp: number) => {
    const slot_ = slots.find(s =>
        ((s.timestamp <= timestamp) && (s.timestamp + s.consultation.duration * 60 > timestamp)));
    if (slot_) {
        if (preCell && (preCell.type === Cell.slot || preCell.type === Cell.appointment || preCell.type === Cell.booked)
            && preCell.slot?.timestamp === slot_.timestamp) {
            return preCell.copyCountUp();
        }
        else {
            if (slot_.isPaid()) {
                return new CellAppointment(slot_);
            }
            else if (slot_.isBooked()) {
                return new CellBooked(slot_);
            }
            else {
                return new CellSlot(slot_);
            }
        }
    }

    const slot_added = addedSlots.find(s =>
        ((s.timestamp <= timestamp) && (s.timestamp + s.consultation.duration * 60 > timestamp)));
    if (slot_added) {
        if (preCell && preCell.type === Cell.added_slot && preCell.slot?.timestamp === slot_added.timestamp) {
            return preCell.copyCountUp();
        }
        else {
            return new CellAddedSlot(slot_added);
        }
    }

    const busy = slots.find(s =>
        ((s.timestamp > timestamp) && (s.timestamp - consultation.duration * 60 < timestamp)));
    if (busy) {
        return new CellBusy(timestamp);
    }

    const busy_by_added = addedSlots.find(s =>
        ((s.timestamp > timestamp) && (s.timestamp - consultation.duration * 60 < timestamp)));
    if (busy_by_added) {
        return new CellBusy(timestamp);
    }

    return new CellEmpty(timestamp);
}

const DayTimetable = (props: {date: Date, addedSlots: Slot[], slots: Slot[], cons: Consultation, functions: functions
}) => {
    const [period, setPeriod_] = useState(opts[0]);
    const setPeriod = (e: any) => setPeriod_(parseInt(e.target.value));

    const startTS = props.date.getTime() / 1000;
    const cells:Cell[] = [];
    let i_ = 0;
    while (i_ < 24 * 60) {
        const cell = getCell(cells.at(-1) || null, props.addedSlots, props.slots, props.cons,
            startTS + i_ * 60);
        if (cell.count > 1) {
            cells.pop();
        }
        cells.push(cell);
        i_ += period;
    }

    return (
        <DayTimetableStyle>
            <H5>{toLocaleDateStringByMoscow(props.date).slice(0, 5)}, {weekDays[(props.date.getDay() + 6) % 7]}</H5>
            <Select header={'Период, мин.:'} input={{value: period, onChange: setPeriod}}>
                {
                    opts.map((opt, i) => <option key={i} value={opt}>{opt}</option>)
                }
            </Select>
            <table>
                <tbody>
                {
                    cells.map((c, i) => c.body(i, props.functions))
                }
                </tbody>
            </table>
        </DayTimetableStyle>
    );
};

export default DayTimetable;
