import React, {useEffect, useState} from 'react';
import {adminArticle} from "../../../utils/types";
import {BlocksStyle, BlockStyle, Button, ButtonVisible, ColumnButtons, Header} from "./shared";
import {H5} from "../../shared/fonts/fonts";
import styled from "styled-components";
import {InputText} from "../../shared/form/input";
import {URLs} from "../../../utils/constants";

const Text = styled.div`
  display: grid;
  grid-gap: 10px;
`;

const Article = (props: {data: adminArticle, updateArticlesList: () => void, order_i: number, articles: adminArticle[]}) => {
    const [title, setTitle_] = useState(props.data.title);
    const [deleting, setDeleting] = useState(false);

    const setTitle = (e: any) => setTitle_(e.target.value);

    const setVisibility = () => {
        /*post('setArticleVisible', {token, article_id: props.data.id, visibility: 1 - props.data.visible})
            .then(props.updateArticlesList)
            .catch();*/
    }

    const changed = title !== props.data.title;

    const save = () => {};
        /*post('editArticle', {token, article_id: props.data.id, title})
            .then(() => {
                props.updateArticlesList();
            })
            .catch();*/

    const cancel = () => {
        setTitle_(props.data.title);
    }

    const deleteLink = () => setDeleting(true);
    const cancelDeleteLink = () => setDeleting(false);
    const sureDeleteLink = () => {};
        /*post('deleteArticle', {token, article_id: props.data.id})
            .then(() => {
                props.updateArticlesList();
            })
            .catch();*/

    const setOrder = (vector: number) => {
        //const changeWith = props.articles.at(props.order_i + vector);
        /*changeWith && post('setArticleOrder', {token, article_id: props.data.id, order_id: changeWith.order_id})
            .then(() =>
                post('setArticleOrder', {token, article_id: changeWith.id, order_id: props.data.order_id})
                    .then(() => {
                        props.updateArticlesList();
                    })
                    .catch())
            .catch();*/
    }

    return (
        <BlockStyle>
            <Header className={props.data.visible === 1 ? 'visible' : ''}>
                <ButtonVisible className={(props.data.visible === 0 && (!props.data.title || !props.data.date)) ? 'disabled' : ''} onClick={setVisibility}>
                    <H5>{props.data.visible === 0 ? 'Сделать видимым' : 'Сделать невидимым'}</H5>
                </ButtonVisible>
                <Button onClick={() => setOrder(-1)} className={props.order_i === 0 ? 'disabled' : ''}>
                    <H5>▲</H5>
                </Button>
                <Button onClick={() => setOrder(1)} className={props.order_i === props.articles.length - 1 ? 'disabled' : ''}>
                    <H5>▼</H5>
                </Button>
                <Button onClick={save} className={changed ? '' : 'disabled'}>
                    <H5>Сохранить изменения</H5>
                </Button>
                <Button onClick={cancel} className={changed ? '' : 'disabled'}>
                    <H5>Отменить изменения</H5>
                </Button>
                <Button href={URLs.articleEdit + '?article=' + props.data.id} target={'_blank'}>
                    <H5>Перейти к редактированию</H5>
                </Button>
                {
                    deleting ?
                        <ColumnButtons>
                            <Button onClick={sureDeleteLink}>
                                <H5>Удалить</H5>
                            </Button>
                            <Button onClick={cancelDeleteLink}>
                                <H5>Отмена</H5>
                            </Button>
                        </ColumnButtons>
                        :
                        <Button onClick={deleteLink}>
                            <H5>Удалить</H5>
                        </Button>
                }
            </Header>
            <Text>
                <InputText input={{value: title, onInput: setTitle, style: {borderWidth: '2px', borderStyle: 'dashed'},
                    placeholder: 'Введите название'}} />
            </Text>
        </BlockStyle>
    )
}

const Articles = () => {
    const [articles, setArticles] = useState<adminArticle[]>([]);

    const updateArticlesList = () => {
        setArticles([]);
        /*post('getArticlesList', {token})
            .then(result => setArticles(result.data.articles))
            .catch();*/
    }

    useEffect(() => {
        updateArticlesList();
    }, []);

    const create = () => {};
        /*post('createArticle', {token})
            .then(updateArticlesList)
            .catch();*/

    return (
        <BlocksStyle>
            <Button onClick={create}><H5>Создать статью</H5></Button>
            {articles.map((a, i) =>
                <Article order_i={i} articles={articles} updateArticlesList={updateArticlesList} key={i} data={a} />)}
        </BlocksStyle>
    );
};

export default Articles;