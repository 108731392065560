import React from 'react';

const FooterMobileCenter = () => {
    return (
        <svg width="326" height="287" viewBox="0 0 326 287" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.21275 109.649C18.4878 56.9606 63.4858 22.9399 117.093 12.532C189.962 -1.61567 281.848 -16.1601 315.387 49.622C350.329 118.156 292.069 190.941 233.48 240.809C183.654 283.218 114.25 302.205 57.2674 269.319C3.37222 238.215 -7.76989 169.666 6.21275 109.649Z" fill="#ACA1EC"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.21275 109.649C18.4878 56.9606 63.4858 22.9399 117.093 12.532C189.962 -1.61567 281.848 -16.1601 315.387 49.622C350.329 118.156 292.069 190.941 233.48 240.809C183.654 283.218 114.25 302.205 57.2674 269.319C3.37222 238.215 -7.76989 169.666 6.21275 109.649Z" fill="url(#pattern_footer_mobile)" fillOpacity="0.5"/>
            <defs>
                <pattern id="pattern_footer_mobile" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref="#image_gradient2" transform="matrix(0.000401638 0 0 0.000390625 -0.0140971 0)"/>
                </pattern>
            </defs>
        </svg>
    );
};

export default FooterMobileCenter;