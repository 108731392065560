import React, {UIEventHandler, useEffect, useState} from 'react';
import styled from "styled-components";
import {H5, H9, P2} from "../shared/fonts/fonts";
import {Images} from "../../utils/images";
import {useDispatch, useSelector} from "react-redux";
import store, {storeType} from "../../redux/store";
import {getMessagesHistory} from "../../api/methods/messages/get/getMessagesHistory";
import {InputTextArea} from "../shared/form/input";
import {ChooseChat, ClearMessages} from "../../redux/actions";
import post from "../../api/post";
import {setWraps} from "../../utils/functions";
import {signAtNewMessages} from "../../api/methods/messages/get/signAtNewMessages";
import InfoCard from "./InfoCard";
import ReviewsArrow from "../../images/svgs/icons/ReviewsArrow";
import {useViewport} from "../../utils/ViewportContext";
import MessageClass, {MessageData} from "../../entities/Message";
import User, {UserData} from "../../entities/User";
import {seen} from "../../api/methods/messages/update/seen";
import {updateChat} from "../../api/methods/messages/get/getChats";

const UserPhoto = styled.div<{src: string}>`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  
  background: ${props => props.src ? `url("${props.src}"), ` : ''}url("${Images.icons.avatar}");
  background-size: cover;
  background-position: center;
`;

const MessageOutcomeText = styled.div<{seen: 0|1|2}>`
  border-radius: 16px 16px 0 16px;
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  padding: 16px;
  position: relative;

  border-left: 2px solid white;
  transition: border-left-color 0.2s linear;
  
  width: fit-content;

  &:after {
    content: "";
    position: absolute;
    height: 24px;
    width: 24px;
    right: calc(100% + 10px);
    bottom: 0;
    background-image: url("${props => props.seen === 2 ? Images.icons.seen : Images.icons.unSeen}");
    opacity: ${props => props.seen === 1 ? 0 : 1};
  }
`;

const MessageIncomeText = styled(MessageOutcomeText)`
  border-radius: 16px 16px 16px 0;
  background: ${props => props.theme.color.bb2};
`;

const MessageIncomeStyle = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 14px;
  justify-self: start;
  max-width: 66%;
  margin-left: 16px;
`;

const MessageOutcomeStyle = styled.div`
  display: grid;
  grid-gap: 8px;
  max-width: 66%;
  margin-right: 24px;
  justify-self: end;
  justify-items: end;
`;

const HeaderAndText = styled.div`
  display: grid;
  grid-gap: 8px;
  
  & > h5 {
    white-space: normal;
  }
`;

const Message = (props: MessageClass) => {
    const Style = props.admin ? MessageIncomeText : MessageOutcomeText;

    return (
        <Style className={'message'} seen={props.seen} data-id={String(props.id)}>
            {
                props.slot && <InfoCard slot={props.slot} />
            }
            <P2>{setWraps(props.text)}</P2>
        </Style>
    );
}

const MessageUserBlock = (props: {messages: MessageClass[]; currentChat: User}) => {
    return (
        <MessageIncomeStyle>
            <UserPhoto src={props.currentChat.avatar()} />
            <HeaderAndText>
                <H5>
                    {[props.currentChat.lastname,
                        props.currentChat.firstname,
                        props.currentChat.fatherName].join(' ')}
                </H5>
                {
                    props.messages.map((m, i) =>
                        <Message key={i} {...m} />
                    )
                }
            </HeaderAndText>
        </MessageIncomeStyle>)
}

const MessageMyBlock = (props: {messages: MessageClass[]}) => {
    return (
        <MessageOutcomeStyle>
            <H5>Вы</H5>
            {
                props.messages.map((m, i) =>
                    <Message key={i} {...m} />
                )
            }
        </MessageOutcomeStyle>)
}

const MessagesStyle = styled.div`
  background: ${props => props.theme.color.dpu5};
  display: grid;
  grid-template-rows: 1fr auto;
  position: relative;
  z-index: 3;
`;

const MessagesScroll = styled.div`
  position: relative;
  padding: 48px 0;
  overflow-y: scroll;
  display: grid;
  align-items: end;
  width: 100%;
`;

const MessagesContainer = styled.div`
  display: grid;
  align-content: start;
  grid-gap: 18px;
`;

const SendForm = styled.div`
  border-radius: 16px;
  background: white;
  margin: 0 24px 16px 16px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: auto min-content;
  grid-gap: 10px;

  & > div > div {
    width: 100%;
  }
  
  & textarea {
    border: 0;
    resize: vertical;
    overflow-y: hidden;
    width: 100%;
    min-height: 90px;
    height: 90px;
  }
`;

interface messageBlock {
    admin: boolean;
    messages: MessageClass[]
}

const SendButton = styled.button`
  background: none;
  cursor: pointer;
  border: 0;
  padding: 16px;
  
  & div {
    background: ${props => props.theme.color.pu1};;
    border-radius: 50%;
    height: 36px;
  }
  
  &:disabled {
    cursor: auto;
    opacity: 0.7;
  }
  
  &.sending img {
    transform: rotate(-90deg);
    animation: sending 0.3s infinite ease-in-out;
  }

  @keyframes sending {
    0% {opacity: 1}
    50% {opacity: 0}
  }
`;

const DialogsList = styled.div`
  padding: 12px;
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  height: min-content;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  grid-gap: 10px;
  
  & svg {
    transform: rotate(180deg);
    height: 30px;
  }
`;

const MESSAGES_COUNT = 10;

const Messages = () => {
    const viewport = useViewport();
    const meData = useSelector<storeType, UserData | null>((state) => state.me);
    const me = meData ? new User(meData) : null;

    const currentChatData = useSelector<storeType, UserData | null>((state) => state.currentChat);
    const currentChat = currentChatData ? new User(currentChatData) : null;

    const [sending, setSending] = useState(false);
    const [text, setText_] = useState('');

    const lastSeenMessageId = useSelector<storeType, number>((state) => state.lastSeenMessageId);

    const messagesData = useSelector<storeType, MessageData[]>((state) => state.messages);
    const messages = messagesData.map(m => new MessageClass(m, lastSeenMessageId));
    //const messagesLoading = useSelector<storeType, boolean>((state) => state.messagesLoading);
    const [messagesFinished, setMessagesFinished] = useState(false);
    const [focusMessage, setFocusMessage] = useState<MessageClass|null>(null);
    const [baseMessage, setBaseMessage] = useState<MessageClass|null>(null);
    const [baseMessageTop, setBaseMessageTop] = useState<number>(0);

    const [scrollTop, setScrollTop] = useState(0);

    const [messageBlocks, setMessageBlocks] = useState<messageBlock[]>([]);

    const [controller, setController] = useState<AbortController>(new AbortController());

    const dispatch = useDispatch();

    const setText = (e: any) => setText_(e.target.value);

    const abort = () => {
        controller.abort();
        setController(new AbortController());
    }

    /*useEffect(() => {
        //console.log(focusMessage);

    }, [focusMessage]);*/

    useEffect(() => {
        if (currentChatData && meData) {
            setText_('');
            abort();
            setMessagesFinished(false);
            dispatch(ClearMessages());
            setBaseMessage(null);
        }
    }, [currentChatData, meData]);

    useEffect(() => {
        if (currentChat) {
            getMessagesHistory(0, MESSAGES_COUNT, currentChat, controller)
                .then(() =>
                    signAtNewMessages(currentChat, controller));
        }
    }, [controller]);

    /*useEffect(() => {
        console.log(baseMessage, focusMessage);
    }, [baseMessage, focusMessage]);*/

    useEffect(() => {
        if (meData && currentChat && focusMessage && focusMessage.id > lastSeenMessageId) {
            seen(focusMessage)
                .then(() => me && me.role.isAdmin() && updateChat(currentChat))
                .catch(error => console.log(error));
        }
    }, [meData, currentChatData, lastSeenMessageId, focusMessage]);

    useEffect(() => {
        const messageBlocks_:messageBlock[] = [];
        messages.map(m => {
            const lastBlock = messageBlocks_.at(-1);
            const income = me && me.role.isAdmin() ? !m.admin : m.admin;

            if (lastBlock && income === lastBlock.admin) {
                lastBlock.messages.push(m);
            }
            else {
                messageBlocks_.push({admin: income, messages: [m]});
            }
        });
        setMessageBlocks(messageBlocks_);
    }, [messagesData]);

    useEffect(() => {
        if (messageBlocks.length > 0) {
            const lastMessage = messages.at(-1);
            if (baseMessage === null) {
                lastMessage && setBaseMessage(lastMessage);
            }
            else if (baseMessage && focusMessage && baseMessage.id <= focusMessage.id) {
                lastMessage && setBaseMessage(lastMessage);
            }
            else {
                focus(baseMessageTop);
            }
        }
        else {
            setBaseMessage(null);
        }
    }, [messageBlocks]);

    useEffect(() => {
        if (currentChat && focusMessage !== null) {
            const messages_container = document.getElementsByClassName('messages-scroll')[0].getBoundingClientRect();
            const top_cri = messages_container.top + messages_container.height;

            const messages_ = document.getElementsByClassName('message');
            let focus_message_id_string: null | string = null;
            for (let i = 0; i < messages_.length; i++) {
                const m_ = messages_.item(i);
                const m_rect = m_?.getBoundingClientRect();
                if (m_ && m_rect && m_rect.top + m_rect.height < top_cri) {
                    focus_message_id_string = m_.getAttribute('data-id');
                } else {
                    break;
                }
            }
            if (focus_message_id_string) {
                const focus_message_id = parseInt(focus_message_id_string);
                const fm = messages.find(m => m.id === focus_message_id);
                fm && setFocusMessage(fm);
            }

            if (baseMessage) {
                const message = document.querySelector(`.message[data-id="${baseMessage.id}"]`);
                const offset = (message as any).offsetTop;
                setBaseMessageTop(offset);
            }

            if (scrollTop === 0 && messages.length) {
                const firstMessage = messages.at(0);
                if (firstMessage && !messagesFinished) {
                    getMessagesHistory(firstMessage.id, MESSAGES_COUNT, currentChat, controller)
                        .then(count => {
                            if (count === 0) {
                                setMessagesFinished(true);
                            }
                        });
                }
            }
        }
    }, [scrollTop]);

    useEffect(() => {
        focus(0);
        setFocusMessage(baseMessage);
        setBaseMessageTop(0);
    }, [baseMessage]);

    const focus = (top: number) => {
        if (baseMessage) {
            const messages_container = document.getElementsByClassName('messages-scroll')[0];
            const message = document.querySelector(`.message[data-id="${baseMessage.id}"]`);
            if (message) {
                if (top) {
                    const offset = (message as any).offsetTop;
                    messages_container.scrollTo({
                        top: messages_container.scrollTop + offset - top,
                        left: 0,
                        behavior: "auto"
                    });
                }
                else {
                    messages_container.scrollTo({
                        top: messages_container.scrollHeight,
                        left: 0,
                        behavior: "auto"
                    });
                    const offset = (message as any).offsetTop;
                    setBaseMessageTop(offset);
                }
            }
        }
    };

    const send = () => {
        if (currentChat) {
            setSending(true);
            post('sendMessage', {
                token: store.getState().token,
                text: text,
                to_user_id: currentChat.id
            })
                .then(() => setText_(''))
                .catch(error => console.log(error))
                .finally(() => setSending(false));
        }
    }

    const scroll:UIEventHandler<HTMLDivElement> = (e) =>
        setScrollTop(e.currentTarget.scrollTop);

    const toDialogsList = () => {
        dispatch(ChooseChat(null));
        //getChats();
    }

    return (
        <MessagesStyle>
            {
                me && me.role.isAdmin() && viewport.device === 'mobile' && <DialogsList onClick={toDialogsList}><ReviewsArrow />К списку диалогов</DialogsList>
            }
            <MessagesScroll className={'messages-scroll'} onScroll={scroll}>
                <MessagesContainer>
                    {
                        currentChat ?
                            (messageBlocks.length === 0 &&
                                <center><H9>Здесь пока нет сообщений</H9></center>)
                            : <center><H9>Чтобы увидеть сообщения, выберите диалог</H9></center>
                    }
                    {
                        currentChat && messageBlocks.map((b, i) =>
                            b.admin ?
                                <MessageUserBlock messages={b.messages} currentChat={currentChat} key={i} />
                                :
                                <MessageMyBlock messages={b.messages} key={i} />)
                    }
                </MessagesContainer>
            </MessagesScroll>
            <SendForm>
                <InputTextArea input={{placeholder: 'Введите текст сообщения...', value: text, onInput: setText}} />
                <SendButton onClick={send}
                            disabled={sending || text.length === 0 || !currentChat}
                            className={sending ? 'sending' : ''}>
                    <div>
                        <img src={Images.icons.send} />
                    </div>
                </SendButton>
            </SendForm>
        </MessagesStyle>
    );
};

export default Messages;
