import React from "react";

export const toLocaleStringByMoscow = (date: Date) => date.toLocaleString("ru", {timeZone: "Europe/Moscow"});
export const toLocaleTimeStringByMoscow = (date: Date) => date.toLocaleTimeString("ru", {timeZone: "Europe/Moscow"});
export const toLocaleDateStringByMoscow = (date: Date) => date.toLocaleDateString("ru", {timeZone: "Europe/Moscow"});

export const timeToString = (timestamp: number) =>
    toLocaleTimeStringByMoscow(new Date(timestamp * 1000)).slice(0, 5);

export const processPhone = (phone: string) => {
    let val:string = phone;
    //if (/^\+7(\s(\([0-9]{1,2}|\([0-9]{3}\)(\s([0-9]{2,3}-)*[0-9]+)?))?$/.test(val)) {
    if (val.length === 0) {
        val = '7';
    }
    else if (val[0] !== '7') {
        val = '7' + val;
    }

    let newVal = '+' + val[0];
    if (val.length > 1) {
        newVal += ' (' + val.slice(1, 4);
        if (val.length > 4) {
            newVal += ') ' + val.slice(4, 7);
            if (val.length > 7) {
                newVal += '-' + val.slice(7, 9);
                if (val.length > 9) {
                    newVal += '-' + val.slice(9);
                }
            }
        }
    }
    return newVal;
}

export const setWraps = (text: string) => {
    const d_ = text.split('\n');
    const res:any = [d_[0]];
    for (let i = 1; i < d_.length; i++) {
        res.push(<br key={i}/>);
        res.push(d_[i]);
    }
    return res;
}