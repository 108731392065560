import React from 'react';
import styled from "styled-components";
import {H6} from "../shared/fonts/fonts";
import {Images} from "../../utils/images";

const RowStyle = styled.div`
  display: grid;
  grid-gap: 6px;

  & h5 {
    white-space: normal;
  }
  
  & a {
    text-decoration: none;
  }
`;

const Header = styled.div`
  & h6 span {
    opacity: 0.5;
    white-space: normal;
  }
  
  & img {
    margin-left: 8px;
  }
`;
const InfoRow = (props: {header: string, required?: boolean, children: any}) => {
    return (
        <RowStyle>
            <Header><H6><span>{props.header}</span>{props.required && <img src={Images.icons.required} />}</H6></Header>
            <div>
                {props.children}
            </div>
        </RowStyle>
    );
};

export default InfoRow;
