import User, {UserData} from "./User";
import Consultation, {SlotConsultationData} from "./Consultation";
import get from "../api/get";
import {URLs} from "../utils/constants";

export interface SlotData {
    id: number,
    timestamp: number,
    consultation: SlotConsultationData,
    paid?: SLOT_PAYMENT_STATUS,
    booking_timestamp?: number,
    user?: UserData;
}

class Slot {
    id: number;
    timestamp: number;
    consultation: Consultation;
    consultation_id: number;
    private paid: SLOT_PAYMENT_STATUS;
    booking_timestamp: null|number;
    user: null|User;

    constructor(data: SlotData) {
        this.id = data.id;
        this.timestamp = data.timestamp;
        this.consultation = Consultation.get(data.consultation.id);
        this.consultation_id = data.consultation.id;
        this.paid = data.paid || SLOT_PAYMENT_STATUS_FREE;
        this.booking_timestamp = data.booking_timestamp ? data.booking_timestamp : null;
        this.user = data.user ? new User(data.user) : null;
    }

    isFree = () => this.paid === SLOT_PAYMENT_STATUS_FREE;
    isBooked = () => this.paid === SLOT_PAYMENT_STATUS_BOOKED;
    isPaid = () => this.paid === SLOT_PAYMENT_STATUS_PAID;
    isActual = () => this.timestamp > (new Date()).getTime() / 1000;

    getValue:() => SlotData = () => {
        return {
            id: this.id,
            timestamp: this.timestamp,
            consultation: {
                id: this.consultation.id
            },
            paid: this.paid,
            booking_timestamp: this.booking_timestamp || undefined,
            user: this.user?.getValue() || undefined
        }
    }

    static getByID = (id: number) =>
        new Promise((resolve: (slot: SlotData) => void, reject: (error: any) => void) => {
            get(`slots/${id}`)
                .then(result =>
                    resolve(result.data.slot))
                .catch(reject)
        });

    static linkToView = (slot_id?: number) => URLs.appointmentView.replace(':id', slot_id ? String(slot_id) : '');
    linkToView = () => Slot.linkToView(this.id);
}

export default Slot;

export const SLOT_PAYMENT_STATUS_FREE = 0;
export const SLOT_PAYMENT_STATUS_BOOKED = 1;
export const SLOT_PAYMENT_STATUS_PAID = 2;

type SLOT_PAYMENT_STATUS = typeof SLOT_PAYMENT_STATUS_FREE | typeof SLOT_PAYMENT_STATUS_BOOKED | typeof SLOT_PAYMENT_STATUS_PAID;