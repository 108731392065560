import {reg_data} from "../utils/types";
import {SlotData} from "../entities/Slot";
import {UserData} from "../entities/User";
import {MessageData} from "../entities/Message";
import {ConsultationData} from "../entities/Consultation";

export const AuthDataType = 'SET_AUTH_DATA';
interface AuthDataAction {
    type: typeof AuthDataType,
    payload: {
        token: string
    }
}
export const setAuthData = (token: string) => ({
    type: AuthDataType,
    payload: {
        token: token
    }
});

export const LogoutType = 'LOGOUT';
interface LogoutAction {
    type: typeof LogoutType
}
export const Logout = () => ({
    type: LogoutType
});


export const RegDataType = 'SET_REG_DATA';
interface RegDataAction {
    type: typeof RegDataType,
    payload: reg_data
}
export const setRegData = (data: reg_data) => ({
    type: RegDataType,
    payload: data
});
/*export const setRegDataPhone = (phone: string) => ({
    type: RegDataType,
    payload: {
        phone: phone.replace(/[^0-9]/g, '')
    }
});*/

export const ClearRegDataType = 'CLEAR_REG_DATA';
interface ClearRegDataAction {
    type: typeof ClearRegDataType
}
export const clearRegData = () => ({
    type: ClearRegDataType
});



export const MeType = 'SET_ME_DATA';
interface SetMeAction {
    type: typeof MeType,
    payload: UserData
}
export const setMe = (user: UserData) => ({
    type: MeType,
    payload: user
});



export const SlotsType = 'SET_SLOTS';
interface SlotsAction {
    type: typeof SlotsType,
    payload: SlotData[]
}
export const setSlots = (slots: SlotData[]) => ({
    type: SlotsType,
    payload: slots
});

export const SlotsLoadingType = 'SET_SLOTS_LOADING';
interface SlotsLoadingAction {
    type: typeof SlotsLoadingType
}
export const setSlotsLoading = () => ({
    type: SlotsLoadingType
});

export const CopySlotsType = 'COPY_SLOTS';
interface CopySlotsAction {
    type: typeof CopySlotsType,
    payload: SlotData[]
}
export const copySlots = (slots: SlotData[]) => ({
    type: CopySlotsType,
    payload: slots
});



export const ChooseSlotType = 'CHOOSE_SLOT';
interface ChooseSlotAction {
    type: typeof ChooseSlotType,
    payload: SlotData|null
}
export const ChooseSlot = (slot: SlotData|null) => ({
    type: ChooseSlotType,
    payload: slot
});

export const SetMenuType = 'SET_MENU';
interface SetMenuAction {
    type: typeof SetMenuType,
    payload: boolean
}
export const SetMenu = (value: boolean) => ({
    type: SetMenuType,
    payload: value
});



export const ChooseChatType = 'CHOOSE_CHAT';
interface ChooseChatAction {
    type: typeof ChooseChatType,
    payload: UserData|null
}
export const ChooseChat = (chat: UserData|null) => ({
    type: ChooseChatType,
    payload: chat
});

export const AddChatsType = 'ADD_CHATS';
interface AddChatsAction {
    type: typeof AddChatsType,
    payload: UserData[]
}
export const AddChats = (chats: UserData[]) => ({
    type: AddChatsType,
    payload: chats
});

export const UpdateChatType = 'UPDATE_CHAT';
interface UpdateChatAction {
    type: typeof UpdateChatType,
    payload: UserData
}
export const UpdateChat = (chat: UserData) => ({
    type: UpdateChatType,
    payload: chat
});

export const ClearMessagesType = 'CLEAR_MESSAGES';
interface ClearMessagesAction {
    type: typeof ClearMessagesType
}
export const ClearMessages = () => ({
    type: ClearMessagesType
});

export const PushMessagesType = 'PUSH_MESSAGES';
interface PushMessagesAction {
    type: typeof PushMessagesType,
    payload: MessageData[]
}
export const PushMessages = (messages: MessageData[]) => ({
    type: PushMessagesType,
    payload: messages
});

export const SetLastSeenMessageIdType = 'SET_LAST_SEEN_MESSAGE_ID';
interface SetLastSeenMessageIdAction {
    type: typeof SetLastSeenMessageIdType,
    payload: number
}
export const SetLastSeenMessageId = (id: number) => ({
    type: SetLastSeenMessageIdType,
    payload: id
});

export const ShiftMessagesType = 'SHIFT_MESSAGES';
interface ShiftMessagesAction {
    type: typeof ShiftMessagesType,
    payload: MessageData[]
}
export const ShiftMessages = (messages: MessageData[]) => ({
    type: ShiftMessagesType,
    payload: messages
});

export const MessagesLoadingType = 'SET_MESSAGES_STATUS';
interface MessagesLoadingAction {
    type: typeof MessagesLoadingType,
    payload: boolean
}
export const setMessagesLoading = (loading: boolean) => ({
    type: MessagesLoadingType,
    payload: loading
});



export const ConsultationsType = 'SET_CONSULTATIONS';
interface ConsultationsAction {
    type: typeof ConsultationsType,
    payload: ConsultationData[]
}
export const setConsultations = (consultations: ConsultationData[]) => ({
    type: ConsultationsType,
    payload: consultations
});

export const ConsultationsLoadingType = 'SET_CONSULTATIONS_LOADING';
interface ConsultationsLoadingAction {
    type: typeof ConsultationsLoadingType
}
export const setConsultationsLoading = () => ({
    type: ConsultationsLoadingType
});


export type action = AddChatsAction | UpdateChatAction | PushMessagesAction | ClearMessagesAction | SetMenuAction
    | ShiftMessagesAction | ChooseChatAction | AuthDataAction | MessagesLoadingAction
    | LogoutAction | RegDataAction | ClearRegDataAction
    | SetMeAction | SlotsAction | SlotsLoadingAction | ChooseSlotAction | CopySlotsAction
    | ConsultationsAction | ConsultationsLoadingAction
    | SetLastSeenMessageIdAction;
