import React from 'react';
import {H2, H5} from "../shared/fonts/fonts";
import {Images} from "../../utils/images";
import styled from "styled-components";
import {useViewport} from "../../utils/ViewportContext";

const TopContainer = styled.div`
  position: relative;
  display: grid;

  &.desktop {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
  }
`;

const RightTop = styled.img`
  position: absolute;
  right: 0;
  top: 0;
`;

const MainPhoto = styled.img`
  position: relative;
  margin: 60px 0 72px 148px;
`;

const InfoContainer = styled.div`
  display: grid;
  grid-gap: 54px;
  align-content: center;
  grid-template-rows: 1fr auto auto auto 1.22fr;
  margin: 0 24px;
  
  .mobile & h2 {
    white-space: normal;
  }
  
  .mobile & {
    grid-template-rows: auto auto auto 1.22fr;
  }
`;

const ImageContainer = styled.div`
  justify-self: end;
`;

const Items = styled.div`
  display: grid;
  grid-gap: 20px;
`;

const Item = styled(H5)`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-gap: 12px;
  color: ${props => props.theme.color.pu1};
`;

const Top = () => {
    const viewport = useViewport();

    return (
        <TopContainer className={viewport.device}>
            {
                viewport.device === 'tablet' && <ImageContainer>
                    <RightTop src={Images.clouds.info.rightTop} />
                    <MainPhoto src={Images.photos.info} />
                </ImageContainer>
            }
            <InfoContainer>
                <div />
                <H2>Полезная информация</H2>
                <Items>
                    <Item><img src={Images.icons.articles} />Статьи</Item>
                    <Item><img src={Images.icons.links} />Ссылки</Item>
                    <Item><img src={Images.icons.video} />Видео</Item>
                </Items>
                <div />
            </InfoContainer>
            {
                viewport.device === 'desktop' && <ImageContainer>
                    <RightTop src={Images.clouds.info.rightTop} />
                    <MainPhoto src={Images.photos.info} />
                </ImageContainer>
            }
        </TopContainer>
    );
};

export default Top;
