import React, {useEffect, useState} from 'react';
import {pageProps} from "../../pages/auth";
import post from "../../api/post";
import Form from "../shared/form/form";
import {H2, H5, P1} from "../shared/fonts/fonts";
import {InputText} from "../shared/form/input";
import {ButtonPU} from "../shared/buttons/buttons";
import Error from "./Error";
import styled from "styled-components";
import store from "../../redux/store";

const Fields = styled.div`
  display: grid;
  grid-gap: 22px;
  width: 100%;
`;

const Buttons = styled.div`
  display: grid;
  grid-gap: 20px;
  justify-items: center;
`;

const NewPassword = (props: pageProps) => {
    const [password1, setPassword1_] = useState('');
    const [pass1Status, setPass1Status] = useState<string|null>(null);
    const [password2, setPassword2_] = useState('');
    const [pass2Status, setPass2Status] = useState<string|null>(null);
    const statuses = [pass1Status, pass2Status];

    const [buttonActive, setButtonActive] = useState(false);
    const [error, setError] = useState<string|null>(null);

    const setPassword1 = (e: any) => {
        if (e.target.value.length > 0) {
            setPass1Status('success');
        }
        else {
            setPass1Status(null);
        }
        setPassword1_(e.target.value);
    }

    const setPassword2 = (e: any) => {
        if (e.target.value.length > 0) {
            if (e.target.value === password1) {
                setPass2Status('success');
            }
            else {
                setPass2Status('error');
            }
        }
        else {
            setPass2Status(null);
        }
        setPassword2_(e.target.value);
    }

    const next = () => {
        post('newPassword', {token: store.getState().regData.recoverToken, password: password1})
            .then(() => {
                props.setPage('success', {text: 'Пароль успешно изменен!'});
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.text) {
                    setError(error.response.data.text);
                }
                else {
                    setError('Неизвестная ошибка. Попробуйте позже.');
                }
            })
    }

    useEffect(() => {
        if (statuses.every(status => status === 'success')) {
            setButtonActive(true);
        }
        else {
            setButtonActive(false);
        }
    }, [statuses]);

    return (
        <Form>
            <H2>Восстановление пароля</H2>
            <P1>Придумайте новый пароль</P1>
            <Fields>
                <InputText status={pass1Status} header={'Новый пароль'} input={{value: password1, onInput: setPassword1, name: 'password', type: 'password'}} />
                <InputText status={pass2Status} header={'Подтвердите пароль'} input={{value: password2, onInput: setPassword2, name: 'password', type: 'password'}} />
            </Fields>
            <Buttons>
                {
                    error && <Error>{error}</Error>
                }
                <ButtonPU onClick={next} className={buttonActive ? '' : 'disabled'}><H5>Подтвердить</H5></ButtonPU>
            </Buttons>
        </Form>
    );
};

export default NewPassword;