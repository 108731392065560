import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {storeType} from "../../../redux/store";
import {link} from "../../../utils/types";
import post from "../../../api/post";
import {BlocksStyle, BlockStyle, Button, ButtonVisible, ColumnButtons, Header} from "./shared";
import {H5} from "../../shared/fonts/fonts";
import {InputText, InputTextArea} from "../../shared/form/input";
import styled from "styled-components";

const Text = styled.div`
  display: grid;
  grid-gap: 10px;
`;

const Link = (props: {data: link, updateLinksList: () => void, order_i: number, links: link[]}) => {
    const token = useSelector<storeType, string|null>((state) => state.token);
    const [title, setTitle_] = useState(props.data.title);
    const [description, setDescription_] = useState(props.data.description);
    const [href, setHref_] = useState(props.data.href);
    const [deleting, setDeleting] = useState(false);

    const setTitle = (e: any) => setTitle_(e.target.value);
    const setDescription = (e: any) => setDescription_(e.target.value);
    const setHref = (e: any) => setHref_(e.target.value);

    const setVisibility = () => {
        post('setLinkVisible', {token, link_id: props.data.id, visibility: 1 - props.data.visible})
            .then(props.updateLinksList)
            .catch();
    }

    const changed = title !== props.data.title || description !== props.data.description || href !== props.data.href;

    const save = () =>
        post('editLink', {token, link_id: props.data.id, title, description, href})
            .then(() => {
                props.updateLinksList();
            })
            .catch();

    const cancel = () => {
        setTitle_(props.data.title);
        setDescription_(props.data.description);
        setHref_(props.data.href);
    }

    const deleteLink = () => setDeleting(true);
    const cancelDeleteLink = () => setDeleting(false);
    const sureDeleteLink = () =>
        post('deleteLink', {token, link_id: props.data.id})
            .then(() => {
                props.updateLinksList();
            })
            .catch();

    const setOrder = (vector: number) => {
        const changeWith = props.links.at(props.order_i + vector);
        changeWith && post('setLinkOrder', {token, link_id: props.data.id, order_id: changeWith.order_id})
            .then(() =>
                post('setLinkOrder', {token, link_id: changeWith.id, order_id: props.data.order_id})
                    .then(() => {
                        props.updateLinksList();
                    })
                .catch())
            .catch();
    }

    return (
        <BlockStyle>
            <Header className={props.data.visible === 1 ? 'visible' : ''}>
                <ButtonVisible className={(props.data.visible === 0 && (!props.data.title || !props.data.href)) ? 'disabled' : ''} onClick={setVisibility}>
                    <H5>{props.data.visible === 0 ? 'Сделать видимым' : 'Сделать невидимым'}</H5>
                </ButtonVisible>
                <Button onClick={() => setOrder(-1)} className={props.order_i === 0 ? 'disabled' : ''}>
                    <H5>▲</H5>
                </Button>
                <Button onClick={() => setOrder(1)} className={props.order_i === props.links.length - 1 ? 'disabled' : ''}>
                    <H5>▼</H5>
                </Button>
                <Button onClick={save} className={changed ? '' : 'disabled'}>
                    <H5>Сохранить изменения</H5>
                </Button>
                <Button onClick={cancel} className={changed ? '' : 'disabled'}>
                    <H5>Отменить изменения</H5>
                </Button>
                {
                    deleting ?
                        <ColumnButtons>
                            <Button onClick={sureDeleteLink}>
                                <H5>Удалить</H5>
                            </Button>
                            <Button onClick={cancelDeleteLink}>
                                <H5>Отмена</H5>
                            </Button>
                        </ColumnButtons>
                        :
                        <Button onClick={deleteLink}>
                            <H5>Удалить</H5>
                        </Button>
                }
            </Header>
            <Text>
                <InputText input={{value: title, onInput: setTitle, style: {borderWidth: '2px', borderStyle: 'dashed'},
                    placeholder: 'Введите название'}} />
                <InputText input={{value: href, onInput: setHref, style: {borderWidth: '2px', borderStyle: 'dashed'},
                    placeholder: 'Введите адрес ссылки'}} />
                <InputTextArea input={{value: description, onInput: setDescription, style: {borderWidth: '2px', borderStyle: 'dashed'},
                    placeholder: 'Введите описание'}} />
            </Text>
        </BlockStyle>
    )
}

const Links = () => {
    const token = useSelector<storeType, string|null>((state) => state.token);
    const [links, setLinks] = useState<link[]>([]);

    const updateLinksList = () => {
        setLinks([]);
        post('getLinksList', {token})
            .then(result => setLinks(result.data.links))
            .catch();
    }

    useEffect(() => {
        updateLinksList();
    }, []);

    const create = () =>
        post('createLink', {token})
            .then(updateLinksList)
            .catch();

    return (
        <BlocksStyle>
            <Button onClick={create}><H5>Создать ссылку</H5></Button>
            {links.map((l, i) =>
                <Link order_i={i} links={links} updateLinksList={updateLinksList} key={i} data={l} />)}
        </BlocksStyle>
    );
};

export default Links;