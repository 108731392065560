import React from 'react';
import {months, weekDays} from "../../utils/constants";
import styled from "styled-components";
import {H8} from "../shared/fonts/fonts";
import {Images} from "../../utils/images";

const HintStyle = styled.div`
  background: ${props => props.theme.color.pu1};
  border-radius: 16px;
  color: white;
  padding: 4px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
`;

const DayHeaders = styled.div`
  display: grid;
  grid-gap: 14px;
  grid-auto-flow: column;
  grid-auto-columns: 22px;
  justify-items: center;
  margin: 12px 0 15px 0;
`;

const DayHeader = styled.div`
  color: #919095;
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 13.5px;
`;

const DayHeaderCurrent = styled(DayHeader)`
  color: ${props => props.theme.color.pi2};
`;

const Weeks = styled.div`
  display: grid;
  grid-gap: 5px;
`;

const Week = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 14px;
  height: 18px;
  margin: 2px -6px;
  padding: 0 6px;
  align-content: center;
  border-radius: 4.5px;
  //background: #F2F2F2;
  
  &.current {
    background: #F2F2F2;
  }
`;

const Empty = styled.div`
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: ${props => props.theme.color.bl4};
  margin: 7.5px;
`;

const Day = styled.div`
  display: grid;
  align-content: center;
  justify-content: center;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background: ${props => props.theme.color.bl4};
  
  color: ${props => props.theme.color.bl1};
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 13.5px;
  
  cursor: pointer;
`;

const DayCurrent = styled(Day)`
  background: ${props => props.theme.color.b1};
  color: white;
`;

const DateStyle = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 5px;
  justify-content: start;

  align-self: end;
  
  & h6:nth-child(2) {
    color: ${props => props.theme.color.b1};
  }
`;

export const ButtonMonthLeft = styled.div`
    border-radius: 9px 0 0 9px;
    background: #F3F3F3;
    padding: 9px 16px;
    cursor: pointer;
    
    & h5 {
        color: #535353;
    }
`;

export const ButtonMonthRight = styled(ButtonMonthLeft)`
  border-radius: 0 9px 9px 0;
  
  & img {
    transform: rotate(180deg);
  }
`;

const Buttons = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-self: end;
`;

const Header = styled.div`
  display: grid;
  grid-auto-flow: column;
`;

const CalendarStyle = styled.div`
  justify-self: center;
`;

const Calendar = (props: {currentDate: Date; setCurrentDate: (date: Date) => void; currentWeekDay: number; weekStart: Date }) => {
    const now = new Date();
    const iDate = new Date(props.currentDate);
    iDate.setDate(1);
    let iWeekDay = (6 + iDate.getDay()) % 7;

    let currentWeek = 0;

    const eDate = new Date(props.currentDate);
    eDate.setMonth(eDate.getMonth() + 1);
    eDate.setDate(0);
    const countOfDays = eDate.getDate();
    const rows:number[][][] = [];
    const countOfRows = Math.ceil((iWeekDay + countOfDays) / 7);
    for (let i = 0; i < countOfRows; i++) {
        const row = [];
        for (let t = 0; t < 7; t++) {
            row.push([0, 0]);
        }
        rows.push(row)
    }

    let week = 0;
    for (let i = 1; i < countOfDays + 1; i++) {
        if (i === props.currentDate.getDate()) {
            currentWeek = week;
        }
        if (props.currentDate.getFullYear() === now.getFullYear()
            && props.currentDate.getMonth() === now.getMonth()
            && i === now.getDate()) {
            rows[week][iWeekDay] = [i, 2];
        }
        else {
            rows[week][iWeekDay] = [i, 1];
        }
        iWeekDay = (iWeekDay + 1) % 7;
        if (iWeekDay === 0) {
            week += 1;
        }
    }

    const setDate = (date: number) => {
        const newDate = new Date(props.currentDate);
        newDate.setDate(date);
        props.setCurrentDate(newDate)
    }

    const buttonLeft = () => {
        const newDate = new Date(props.currentDate);
        newDate.setDate(0);
        props.setCurrentDate(newDate)
    }

    const buttonRight = () => {
        const newDate = new Date(props.currentDate);
        newDate.setDate(countOfDays + 1);
        props.setCurrentDate(newDate)
    }

    return (
        <CalendarStyle>
            <Header>
                <DateStyle>
                    <H8>{months[props.currentDate.getMonth()]}</H8>
                    <H8>{props.currentDate.getFullYear()}</H8>
                </DateStyle>
                <Buttons>
                    <ButtonMonthLeft onClick={buttonLeft}><img src={Images.icons.left} /></ButtonMonthLeft>
                    <ButtonMonthRight onClick={buttonRight}><img src={Images.icons.left} /></ButtonMonthRight>
                </Buttons>
            </Header>
            <DayHeaders>
                {
                    weekDays.map((day, i) =>
                        i === props.currentWeekDay ? <DayHeaderCurrent key={i}>{day}</DayHeaderCurrent> : <DayHeader key={i}>{day}</DayHeader>)
                }
            </DayHeaders>
            <Weeks>
                {
                    rows.map((row, i) =>
                        <Week className={i === currentWeek ? 'current' : ''} key={i}>
                            {
                                row.map((day, i) =>
                                    [
                                        <Empty key={i} onClick={
                                            () => setDate(day[0])
                                        } />,
                                        <Day key={i} onClick={
                                            () => setDate(day[0])
                                        }>{day[0]}</Day>,
                                        <DayCurrent key={i} onClick={
                                            () => setDate(day[0])
                                        }>{day[0]}</DayCurrent>
                                    ][day[1]]
                                )
                            }
                        </Week>)
                }
            </Weeks>
            <HintStyle>Eсли вы не видите свободной записи,<br/> пожалуйста, обновите страницу</HintStyle>
        </CalendarStyle>
    );
};

export default Calendar;
