import styled from "styled-components";

export const BlocksStyle = styled.div`
  display: grid;
  grid-gap: 20px;
`;
export const BlockStyle = styled.div`
  border: 2px dashed ${props => props.theme.color.dpu2};
  display: grid;
  grid-gap: 20px;
  padding: 20px;
`;

export const Header = styled.div`
  margin: -22px;
  border: 2px solid ${props => props.theme.color.dpu2};
  margin-bottom: 0;
  border-bottom: 0;
`;

export const Button = styled.a`
  display: block;
  cursor: pointer;
  background-color: ${props => props.theme.color.bl4};
  user-select: none;
  padding: 0 8px;
  text-decoration: none;
  
  &:hover {
    background-color: ${props => props.theme.color.bl3};
  }
  
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: auto;
  }
`;

export const ButtonVisible = styled(Button)`
  border-top: 8px solid ${props => props.theme.color.pi1};

  .visible & {
    border-top-color: ${props => props.theme.color.g1};
  }
`;

export const ColumnButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  background-color: ${props => props.theme.color.bl4};
  justify-content: start;
  grid-gap: 10px;
`;