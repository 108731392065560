import React, {useState} from 'react';
import {H3, H4, H6} from "../shared/fonts/fonts";
import {ButtonText} from "../shared/buttons/buttons";
import {Images} from "../../utils/images";
import styled from "styled-components";
import {article} from "../../utils/types";
import {useViewport} from "../../utils/ViewportContext";

const ArticleStyle = styled.div`
  width: 336px;
  border-radius: 16px;
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  overflow: hidden;
  
  & h4 {
    margin: 16px 20px 13px 20px;
    height: 116px;
    text-align: center;
    white-space: normal;

    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  & h6 {
    color: #828282;
    padding: 0 0 17px 20px;
  }
  
  .mobile & {
    width: 100%;
  }
`;

const Image = styled.div<{src?: string}>`
  height: 154px;
  background: url(${props => props.src}), url(${Images.icons.articles});
  background-size: cover,25%;
  background-position: center;
  background-repeat: no-repeat;
`;

const Article = (props: {article: article}) => {
    return (
        <ArticleStyle>
            <Image src={props.article.image}></Image>
            <H4>{props.article.title}</H4>
            <H6>{props.article.date}</H6>
        </ArticleStyle>)
}

const ButtonStyle = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  align-items: center;
  
  &.more img {
    transform: rotate(180deg);
  }
`;

const ArticlesStyle = styled.div`
  padding: 82px 24px 60px 24px;
  display: grid;
  justify-items: center;
  
  .mobile & {
    padding: 82px 10px 60px 10px;
  }
`;

const ArticlesContainer = styled.div<{rows: number}>`
  margin: 90px 0 40px 0;
  display: grid;
  grid-gap: 30px;
  height: ${props => props.rows * 366 - 24}px;
  overflow-y: hidden;
  align-content: start;
  
  transition: height 0.2s ease-in-out;
  
  .mobile & {
    width: 100%;
  }
`;

const ArticlesRow = styled.div`
  display: flex;
  column-gap: 16px;
`;

const Articles = () => {
    const [more, setMore] = useState(false);

    const viewport = useViewport();
    const countInRow = Math.floor((viewport.viewport.width - 32) / 352);
    let rows:any[] = [];

    const [articles, setArticles] = useState<article[]>([]);

    for (let i = 0; i < articles.length; i++) {
        if (rows.at(-1) && rows.at(-1).length < countInRow) {
            rows.at(-1).push(<Article key={i} article={articles[i]} />)
        }
        else {
            rows.push([<Article key={i} article={articles[i]} />])
        }
    }

    /*useEffect(() => {
        post('getArticles', {})
            .then(result => setArticles(result.data.articles))
            .catch();
    }, []);*/

    if (articles.length === 0) {
        return <div></div>
    }

    return (
        <ArticlesStyle>
            <H3>Статьи</H3>
            <ArticlesContainer rows={more ? rows.length : 1}>
                {
                    rows.map((articles, i) => <ArticlesRow key={i}>{articles}</ArticlesRow>)
                }
            </ArticlesContainer>
            {
                rows.length > 2 &&
                <ButtonText onClick={() => setMore(!more)}>
                    <H4>
                        <ButtonStyle className={more ? 'more' : ''}><img src={Images.icons.arrowDown}/>
                            {more ? 'Свернуть' : 'Развернуть'}
                        </ButtonStyle>
                    </H4>
                </ButtonText>
            }
        </ArticlesStyle>
    );
};

export default Articles;
