import React from 'react';
import Template from "../components/template/template";
import Top from "../components/info/Top";
import Articles from "../components/info/Articles";
import Links from "../components/info/Links";
import Videos from "../components/info/Videos";
import Text from "../components/info/Text";

const Info = () => {
    return (
        <Template>
            <Top />
            <Text />
            <Articles />
            <Links />
            <Videos />
        </Template>
    );
};

export default Info;