import React from 'react';
import styled from "styled-components";

const Button = styled.a`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  height: 40px;
  box-sizing: border-box;
  border: 0;
  cursor: pointer;
  text-decoration: none;
  
  &, & * {
    user-select: none;
  }
`;

export const ButtonGray = styled(Button)`
  background: ${props => props.theme.color.dpu5};
  border-radius: 16px;

  & h5 {
    color: ${props => props.theme.color.pu1};
  }
`;



export const ButtonWhite = styled(Button)`
  background: none;
  border-radius: 16px;
  border: 1px solid ${props => props.theme.color.pu1};
  grid-gap: 10px;

  color: ${props => props.theme.color.dpu1};
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  
  & h5 {
    color: ${props => props.theme.color.pu1};
  }

  &.disabled {
    cursor: auto;
    border-color: ${props => props.theme.color.pu2};
    pointer-events: none;
    
    & h5 {
      color: ${props => props.theme.color.pu2};
    }
  }
`;



export const ButtonPU = styled(Button)`
  background: ${props => props.theme.color.pu1};
  border-radius: 16px;

  & h5 {
    color: white;
  }
  
  &.disabled {
    cursor: auto;
    background: ${props => props.theme.color.pu2};
    pointer-events: none;
  }
`;



export const ButtonBlue = styled(Button)`
  background: ${props => props.theme.color.b1};
  border-radius: 16px;

  & h5 {
    color: white;
  }
`;



export const ButtonText = styled(Button)`
  padding: 0 0;
  height: auto;

  background: none;
  
  & > * {
    margin: 0;
    color: ${props => props.theme.color.b1};
  }
`;

export const ButtonTextAngry = styled(ButtonText)`
  & > h5 {
    color: ${props => props.theme.color.pi2} !important;
  }
`;
