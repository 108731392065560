import {communication} from "./types";
import env from "../env";
import {Images} from "./images";
import User from "../entities/User";

export const api_host = env.API_HOST;

export const Strings = {
    email: 'doc_zaira@mail.ru',
    phone: '+7 (918) 010-85-55',
    address: 'г. Краснодар',
    instagram: '@Doc_Zaira',
    inn: '0105 1025 5411'
}

export const Links = {
    telegram: 'https://t.me/doc_zairaA',
    instagram: 'https://instagram.com/doc_zaira?igshid=MzRlODBiNWFlZA==',
}

export const AdminUser:User = new User({
    id: 0,
    firstname: 'Заира',
    lastname: 'Сиюхова',
    fatherName: 'Аслановна',
    phone: '',
    email: '',
    role: 2,
    hasAvatar: true,
    unread: 0,
    birthdate: 0
});

export const URLs = {
    main: '/',
    about: '/about',
    info: '/info',
    auth: '/auth',
    appointment: '/appointment/:id?',
    chat: '/chat',
    lk: '/lk/:id?',
    admin: '/admin',
    article: '/article',
    articleEdit: '/article/edit',
    appointmentResult: '/appointment/result',
    consultations: '/appeal',
    writeReview: '/write/review',
    appointmentView: '/appointment/view/:id',
}

export const months = ['Январь', 'Февраль', 'Март', 'Апрель',
    'Май', 'Июнь', 'Июль', 'Август',
    'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

export const weekDays = [
    'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС',
];

export const communications:communication[] = [
    {
        title: 'WhatsApp',
        link: 'whatsapp',
        icon: Images.icons.comm_whatsapp
    },
    {
        title: 'Telegram',
        link: 'telegram',
        icon: Images.icons.comm_telegram
    },
    {
        title: 'Skype',
        link: 'skype',
        icon: Images.icons.comm_skype
    },
];
