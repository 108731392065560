import React, {JSX, useState} from 'react';
import Template from "../components/template/template";
import {H1, H5} from "../components/shared/fonts/fonts";
import styled from "styled-components";
import Appointments from "../components/admin/pages/Appointments";
import {ButtonPU} from "../components/shared/buttons/buttons";
import Timetable from "../components/admin/pages/Timetable";
import Consultations from "../components/admin/pages/Consultations";
import AboutMe from "../components/admin/pages/AboutMe";
import Info from "../components/admin/pages/Info";

interface MenuItem {
    title: string,
    page: JSX.Element,
    link: string
};

const Menu:MenuItem[] = [
    {
        link: 'appointments',
        title: 'Записи',
        page: <Appointments />
    },
    {
        link: 'timetable',
        title: 'Расписание',
        page: <Timetable />
    },{
        link: 'consultations',
        title: 'Виды обращений',
        page: <Consultations />
    },{
        link: 'aboutMe',
        title: 'Данные обо мне',
        page: <AboutMe />
    },{
        link: 'info',
        title: 'Полезная информация',
        page: <Info />
    }
];

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: minmax(min-content, 20%) auto;
  margin: 50px;
  grid-gap: 20px;
`;

const MenuStyle = styled.div`
  display: grid;
  grid-gap: 10px;
  align-content: start;
`;

const ButtonPUStyle = styled(ButtonPU)`
  background: ${props => props.theme.color.dpu2};
  transition: background-color 0.1s ease-in-out;
  
  &.active {
    background: ${props => props.theme.color.dpu1};
  }
`;

const Header = styled.div`
  text-align: center;
  margin: 50px;
`;

const Admin = () => {
    const [page, setPage] = useState<string>(Menu[0].link);

    return (
        <Template>
            <Header><H1>Панель администратора</H1></Header>
            <Container>
                <MenuStyle>
                    {
                        Menu.map((menu_item, i) =>
                            <ButtonPUStyle className={menu_item.link == page ? 'active' : ''} key={i}
                                           onClick={() => setPage(menu_item.link)}>
                                <H5>{menu_item.title}</H5>
                            </ButtonPUStyle>)
                    }
                </MenuStyle>
                <div>
                    {
                        Menu.find(menu_item => menu_item.link == page)?.page
                    }
                </div>
            </Container>
        </Template>
    );
};

export default Admin;
