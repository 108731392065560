import React, {useEffect, useState} from 'react';
import {H3, H4, H5} from "../shared/fonts/fonts";
import styled from "styled-components";
import {Images} from "../../utils/images";
import post from "../../api/post";
import {link} from "../../utils/types";

const LinkStyle = styled.div`
  display: grid;
  grid-auto-flow: column;
  width: 688px;
  border-radius: 16px;
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  padding: 36px 34px;
  justify-content: start;
  grid-gap: 42px;
  cursor: pointer;

  & > div {
    display: grid;
    grid-gap: 3px;
  }
  
  .mobile & {
    width: auto;
  }
  
  & h5 {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const Link = (props: link) => {
  return (
      <LinkStyle onClick={() => window.open(props.href)}>
          <img src={Images.icons.linkL} />
          <div>
              <H4>{props.title}</H4>
              <H5>{props.description}</H5>
          </div>
      </LinkStyle>)
}

const LinksStyle = styled.div`
  padding: 92px 140px;
  
  & h3 {
    text-align: center;
  }
  
  .mobile & {
    padding: 92px 10px;
  }
`;

const LinksContainer = styled.div`
  margin-top: 90px;
  display: grid;
  grid-gap: 20px;
  justify-content: center;
`;

const Links = () => {
    const [links, setLinks] = useState<link[]>([]);

    useEffect(() => {
        post('getLinks', {})
            .then(result => setLinks(result.data.links))
            .catch();
    }, []);

    if (links.length === 0) {
        return <div></div>
    }

    return (
        <LinksStyle>
            <H3>Полезные ссылки</H3>
            <LinksContainer>
                {
                    links.map((link, i) =>
                        <Link key={i} {...link} />)
                }
            </LinksContainer>
        </LinksStyle>
    );
};

export default Links;
