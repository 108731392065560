import React from 'react';
import styled from "styled-components";

const Form = styled.div`
  margin: 0 auto 114px auto;
  display: grid;
  padding: 50px 112px;
  justify-content: center;
  align-items: center;
  gap: 50px;
  justify-items: center;
  grid-template-columns: 100%;
  width: min-content;

  border-radius: 16px;
  background: white;
  /* Тень 1 */
  box-shadow: 48px 24px 48px 0px rgba(24, 37, 56, 0.12);
  
  .mobile & {
    box-sizing: border-box;
    width: 100%;
    padding: 50px 20px;
    
    & h2 {
      font-size: 24px;
      white-space: normal;
    }
    
    & input {
      width: auto;
    }
  }
`;

export default Form;
