import React, {useEffect, useRef, useState} from 'react';
import Template from "../components/template/template";
import EditorJS, {OutputData} from '@editorjs/editorjs';
import Localization from "../components/article/localization";
import uploadByFile from "../components/article/uploadByFile";
import {BlockStyle, Header, Button, ColumnButtons} from "../components/admin/tabs/shared";
import {H5} from "../components/shared/fonts/fonts";
import {useLocation} from "react-router-dom";
import {articleVersion} from "../utils/types";
import {useSelector} from "react-redux";
import {storeType} from "../redux/store";

const Headerr = require('@editorjs/header');
const Image = require('@editorjs/image');
const List = require('@editorjs/list');
const Checklist = require('@editorjs/checklist');
const Marker = require('@editorjs/marker');
const Delimiter = require('@editorjs/delimiter');
const Table = require('@editorjs/table');
const Attaches = require('@editorjs/attaches');

const ArticleEdit = () => {
    const token = useSelector<storeType, string|null>((state) => state.token);
    const location = useLocation();
    const article_string = new URLSearchParams(location.search).get('article');
    const article_id = (article_string && parseInt(article_string)) || 0;

    const [versions, setVersions] = useState<articleVersion[]>([]);
    const [currentVersion, setCurrentVersion] = useState(0);

    const [rendering, setRendering] = useState(false);
    const [changed, setChanged] = useState(false);

    const ejInstance = useRef<EditorJS|1|null>(null);
    const [data, setData] = useState<OutputData>({
        time: 0,
        version: "",
        blocks: []
    });

    const initEditor = () => {
        ejInstance.current = 1;
        const ed_ = new EditorJS({
            holder: 'article',
            onReady: () => {
                ejInstance.current = ed_ || null;
                updateVersions();
            },
            onChange: async () => {
                if (ejInstance.current === 1 || !ejInstance.current) {
                    return;
                }
                let content = await ejInstance.current.saver.save();
                content && setData(content);
            },
            data,
            placeholder: 'Введите текст статьи',
            tools: {
                header: Headerr,
                image: {
                    class: Image,
                    config: {
                        uploader: {
                            uploadByFile
                        }
                    }
                },
                attaches: {
                    class: Attaches,
                    config: {
                        uploader: {
                            uploadByFile
                        }
                    }
                },
                list: List,
                checklist: Checklist,
                marker: Marker,
                delimiter: Delimiter,
                table: Table
            },
            i18n: {
                messages: Localization
            }
        });
    };

    const updateVersions = () => {
        /*post('getArticleVersions', {token, article_id})
            .then(result => {
                setCurrentVersion(result.data.versions.length - 1);
                setVersions(result.data.versions);
            })
            .catch();*/
    }

    const cancel = () => {
        const _version = versions[currentVersion];
        if (_version && ejInstance.current !== null && ejInstance.current !== 1) {
            ejInstance.current.render({...data, blocks: JSON.parse(_version.data)});
        }
        setChanged(false);
    }

    useEffect(() => {
        cancel()
    }, [versions, currentVersion]);

    useEffect(() => {
        window.onbeforeunload = function() {
            return true;
        };

        if (ejInstance.current === null) {
            initEditor();
        }
    }, []);

    useEffect(() => {
        if (rendering) {
            if (ejInstance.current !== null && ejInstance.current !== 1) {
                ejInstance.current.destroy();
                ejInstance.current = null;
            }
        }
        else {
            if (ejInstance.current === null) {
                initEditor();
            }
        }
    }, [rendering]);

    const save = () => {
        /*post('createVersion', {token, article_id, data: JSON.stringify(data.blocks)})
            .then(() => updateVersions())
            .catch();*/
    }

    useEffect(() => {
        const _version = versions[currentVersion];
        if (_version) {
            setChanged(_version.data !== JSON.stringify(data.blocks));
        }
        else {
            setChanged(data.blocks.length > 0);
        }}, [data]);

    return (
        <Template>
            <BlockStyle>
                <Header>
                    <Button onClick={save} className={changed ? '' : 'disabled'}>
                        <H5>Сохранить</H5>
                    </Button>
                    <Button onClick={cancel} className={changed ? '' : 'disabled'}>
                        <H5>Отменить изменения</H5>
                    </Button>
                    <Button onClick={() => setRendering(!rendering)}>
                        <H5>{rendering ? "Редактировать" : "Предпросмотр"}</H5>
                    </Button>
                    <Button className={'disabled'}>
                        <H5>Выбрать версию</H5>
                    </Button>
                    <ColumnButtons>
                        <Button onClick={() => setCurrentVersion(currentVersion - 1)}
                                className={(!changed && (currentVersion > 0)) ? '' : 'disabled'}>
                            <H5>◀</H5>
                        </Button>
                        <Button onClick={() => setCurrentVersion(currentVersion + 1)}
                                className={(!changed && (currentVersion < versions.length - 1)) ? '' : 'disabled'}>
                            <H5>▶</H5>
                        </Button>
                    </ColumnButtons>
                </Header>
            </BlockStyle>
            {
                rendering ? <></> : <div id={'article'}></div>
            }
        </Template>
    );
};

export default ArticleEdit;