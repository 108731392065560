import React from 'react';
import {ButtonText} from "../../shared/buttons/buttons";
import {H4, H5} from "../../shared/fonts/fonts";
import styled from "styled-components";
import {toLocaleDateStringByMoscow} from "../../../utils/functions";
import {useViewport} from "../../../utils/ViewportContext";

const WeekStyle = styled.div`
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    grid-gap: 20px;
    
    .mobile & {
        grid-gap: 8px;
        grid-auto-flow: row;
    }

    .weeksPU & a h5 {
        color: ${props => props.theme.color.dpu2};
    }
`;

const WeekDates = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 4px;
    justify-content: center;

    & div h4  {
        padding: 0 8px;
        color: white;
        background: ${props => props.theme.color.b1};
        text-align: center;

        .weeksPU & {
            background: ${props => props.theme.color.dpu2};
        }
    }
`;

const Buttons = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
`;

const Week = (props: {firstDayOfWeek: Date, setFirstDayOfWeek: (date: Date) => void}) => {
    const viewport = useViewport();
    const setWeek = (vector: number) => {
        props.setFirstDayOfWeek(new Date(props.firstDayOfWeek.getTime() + vector * 7 * 24 * 60 * 60 * 1000));
    }

    if (viewport.device === 'mobile') {
        return (
            <WeekStyle>
                <WeekDates>
                    <div><H4>{toLocaleDateStringByMoscow(props.firstDayOfWeek).slice(0, 5)}</H4></div>
                    <H4>-</H4>
                    <div><H4>{
                        toLocaleDateStringByMoscow(new Date(props.firstDayOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000))
                            .slice(0, 5)}</H4>
                    </div>
                </WeekDates>
                <Buttons>
                    <ButtonText onClick={() => setWeek(-1)}><H5>◀ Предыдущая неделя</H5></ButtonText>
                    <ButtonText onClick={() => setWeek(1)}><H5>Следующая неделя ▶</H5></ButtonText>
                </Buttons>
            </WeekStyle>
        );
    }

    return (
        <WeekStyle>
            <ButtonText onClick={() => setWeek(-1)}><H5>◀ Предыдущая неделя</H5></ButtonText>
            <WeekDates>
                <div><H4>{toLocaleDateStringByMoscow(props.firstDayOfWeek).slice(0, 5)}</H4></div>
                <H4>-</H4>
                <div><H4>{
                    toLocaleDateStringByMoscow(new Date(props.firstDayOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000))
                        .slice(0, 5)}</H4>
                </div>
            </WeekDates>
            <ButtonText onClick={() => setWeek(1)}><H5>Следующая неделя ▶</H5></ButtonText>
        </WeekStyle>
    );
};

export default Week;