import React, {useEffect, useState} from 'react';
import Form from "../shared/form/form";
import {H2, H5, P2} from "../shared/fonts/fonts";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {storeType} from "../../redux/store";
import {ButtonText} from "../shared/buttons/buttons";
import InfoRow from "./InfoRow";
import Loading from "../shared/form/loading";
import {URLs} from "../../utils/constants";
import {setWraps} from "../../utils/functions";
import Link from "../shared/form/link";
import Error from "../auth/Error";
import {CheckboxBlock} from "../shared/form/checkbox";
import {Images} from "../../utils/images";
import {Cancel, Message} from "../shared/calendar/Card";
import {
    Buttons,
    Communication, DateBlock,
    dateToString,
    Fields,
    Links,
    PaymentStyle, Requisite
} from "./Confirmation";
import User, {UserData} from "../../entities/User";
import Slot, {SlotData} from "../../entities/Slot";
import {useParams} from "react-router-dom";
import {ChooseSlot} from "../../redux/actions";
import {disAppoint} from "../../api/methods/slots/update/disAppoint";

const Sure = styled.div`
  display: grid;
  grid-gap: 10px;
  
  & div {
    display: grid;
    grid-gap: 10px;
    grid-auto-flow: column;
  }
`;

const Appointment = () => {
    const meData = useSelector<storeType, UserData | null>((state) => state.me);
    const me = meData ? new User(meData) : null;
    const [sure, setSure] = useState(false);
    const [error, setError] = useState<string|null>(null);

    const slotData = useSelector<storeType, SlotData|null>((state) => state.slot);
    const slot = slotData ? new Slot(slotData) : null;

    const params = useParams();
    const slot_id = params.id ? parseInt(params.id) : null;
    const dispatch = useDispatch();

    const disappoint = () =>
        slot && disAppoint(slot)
            .then(() => window.open(User.linkToView(), '_self'))
            .catch(error => setError(error.response.data.text));

    useEffect(() => {
        slot_id && Slot.getByID(slot_id).then(data => dispatch(ChooseSlot(data)));
    }, [slot_id]);

    if (!slot || !slot.user) {
        return (<Form>
            <H2>Запись на онлайн-информирование</H2>
            <Fields>
                <Loading />
            </Fields>
            <Buttons>
                {
                    error && <Error>{error}</Error>
                }
            </Buttons>
        </Form>);
    }

    return (
        <Form>
            <H2>Просмотр записи<br/>на онлайн-информирование</H2>
            <Fields>
                <InfoRow header={'ФИО:'}>
                    <a href={slot.user.linkToView()}>
                        <H5>{[slot.user.lastname, slot.user.firstname, slot.user.fatherName].join(' ')}</H5>
                    </a>
                </InfoRow>
                <InfoRow header={'Название:'}><H5>{slot.consultation.title}</H5></InfoRow>
                {
                    slot.consultation.description &&
                    <InfoRow header={'Описание:'}><H5>{setWraps(slot.consultation.description)}</H5></InfoRow>
                }
                <InfoRow header={'Длительность:'}><H5>{slot.consultation.duration} мин.</H5></InfoRow>
                <InfoRow header={'Стоимость:'}><H5>{slot.consultation.price} руб.</H5></InfoRow>
                <InfoRow header={'Время:'}>
                    <H5>
                        {dateToString(slot.timestamp)}
                    </H5>
                    <DateBlock slot={slot} />
                </InfoRow>
                <InfoRow header={'Средства связи:'}>
                    {slot.consultation.communications.map((c, i) => <Communication key={i} comm_link={c} />)}
                </InfoRow>
                <InfoRow header={'Я ознакомлен и согласен со следующими документами:'}>
                    <CheckboxBlock checkbox={{value: true}}>
                        <Links>
                            <Link target={'_blank'} href={'./assets/documents/Оферта.pdf'}>
                                Оферта
                            </Link>
                            <Link target={'_blank'} href={'./assets/documents/Политика конфиденциальности.pdf'}>
                                Политика конфиденциальности
                            </Link>
                            <Link target={'_blank'} href={'./assets/documents/Политика обработки ПД.pdf'}>
                                Политика обработки персональных данных
                            </Link>
                            <Link target={'_blank'} href={'./assets/documents/Пользовательское соглашение.pdf'}>
                                Пользовательское соглашение
                            </Link>
                            <Link target={'_blank'} href={'./assets/documents/Согласие на обработку ПД.pdf'}>
                                Согласие на обработку персональных данных
                            </Link>
                        </Links>
                    </CheckboxBlock>
                </InfoRow>
                {
                    slot.consultation.price > 0 &&
                    <PaymentStyle>
                        <InfoRow header={''}>
                            <P2>Оплата производится:<br />
                                - по номеру счета <Requisite>40817810101000765922</Requisite><br/>
                                - или номеру карты <Requisite>2202 2067 3979 4852</Requisite> <i>Сбер, Заира Аслановна С.</i><br/>
                                <br/>
                                <b>После оплаты просьба прислать чек в WhatsApp или Telegram <Requisite>+7 (918) 010-85-55</Requisite>.</b><br/>
                                При отсутствии оплаты в течение суток после бронирования времени, бронь будет отменена.<br/>
                                Если у Вас возникли вопросы по поводу оплаты, просьба написать на почту doc_zaira@mail.ru.<br/>
                            </P2>
                        </InfoRow>
                    </PaymentStyle>
                }
            </Fields>
            <Buttons>
                {
                    error && <Error>{error}</Error>
                }
                {
                    sure ? <Sure>
                            <H5>Вы уверены?</H5>
                            <div>
                                <ButtonText onClick={() => setSure(false)}><H5>Закрыть</H5></ButtonText>
                                <Cancel onClick={() => disappoint()}><H5>Отменить запись</H5></Cancel>
                            </div>
                    </Sure>
                        : <Cancel onClick={() => setSure(true)}><H5>Отменить запись</H5></Cancel>
                }
                {
                    slot.timestamp < (new Date()).getTime() / 1000 && me && me.role.isUser()
                        ?
                        <Message href={URLs.writeReview + '?slot=' + slot.id}>
                            Отзыв
                        </Message>
                        :
                        <Message href={URLs.chat + ((me && me.role.isAdmin() && slot.user) ? '?id=' + slot.user.id : '')}
                                 count={slot.user.unread}>
                            <img src={Images.icons.message_blue}/>Диалог
                        </Message>
                }
            </Buttons>
        </Form>
    );
};

export default Appointment;
