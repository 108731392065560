import React from 'react';
import {H3} from "../../shared/fonts/fonts";
import styled from "styled-components";
import WorkPlaces from "../aboutMe/workPlaces";

const AboutMeStyle = styled.div`
  display: grid;
  grid-gap: 10px;
`;

const AboutMe = () => {

    return (
        <AboutMeStyle>
            <H3>Данные на странице "Обо мне"</H3>
            <WorkPlaces />
        </AboutMeStyle>
    );
};

export default AboutMe;
