import React from 'react';
import Top from "../components/main/Top";
import Consultations from "../components/main/Consultations";
import Reviews from "../components/main/Reviews";
import Template from "../components/template/template";
import Documents from "../components/main/Documents";

const Main = () => {
    return (
        <Template>
            <Top />
            <Consultations />
            <Reviews />
            <Documents />
        </Template>
    );
};

export default Main;