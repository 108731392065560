import React from 'react';
import {H5} from "../fonts/fonts";
import styled from "styled-components";

const InputBlockStyle = styled.div`
  display: grid;
  grid-gap: 8px;
  justify-items: start;
`;

const InputBlockChildren = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  align-items: center;
  
  .mobile & {
    width: 100%;
  }
`;

const InputBlock = (props: {header?: string; children: any; blockStyle?: any}) => {
    return (
        <InputBlockStyle>
            {
                props.header && <H5>{props.header}</H5>
            }
            <InputBlockChildren style={props.blockStyle}>{props.children}</InputBlockChildren>
        </InputBlockStyle>
    );
};

const InputStyle = styled.input`
  width: 455px;
  padding: 16px;
  
  border-radius: 16px;
  border: 1px solid ${props => props.theme.color.bl1};
  background: white;

  font-family: 'Open Sans';
  color: ${props => props.theme.color.bl1};
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  box-sizing: border-box;
  
  &.success {
    border-color: ${props => props.theme.color.g1};
  }

  &.error {
    border-color: ${props => props.theme.color.pi2};
  }
`;

export const InputText = (props: {header?: string, textAfter?: any, input?: any, status?: string|null}) => {
    return (
        <InputBlock header={props.header}>
            <InputStyle type={'text'} {...props.input} className={props.status || 'default'} />{props.textAfter}
        </InputBlock>
    );
}

const InputAreaStyle = styled.textarea`
  width: 455px;
  padding: 16px;
  
  border-radius: 16px 16px 0 16px;
  border: 1px solid ${props => props.theme.color.bl1};
  background: white;

  font-family: 'Open Sans';
  color: ${props => props.theme.color.bl1};
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  box-sizing: border-box;
  
  &.success {
    border-color: ${props => props.theme.color.g1};
  }

  &.error {
    border-color: ${props => props.theme.color.pi2};
  }
`;

export const InputTextArea = (props: {header?: string, textAfter?: any, input?: any, status?: string|null}) => {
    return (
        <InputBlock header={props.header}>
            <InputAreaStyle type={'text'} {...props.input} className={props.status || 'default'} />{props.textAfter}
        </InputBlock>
    );
}



const SelectStyle = styled.select`
  width: 455px;
  padding: 16px;
  
  border-radius: 16px;
  border: 1px solid ${props => props.theme.color.bl1};
  background: white;

  font-family: 'Open Sans';
  color: ${props => props.theme.color.bl1};
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  
  cursor: pointer;
`;

export const Select = (props: {header?: string; input?: any, children: any}) => {
    return (
        <InputBlock header={props.header}>
            <SelectStyle {...props.input}>
                {props.children}
            </SelectStyle>
        </InputBlock>
    );
}

const InlineSelectStyle = styled.select`
  border: none;
  background: none;

  font-family: 'Open Sans';
  color: ${props => props.theme.color.bl1};
  font-style: normal;
  font-size: 14px;
  font-weight: 700;
  
  cursor: pointer;
`;

export const InlineSelect = (props: {input?: any, children: any}) => {
    return (
        <InlineSelectStyle {...props.input}>
            {props.children}
        </InlineSelectStyle>
    );
}