import React from 'react';
import {H3, H4, P1} from "../shared/fonts/fonts";
import {Images} from "../../utils/images";
import styled from "styled-components";

const TextStyle = styled.div`
  display: grid;
  grid-gap: 54px;
  align-content: center;
  margin: 0 24px 80px 24px;
  
  & h3, & h4 {
    white-space: normal;
  }
`;

const Text = () => {
    return (
        <TextStyle>
            <H3>
                Варианты плана обследования перед очной консультацией или онлайн-информированием:
            </H3>
            <P1>
                <H4><img src={Images.icons.check} /> Слабость, выпадение волос:</H4>
                <br />
                Общий анализ крови с лейкоцитарной формулой и СОЭ<br /><br />
                Анализ крови на ТТГ, св.Т4, глюкозу, общий белок, креатинин, АЛТ, АСТ, ферритин, кальций общий, С-реактивный белок, калий, натрий, цинк, витамины В9, В12, 25-ОН-витамин Д
            </P1>
            <P1>
                <H4><img src={Images.icons.check} /> Нарушение менструального цикла, бесплодие, гирсутизм, акне:</H4>
                <br />

                Анализ крови на ТТГ, св.Т4, пролактин, АМГ в любой день цикла, не ранее, чем через 1 час после пробуждения, за сутки исключив интенсивную физическую активность и половой акт. При уровне пролактина выше 530 мЕд/л или 25 нг/мл – анализ крови на макропролактин с соблюдением тех же условий
                <br /><br />
                Анализ крови на 2-5 день менструального цикла (д.м.ц.) или в любой день при длительности цикла более 38 дней на ФСГ, ЛГ, общий тестостерон, ГСПГ, ДГЭА-С, 17-ОН-прогестерон. Анализы желательно сдать не позднее 8 утра. При уровне 17-ОН-прогестерона от 2 нг/мл или от 6 нмоль/л и выше – анализ крови на 17-ОН-прогестерон повторно с соблюдением тех же условий.
                <br /><br />
                УЗИ органов малого таза на 5-9 д.м.ц. или в любой день при длительности цикла более 38 дней.
            </P1>
            <P1>
                <H4><img src={Images.icons.check} /> Сахарный диабет:</H4>
                <br />
                Общий анализ крови с лейкоцитарной формулой и СОЭ
                <br /><br />
                Анализ крови на ТТГ, св.Т4, С-пептид, глюкозу, гликированный гемоглобин, креатинин, АЛТ, АСТ, общий билирубин, общий белок, ЛПНП, триглицериды, кальций общий, калий, натрий
                <br /><br />
                Общий анализ мочи
                <br /><br />
                Анализ разовой (утренней) мочи на альбумин-креатининовое соотношение (или альбумин, креатинин, или микроальбуминурию – зависит от лаборатории)
            </P1>
            <P1>
                <H4><img src={Images.icons.check} /> Ожирение:</H4>
                <br />
                Общий анализ крови с лейкоцитарной формулой и СОЭ
                <br /><br />
                Анализ крови на ТТГ, св.Т4, пролактин, глюкозу, гликированный гемоглобин, креатинин, АЛТ, АСТ, общий билирубин, мочевую кислоту, кальций общий, калий, натрий. Для женщин: анализы в любой день цикла, не ранее, чем через 1 час после пробуждения, за сутки исключив интенсивную физическую активность и половой акт. При уровне пролактина выше 530 мЕд/л или 25 нг/мл – анализ крови на макропролактин с соблюдением тех же условий. Для мужчин: в любой день, не ранее, чем через 1 час после пробуждения. При уровне пролактина выше 424 Ед/л или 20 нг/мл – анализ крови на макропролактин.
                <br /><br />
                УЗИ органов брюшной полости (натощак, воду не пить).
            </P1>
            <P1>
                <H4><img src={Images.icons.check} /> Узловой зоб:</H4>
                <br />
                Анализ крови на ТТГ, кальцитонин, кальций общий.
                <br /><br />
                УЗИ щитовидной железы
            </P1>
            <P1>
                ❗ Важный комментарий: здесь может быть не полный или избыточный список обследований конкретно для вас, но, в целом, с проблемами гипердиагностики вряд ли столкнемся. Напомню, что первичное обращение - это и есть та встреча, в рамках которой определяется план обследования, результаты которого мы обсуждаем на повторных очной консультации или обращении онлайн. Явка с результатами и вышепредставленный список – исключительно в помощь вам. Соответственно, ваша причина обращения может отсутствовать в представленном списке.
            </P1>
        </TextStyle>
    );
};

export default Text;