import React from 'react';
import Template from "../components/template/template";
import Info from "../components/lk/Info";
import Appointments from "../components/lk/Appointments";

const Lk = () => {
    return (
        <Template>
            <Info />
            <Appointments />
        </Template>
    );
};

export default Lk;