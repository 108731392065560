import React from 'react';
import styled from "styled-components";

const MainPhotoStyle = styled.svg`
  position: relative;
  margin: 40px 56px 0 0;
  filter: drop-shadow(48px 24px 48px rgba(24, 37, 56, 0.12));
  
  .mobile & {
    margin: 40px 10px 0 10px;
  }
`;

const MainPhoto = () => {
    return (
        <MainPhotoStyle width="490" height="500" viewBox="0 0 490 500" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g filter="url(#filter0_d_27_993)">
                <path fillRule="evenodd" clipRule="evenodd" d="M152.524 421.121C96.3266 411.44 30.679 400.602 6.85269 349.02C-16.2676 298.966 29.2627 249.184 49.4483 197.972C71.2445 142.674 72.6914 73.4273 126.007 46.7177C189.493 14.9135 271.711 15.4766 328.54 58.1558C384.74 100.363 396.779 177.375 393.037 247.26C389.76 308.481 359.981 365.267 310.171 401.273C265.117 433.842 207.466 430.586 152.524 421.121Z" fill="url(#pattern_main_photo)"/>
            </g>
            <defs>
                <filter id="filter0_d_27_993" x="0.461578" y="0.323517" width="489.2" height="499.282" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dx="48" dy="24"/>
                    <feGaussianBlur stdDeviation="24"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0941176 0 0 0 0 0.145098 0 0 0 0 0.219608 0 0 0 0.12 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_27_993"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_27_993" result="shape"/>
                </filter>
                <pattern id="pattern_main_photo" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref="#image_photo" transform="matrix(-0.00037 0 0 -0.00037 1.19 1.37) rotate(180, 1820, 2048)"/>
                </pattern>
            </defs>
        </MainPhotoStyle>
    );
};

export default MainPhoto;
