import React, {useEffect, useState} from 'react';
import {H5, H8, H9, P2} from "../shared/fonts/fonts";
import styled from "styled-components";
import {processPhone} from "../../utils/functions";
import {ButtonGray, ButtonText, ButtonWhite} from "../shared/buttons/buttons";
import {Images} from "../../utils/images";
import {useDispatch, useSelector} from "react-redux";
import {storeType} from "../../redux/store";
import {URLs} from "../../utils/constants";
import {Logout} from "../../redux/actions";
import {useViewport} from "../../utils/ViewportContext";
import EditInfo from "./EditInfo";
import Error from "../auth/Error";
import Photo from "./Photo";
import {useParams} from "react-router-dom";
import User, {EMPTY_USER, UserData} from "../../entities/User";
import {getUser, loadMe} from "../../api/methods/getUser";

const InfoStyle = styled.div`
  display: grid;
  padding: 70px 220px 150px 220px;
  grid-gap: 50px;
  .mobile & {
    padding: 70px 24px 150px 24px;
    grid-gap: 50px;
  }
  
  & > a {
    justify-self: center;
  }
`;

const InfoContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: min-content 16px 1.5fr 16px 1fr 1fr 1.8fr;
  
  .mobile & {
    grid-template-columns: none;
    grid-auto-flow: row;
    grid-gap: 20px;
    justify-content: center;
    justify-items: center;
  }
`;

const Container = styled.div`
  display: grid;
  grid-gap: 6px;
  
  & h6 {
    color: ${props => props.theme.color.dpu1};
  }

  & h5 {
    line-height: 22px;
  }

  & p {
    font-weight: 400;
  }
`;

const ContainerRight = styled(Container)`
  justify-items: end;
  & p {
    font-weight: 400;
    white-space: nowrap;
  }
`;

const Buttons = styled.div`
  display: grid;
  grid-gap: 8px;
  justify-content: end;
  
  .mobile & {
    justify-items: center;
  }

  & h6 {
    color: ${props => props.theme.color.b1};
  }
  
  & > div {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
  }
`;

const CancelButton = styled(ButtonText)`
  & h6 {
    color: ${props => props.theme.color.pi2};
  }
`;

const ErrorContainer = styled.div`
  display: grid;
  justify-content: center;
  padding: 10px 0;
`;

const Message = styled(ButtonWhite)<{count?: number}>`
  position: relative;

  &::after {
    display: ${props => props.count ? 'grid' : 'none'};
    content: "${props => props.count && '+' + props.count}";
    position: absolute;

    right: -4px;
    top: -10px;
    font-size: 10px;
    background: ${props => props.theme.color.pi2};
    color: white;
    padding: 0 4px;
    border-radius: 4px;
  }
`;

const Info = () => {
    const viewport = useViewport();
    const [editInfo, setEditInfo] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string|null>(null);
    const [data, setData] = useState<any>({});

    const meData = useSelector<storeType, UserData|null>((state) => state.me);
    const me = meData ? new User(meData) : null;

    const params = useParams();
    const user_id = params.id ? parseInt(params.id) : null;
    const [anotherUser, setAnotherUser] = useState<UserData|null>(null);

    const user = user_id ? (anotherUser ? new User(anotherUser) : new User(EMPTY_USER)) : (me || new User(EMPTY_USER));

    const dispatch = useDispatch();

    useEffect(() => {
        if (user_id && me && me.role.isAdmin()) {
            setAnotherUser(EMPTY_USER);
            getUser(user_id).then(userData => setAnotherUser(userData));
        }
    }, [meData, user_id]);

    const logout = () => {
        dispatch(Logout());
        window.open(URLs.main, '_self');
    }

    const edit = () => setEditInfo(true);

    const cancel = () => {
        setErrorMessage(null);
        setEditInfo(false);
    }

    const save = () =>
        User.edit(data)
            .then(() => {
                setErrorMessage(null);
                setEditInfo(false);
                loadMe();
            })
            .catch(error_text => {
                if (error_text) {
                    setErrorMessage(error_text);
                }
                else {
                    setErrorMessage('Неизвестная ошибка. Попробуйте позже.');
                }
            });

    return (
        <InfoStyle>
            <InfoContainer>
                <Photo />
                <div />
                {
                    editInfo
                        ? <EditInfo setData={setData} />
                        : <Container>
                            <H8>{user.firstname} {user.lastname}</H8>
                            <P2>{user.birthdate && user.age()}</P2>
                        </Container>
                }
                <div />
                {
                    viewport.device === 'mobile' ?
                        <>
                            <Container>
                                <H5>Номер телефона</H5>
                                <P2>{user.phone && processPhone(user.phone)}</P2>
                            </Container>
                            <ContainerRight>
                                <H5>Электроная почта</H5>
                                <P2>{user.email}</P2>
                            </ContainerRight>
                        </>
                        :
                        <>
                            <Container>
                                <H5>Номер телефона</H5>
                                <H5>Электроная почта</H5>
                            </Container>
                            <ContainerRight>
                                <P2>{user.phone && processPhone(user.phone)}</P2>
                                <P2>{user.email}</P2>
                            </ContainerRight>
                        </>
                }
                <Buttons>
                    <Message href={URLs.chat + (user_id ? '?id=' + user_id : '')} count={user.unread}>
                        <img src={Images.icons.message} />Сообщения
                    </Message>
                    {
                        editInfo ?
                            <div>
                                <CancelButton onClick={cancel}><H9>Отменить</H9></CancelButton>
                                <ButtonText onClick={save}><H9>Сохранить</H9></ButtonText>
                            </div>
                            :
                            <ButtonText onClick={edit}><H9>Редактировать</H9></ButtonText>
                    }
                </Buttons>
            </InfoContainer>
            {
                errorMessage &&
                <ErrorContainer>
                    <Error>
                        {errorMessage}
                    </Error>
                </ErrorContainer>
            }
            <ButtonGray onClick={logout}><H5>Выйти из аккаунта</H5></ButtonGray>
        </InfoStyle>
    );
};

export default Info;
