export type ROLES_VALUES = 0 | 1 | 2;

export class Role {
    private role:ROLES_VALUES = 0;
    constructor(role: ROLES_VALUES) {
        this.role = role;
    }
    isAuthorized = () => this.role === 1 || this.role === 2;
    isUser = () => this.role === 1;
    isAdmin = () => this.role === 2;
    getValue = () => this.role;

    hasRightIn = (role: Role) => this.role >= role.getValue();
}

export default Role;

export const ROLES = {
    VISITOR: new Role(0),
    USER: new Role(1),
    ADMIN: new Role(2),
}