import React from 'react';
import styled from "styled-components";
import {H5} from "../shared/fonts/fonts";

const ErrorStyle = styled.div`
  border: 2px solid ${props => props.theme.color.pi2};
  border-radius: 8px;
  padding: 4px 10px;
  
  & h5 {
    color: ${props => props.theme.color.pi2};
  }
  
  .mobile & {
    white-space: normal;
  }
`;

const Error = (props: {children: string}) => {
    return (
        <ErrorStyle>
            <H5>
                {props.children}
            </H5>
        </ErrorStyle>
    )
}

export default Error;
