import React, {useEffect, useState} from 'react';
import {H2, H5, H6} from "../shared/fonts/fonts";
import {ButtonPU, ButtonText} from "../shared/buttons/buttons";
import Form from "../shared/form/form";
import {InputText} from "../shared/form/input";
import styled from "styled-components";
import post from "../../api/post";
import {pageProps} from "../../pages/auth";
import {processPhone} from "../../utils/functions";
import Error from "./Error";
import {useDispatch} from "react-redux";
import {setAuthData} from "../../redux/actions";
import User from "../../entities/User";

const Fields = styled.div`
  display: grid;
  grid-gap: 22px;
  width: 100%;
`;

const Buttons = styled.div`
  display: grid;
  grid-gap: 20px;
  justify-items: center;
`;

const Login = (props: pageProps) => {
    const [phone, setPhone_] = useState('');
    const phoneStr = processPhone(phone);
    const [phoneStatus, setPhoneStatus] = useState<string|null>(null);
    const [password, setPassword_] = useState('');
    const [passStatus, setPassStatus] = useState<string|null>(null);
    const statuses = [phoneStatus, passStatus];

    const [buttonActive, setButtonActive] = useState(false);
    const [error, setError] = useState<string|null>(null);
    const dispatch = useDispatch();

    const setPassword = (e: any) => {
        if (e.target.value.length > 0) {
            setPassStatus('success');
        }
        else {
            setPassStatus(null);
        }
        setPassword_(e.target.value);
    }

    const setPhone = (e: any) => {
        const phone_ = e.target.value.replace(/[^0-9]/g, '');
        if (phone_.length === 11) {
            setPhoneStatus('success');
        }
        else if (phone_.length > 11) {
            setPhoneStatus('error');
        }
        else {
            setPhoneStatus(null);
        }
        setPhone_(phone_);
    }

    const login = () => {
        const sendPhone = phone.replace(/[^0-9]/g, '');
        post('login', {phone: sendPhone, password: password})
            .then(result => {
                dispatch(setAuthData(result.data.token));
                window.open(User.linkToView(), '_self');
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.text) {
                    setError(error.response.data.text);
                }
                else {
                    setError('Неизвестная ошибка. Попробуйте позже.');
                }
            })
    }

    useEffect(() => {
        if (statuses.every(status => status === 'success')) {
            setButtonActive(true);
        }
        else {
            setButtonActive(false);
        }
    }, statuses);

    return (
        <Form>
            <H2>Вход</H2>
            <Fields>
                <InputText status={phoneStatus} header={'Номер телефона'}
                           input={{value: phoneStr, onInput: setPhone, name: 'username'}} />
                <InputText status={passStatus} header={'Пароль'} input={{value: password, onInput: setPassword, name: 'password', type: 'password'}} />
                <ButtonText onClick={() => props.setPage('recovery', {})}><H6>Забыли пароль?</H6></ButtonText>
            </Fields>
            <Buttons>
                {
                    error && <Error>{error}</Error>
                }
                <ButtonPU onClick={login} className={buttonActive ? '' : 'disabled'}><H5>Войти</H5></ButtonPU>
                <ButtonText onClick={() => props.setPage('register', {})}><H6>Зарегистрироваться</H6></ButtonText>
            </Buttons>
        </Form>
    );
};

export default Login;
