import React from 'react';
import Header, {MenuMobile} from "./header/header";
import Footer from "./footer/footer";
import {useViewport} from "../../utils/ViewportContext";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {storeType} from "../../redux/store";
import SvgDefs from "./svgDefs";

const TemplateStyle = styled.div`
  &.noScroll {
    height: 100vh;
    overflow: hidden;
  }
`;

const Template = (props: {children: any, noFooter?: boolean}) => {
    const viewport = useViewport();
    const mobileMenu = useSelector<storeType, boolean>((state) => state.mobileMenu);

    return (
        <TemplateStyle className={mobileMenu ? 'noScroll' : ''}>
            <SvgDefs />
            <Header />
            {viewport.device === 'mobile' && <MenuMobile />}
            {props.children}
            {
                props.noFooter ? null : <Footer />
            }
        </TemplateStyle>
    );
};

export default Template;