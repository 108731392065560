import React from 'react';
import {Images} from "../../utils/images";
import {H2, H5, P1} from "../shared/fonts/fonts";
import {ButtonPU} from "../shared/buttons/buttons";
import {ResultFormStyle} from "./Success";
import User from "../../entities/User";

const Error = () => {
    return (
        <ResultFormStyle>
            <img src={Images.icons.success}/>
            <H2>Ошибка при оплате</H2>
            <P1>Попробуйте позже.</P1>
            <ButtonPU href={User.linkToView()}><H5>Личный кабинет</H5></ButtonPU>
        </ResultFormStyle>
    );
};

export default Error;