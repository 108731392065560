import React, {useEffect, useState} from 'react';
import {H2, H4, H5, H6, P1} from "../shared/fonts/fonts";
import {InputText} from "../shared/form/input";
import {ButtonPU, ButtonText} from "../shared/buttons/buttons";
import Form from "../shared/form/form";
import styled from "styled-components";
import {pageProps} from "../../pages/auth";
import {useSelector} from "react-redux";
import {storeType} from "../../redux/store";
import {processPhone} from "../../utils/functions";
import Error from "./Error";
import {reg_data, reg_data_req} from "../../utils/types";

const FormS = styled(Form)`
  text-align: center;
  & h2 {
    white-space: normal;
  }
  & h4 {
    color: ${props => props.theme.color.dpu2};
  }
`;

const Fields = styled.div`
  display: grid;
  grid-gap: 20px;
  width: 100%;
`;

const Buttons = styled.div`
  display: grid;
  grid-gap: 20px;
  justify-items: center;
`;

const Code = (props: pageProps) => {
    const [code, setCode_] = useState('');
    const [codeStatus, setCodeStatus] = useState<string|null>(null);
    const setCode = (e: any) => {
        if (e.target.value.length < 4) {
            setCodeStatus(null)
        }
        else if (/^[A-z0-9]{4}$/.test(e.target.value)) {
            setCodeStatus('success')
        }
        else {
            setCodeStatus('error')
        }
        setCode_(e.target.value);
    }
    const regData = useSelector<storeType, reg_data>((state) => state.regData) as reg_data_req;
    const phone = processPhone(regData.phone);
    const [error, setError] = useState<string|null>(null);

    const [buttonActive, setButtonActive] = useState(false);

    useEffect(() => {
        if (codeStatus === 'success') {
            setButtonActive(true);
        }
        else {
            setButtonActive(false);
        }
    }, [codeStatus]);

    return (
        <FormS>
            <H2>{props.text}</H2>
            <Fields>
                <P1>Введите код подтверждения из SMS-сообщения, отправленного вам на номер</P1>
                <H4>{phone}</H4>
            </Fields>
            <InputText status={codeStatus}
                       input={{value: code, onInput: setCode, name: 'code', placeholder: 'Код подтверждения'}} />
            <Buttons>
                {
                    error && <Error>{error}</Error>
                }
                <ButtonPU className={buttonActive ? '' : 'disabled'} onClick={() => props.next && props.next(code)}>
                    <H5>Подтвердить</H5>
                </ButtonPU>
                <ButtonText onClick={props.prev}>
                    <H6>Вернуться</H6>
                </ButtonText>
            </Buttons>
        </FormS>
    );
};

export default Code;