import React from 'react';

const ReviewsArrow = () => {
    return (
        <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g>
                <path d="M1 1L12 11L1 21" stroke="#ACA1EC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M1 1L12 11L1 21" stroke="url(#pattern_reviews_arrow)" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <pattern id="pattern_reviews_arrow" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref="#image_gradient2" transform="matrix(0.000710227 0 0 0.000390625 -0.409091 0)"/>
                </pattern>
            </defs>
        </svg>
    );
};

export default ReviewsArrow;