import React from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";
import {storeType} from "../../redux/store";
import {H9} from "../shared/fonts/fonts";
import {dateToString, Info} from "../shared/calendar/Card";
import Slot from "../../entities/Slot";

const InfoCardStyle = styled.div`
  background: ${props => props.theme.color.bb2};
  border-radius: 10px;
  box-shadow: 0 0 4px 2px rgba(24, 37, 56, 0.12);
  margin-bottom: 16px;
`;
const InfoCard = (props: {slot: Slot}) => {
    const consultationsLoading = useSelector<storeType, boolean>((state) => state.consultationsLoading);

    return (
        <InfoCardStyle>
            <Info>
                {consultationsLoading
                    ?
                    <div>Загрузка</div>
                    :
                    <>
                        <div>
                            <H9><b>{props.slot.consultation.title}</b></H9>
                        </div>
                        <div>{dateToString(props.slot.timestamp)}</div>
                    </>
            }
            </Info>
        </InfoCardStyle>
    );
};

export default InfoCard;